import {useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import Geocode from 'react-geocode'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {HiMinusSm, HiPlusSm} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {
  addressRemapper,
  addressStyles,
  finalAddressRemapper,
  getAccumulatedContacts,
  getContactDynamicFields,
  getCountryId,
} from 'helpers'
import {addSite, getSite} from 'redux-src'

import {
  Button,
  Checkbox,
  CustomCollapse,
  Input,
  Loading,
  Modal,
  Select,
  SelectField,
} from 'app/common'
import {DescriptionBox} from 'app/components'
import {REACT_APP_GOOGLE_KEY} from 'config'
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterWithSpace,
  validationHandler,
} from 'utils'

export const SiteModal = ({
  newSiteModal,
  setNewSiteModal,
  siteAddCallback,
  activeSiteDetails,
}: {
  newSiteModal: boolean
  setNewSiteModal: React.Dispatch<React.SetStateAction<boolean>>
  siteAddCallback?: (id?: number) => void
  activeSiteDetails?: Api.SiteDetailsByIdType
}) => {
  //! REDUX HANDLING
  const {toast} = useAuth()
  const dispatch = useDispatch()
  const {add_loading} = useSelector((state: any) => state.site)

  //? SITE DETAILS
  const [siteData, siteDataHandler] = useFormInput({
    name: undefined,
  })

  //? PHYSICAL ADDRESS
  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1,
  })

  // const [physAddress_loading, setPhysAddress_loading] = useState(false)

  //? POSTAL ADDRESS
  const [postalAddressData, postalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 2,
  })
  const [postalAddressActive, setPostalAddressActive] = useState(false)
  // const [postAddress_loading, setPostAddress_loading] = useState(false)

  //? DEFAULT MAIN CONTACT
  const [siteContactData, setSiteContactData] = useState({
    contactTypeId: 2,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
    // company: '',
  })

  //? BILLING CONTACT
  const [billingContactData, setBillingContactData] = useState({
    contactTypeId: 3,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
  })
  const [billingContactActive, setBillingContactActive] = useState(false)

  //? NEW CUSTOMER SUBMISSION HANDLING
  const addSiteSuccessCallback = useCallback(
    (responseData: Api.Base<Api.AddSiteDetailsResponse>) => {
      // setSiteContactFirstNameError('')
      // setSiteContactLastNameError('')
      dispatch(getSite())
      physicalAddressHandler.clear()
      postalAddressHandler.clear()
      siteDataHandler.clear()

      setBillingContactData({
        contactTypeId: 3,
        dynamicFields: [
          {dynamicFieldId: 1, contactFieldId: 1, value: undefined},
        ],
        firstName: '',
        lastName: '',
        title: '',
      })
      setSiteContactData({
        contactTypeId: 2,
        dynamicFields: [
          {dynamicFieldId: 1, contactFieldId: 1, value: undefined},
        ],
        firstName: '',
        lastName: '',
        // company: '',
        title: '',
      })
      setNewSiteModal(false)
      siteAddCallback && siteAddCallback(responseData.data.data.id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, setNewSiteModal],
  )

  const onPhysicalAddressSubmit = () => {
    const physical = validationHandler(
      {
        address: physicalAddressData.address,
        city: physicalAddressData.city,
        country: physicalAddressData.countryId,
      },
      toast,
      'physical',
    )
    const postal = postalAddressActive
      ? true
      : validationHandler(
          {
            address: postalAddressData.address,
            city: postalAddressData.city,
            country: postalAddressData.countryId,
          },
          toast,
          'postal',
        )
    return physical && postal
  }

  const onSiteContactSubmit = () => {
    const site = validationHandler(
      {
        'first name': siteContactData.firstName,
        'last name': siteContactData.lastName,
        title: siteContactData.title,
      },
      toast,
      'site contact',
    )
    const billing = billingContactActive
      ? true
      : validationHandler(
          {
            'first name': billingContactData.firstName,
            'last name': billingContactData.lastName,
            title: billingContactData.title,
          },
          toast,
          'billing contact',
        )

    // return billingContactActive ? site : (site && billing)
    return site && billing
  }

  const onAddNewSite = (e: any) => {
    e.preventDefault()
    const {defaultContact, billingContact} = getAccumulatedContacts(
      siteContactData,
      billingContactActive ? undefined : billingContactData,
    )

    const physicalAddress = finalAddressRemapper(physicalAddressData)
    const postalAddress = postalAddressActive
      ? undefined
      : finalAddressRemapper(postalAddressData)

    const _requestData: any = {
      name: siteData.name ?? undefined,
      info: {
        physicalAddress,
        postalAddress,
        defaultContact,
        billingContact,
      },
    }

    if (onPhysicalAddressSubmit() && onSiteContactSubmit()) {
      dispatch(addSite(_requestData, addSiteSuccessCallback, toast))
    } else {
      toast.error('Please fill all the required fields!')
    }
  }

  // MARK: - Clear Fields
  const clearFields = () => {
    if (!!activeSiteDetails) {
      return
    }

    physicalAddressHandler.clear()
    postalAddressHandler.clear()
    siteDataHandler.clear()

    setBillingContactData({
      contactTypeId: 3,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
    })
    setSiteContactData({
      contactTypeId: 2,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      // company: '',
      title: '',
    })
  }

  const remapSiteDetails = useCallback(() => {
    if (activeSiteDetails) {
      // dispatch({ type: GET_CUSTOMER_BY_ID.CLEAR })
      siteDataHandler.setValue('name', activeSiteDetails?.site_details?.name)
      const getCustomerAddresses = (
        addressType: 'physical' | 'postal',
        setValue: any,
      ) => {
        const addressKey =
          addressType === 'physical'
            ? 'physical_address_details'
            : 'postal_address_details'
        setValue('address', activeSiteDetails[addressKey]?.address ?? '')
        setValue('state', activeSiteDetails[addressKey]?.state ?? '')
        setValue('suburb', activeSiteDetails[addressKey]?.suburb ?? '')
        setValue('city', activeSiteDetails[addressKey]?.city ?? '')
        setValue('place', activeSiteDetails[addressKey]?.place ?? '')
        setValue(
          'countryId',
          getCountryId(activeSiteDetails[addressKey]?.country) ?? 0,
        )
        setValue('zip', activeSiteDetails[addressKey]?.zip_code ?? '')
        setValue(
          'lat',
          activeSiteDetails[addressKey]?.coordinates
            ? activeSiteDetails[addressKey]?.coordinates[0]
            : 0,
        )
        setValue(
          'lng',
          activeSiteDetails[addressKey]?.coordinates
            ? activeSiteDetails[addressKey]?.coordinates[1]
            : 0,
        )
      }

      getCustomerAddresses('physical', physicalAddressHandler.setValue)
      getCustomerAddresses('postal', postalAddressHandler.setValue)
      setPostalAddressActive(
        activeSiteDetails?.postal_address_details?.id ===
          activeSiteDetails?.physical_address_details?.id,
      )

      const getContacts = (contactTypeId: 2 | 3) => {
        const contactType =
          contactTypeId === 2
            ? 'default_contact_details'
            : 'billing_contact_details'

        return {
          contactTypeId,
          dynamicFields: getContactDynamicFields({
            emails: activeSiteDetails[contactType]?.emails ?? [],
            phones: activeSiteDetails[contactType]?.phones ?? [],
            otherPhones: activeSiteDetails[contactType]?.other_phones ?? [],
            fax: activeSiteDetails[contactType]?.fax ?? [],
            websites: activeSiteDetails[contactType]?.websites ?? [],
          }),
          firstName: activeSiteDetails[contactType]?.firstname ?? '',
          lastName: activeSiteDetails[contactType]?.lastname ?? '',
          title: activeSiteDetails[contactType]?.position ?? '',
        }
      }

      setSiteContactData(getContacts(2))
      setBillingContactData(getContacts(3))
      setBillingContactActive(
        activeSiteDetails?.default_contact_details?.id ===
          activeSiteDetails?.billing_contact_details?.id,
      )

      if (!!activeSiteDetails?.other_contacts) {
        const getOtherContacts = (otherContact: Api.ContactType) => {
          return {
            contactTypeId: 4,
            id: otherContact?.id,
            dynamicFields: getContactDynamicFields({
              emails: otherContact?.emails ?? [],
              phones: otherContact?.phones ?? [],
              otherPhones: otherContact?.other_phones ?? [],
              fax: otherContact?.fax ?? [],
              websites: otherContact?.websites ?? [],
            }),
            firstName: otherContact?.firstname ?? '',
            lastName: otherContact?.lastname ?? '',
            title: otherContact?.position ?? '',
            attn: '',
          }
        }

        const remappedOtherContacts = activeSiteDetails?.other_contacts.map(
          (otherContact) => {
            return getOtherContacts(otherContact)
          },
        )

        // setOtherContactData(remappedOtherContacts)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSiteDetails])

  useEffect(() => {
    remapSiteDetails()
  }, [remapSiteDetails])

  useEffect(() => {
    if (!activeSiteDetails) {
      clearFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSiteDetails])

  return (
    <>
      <Modal
        isActive={newSiteModal}
        size="md"
        toggleModal={() => setNewSiteModal(false)}
        title="SITE"
        onClose={clearFields}
      >
        {add_loading ? (
          <Loading />
        ) : (
          <>
            <div className="h-modal  overflow-y-scroll ">
              <div className="flex p-14">
                <div className="pl-20 pr-20">
                  {/* //physical address */}
                  <AddressTable
                    addressProps={{
                      addressData: physicalAddressData,
                      addressHandler: physicalAddressHandler,
                    }}
                    siteData={siteData}
                    siteDataHandler={siteDataHandler}
                  />

                  {/* //postal address */}
                  <CustomCollapse
                    childrenClassName="mt-18"
                    trigger={!postalAddressActive}
                    header={
                      <div className="text-sm text-gray-400 ml-24 mb-20 font-bold">
                        <Checkbox
                          dataId="postal-address"
                          label="Use Physical Address as Postal Address"
                          onChange={() => {
                            setPostalAddressActive(!postalAddressActive)
                          }}
                          isChecked={postalAddressActive}
                          value={postalAddressActive}
                        />
                      </div>
                    }
                  >
                    <div className="Sites">
                      {!postalAddressActive && (
                        <>
                          <AddressTable
                            addressProps={{
                              addressData: postalAddressData,
                              addressHandler: postalAddressHandler,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </CustomCollapse>

                  {/* //site contact */}
                  <ContactTable
                    contactProps={{
                      contactData: siteContactData,
                      setContactData: setSiteContactData,
                    }}
                  />

                  {/* //billing contact */}
                  <CustomCollapse
                    childrenClassName="mt-18"
                    trigger={!billingContactActive}
                    header={
                      <div className="text-sm text-gray-400 ml-24 mb-20 font-bold">
                        <Checkbox
                          dataId="billing-contact"
                          label="Select as Billing Contact"
                          onChange={() => {
                            setBillingContactActive(!billingContactActive)
                            billingContactActive
                              ? setBillingContactData({
                                  contactTypeId: 3,
                                  firstName: '',
                                  lastName: '',
                                  title: '',
                                  dynamicFields: [
                                    {
                                      dynamicFieldId: 1,
                                      contactFieldId: 1,
                                      value: '',
                                    },
                                  ],
                                })
                              : setBillingContactData(siteContactData)
                          }}
                          isChecked={billingContactActive}
                          value={billingContactActive}
                        />
                      </div>
                    }
                  >
                    <div className="Sites">
                      {!billingContactActive && (
                        <>
                          <ContactTable
                            contactProps={{
                              contactData: billingContactData,
                              setContactData: setBillingContactData,
                            }}
                            billing
                          />
                        </>
                      )}
                    </div>
                  </CustomCollapse>
                </div>
              </div>
            </div>
            <div className="p-4 border-t-1 border-gray-200"></div>
            <div className="flex justify-end px-16 pt-4 pb-12">
              <Button
                title="cancel"
                buttonColor="bg-red-400 text-white hover:bg-red-500 "
                size="sm"
                type="button"
                // style="ghost"
                onClick={() => {
                  setNewSiteModal(false)
                  clearFields()
                }}
              />
              <div className="ml-16">
                <Button
                  title="Save"
                  size="sm"
                  type="submit"
                  onClick={(e: any) => onAddNewSite(e)}
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

// MARK: - Address
const AddressTable = ({
  addressProps: {addressData, addressHandler},
  siteData,
  siteDataHandler,
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions} = selectOptions
  const [addressValue, setAddressValue] = useState(null)

  const handleAddressDropChange = (item: any) => {
    setAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(
      async (response) => {
        const responseFromHelper = await addressRemapper(response)
        //

        const _aaddressData = {
          ...addressData,
          ...responseFromHelper,
        }

        const {
          address,
          city,
          countryId,
          lat,
          lng,
          placeId,
          state,
          zip,
          suburb,
          country,
        } = _aaddressData
        const {setValue} = addressHandler

        setValue('address', address)
        setValue('city', city)
        setValue('countryId', countryId)
        setValue('lat', lat)
        setValue('lng', lng)
        setValue('placeId', placeId)
        setValue('state', state)
        setValue('zip', zip)
        setValue('suburb', suburb)
        setValue('country', country)
      },
      (error) => {},
    )
  }

  return (
    <DescriptionBox
      className="mt-18"
      title={
        <div className="flex items-center align-center justify-between text-sm text-gray-700 ml-20">
          <h4 className="flex font-bold text-md justify-between">
            {siteData ? 'Physical' : 'Postal'} Address *
          </h4>
          {!siteData && (
            <div
              className={`border-1 px-8 py-3 rounded-[2px] text-white font-normal cursor-pointer ${
                !addressData?.address
                  ? 'bg-red-200 border-red-200'
                  : 'bg-red-300 border-red-300'
              }`}
              onClick={() => {
                if (!!addressData?.address) {
                  addressHandler.clear()
                }
              }}
            >
              Clear
            </div>
          )}
        </div>
      }
    >
      <div className=" flex flex-wrap items-center mx-10">
        {siteData && (
          <>
            <div className="w-full lg:w-6/12 px-16">
              <div className="text-sm text-gray-400">Site name</div>
              <div className="mx-2 mb-18">
                <Input
                  name="name"
                  value={siteData?.name}
                  size="sm"
                  onChange={(e: any) =>
                    siteDataHandler?.setValue(
                      e.target.name,
                      capitalizeFirstLetterWithSpace(e.target.value),
                    )
                  }
                  error={
                    siteDataHandler?.modified?.name &&
                    siteDataHandler?.errors?.name
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="w-full lg:w-6/12 px-16">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-400">Address *</span>
            {siteData && !!addressData.address && (
              <span
                className="text-red-300 cursor-pointer"
                onClick={() => {
                  addressHandler.clear()
                }}
              >
                Clear
              </span>
            )}
          </div>
          <div className="mb-20">
            <GooglePlacesAutocomplete
              apiKey={REACT_APP_GOOGLE_KEY}
              selectProps={{
                value:
                  {
                    label: addressData?.address,
                    value: addressData?.address,
                  } ?? addressValue,
                onChange: handleAddressDropChange,
                styles: addressStyles(addressData?.address?.length === 0),
              }}
            />
            <Input
              name="address"
              size="sm"
              value={addressData?.address}
              className="text-sm hidden"
              onChange={addressHandler?.onChange}
              error={addressData?.address?.length === 0}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">
            Room/Apartment/Building(Optional)
          </div>
          <div className="mx-2 mb-18">
            <Input
              name="place"
              value={addressData?.place}
              size="sm"
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Suburb</div>
          <div className="mx-2 mb-18">
            <Input
              name="suburb"
              value={addressData?.suburb}
              size="sm"
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">City *</div>
          <div className="mx-2 mb-18">
            <Input
              name="city"
              size="sm"
              value={addressData?.city}
              onChange={addressHandler?.onChange}
              error={addressData?.city?.length === 0}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">State/Region</div>
          <div className="mx-2 mb-18">
            <Input
              name="state"
              size="sm"
              value={addressData?.state}
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>

        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Zip/Postal Code</div>
          <div className="mx-2 mb-18">
            <Input
              name="zip"
              size="sm"
              value={addressData?.zip}
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Country *</div>
          <div className="mx-2 mb-18">
            <SelectField
              options={countryOptions}
              placeholder="Select country"
              getOptionLabel={'key'}
              getOptionValue={'value'}
              value={countryOptions.filter((country: any) => {
                return country.value === addressData.countryId
              })}
              onChangeValue={(e) => {
                addressHandler?.setValue('countryId', e.value)
              }}
              error={addressData?.countryId === 0}
            />
          </div>
        </div>
      </div>
    </DescriptionBox>
  )
}

const ContactTable = ({
  contactProps: {contactData, setContactData},
  billing,
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {contactTypeOptions} = selectOptions

  //? MAIN CONTACT CHANGE HANDLING
  const onContactInputChange = (e: any) =>
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value ?? undefined,
    })

  const onContactDynamicFieldChange = (
    e: any,
    key: 'value' | 'contactFieldId',
    i: any,
  ) => {
    const _contactData = {...contactData}

    const newDynamicFields = _contactData?.dynamicFields.map(
      (dynamicField: any) => {
        if (dynamicField.dynamicFieldId === i) {
          return {
            ...dynamicField,
            [key]: key === 'contactFieldId' ? +e.target.value : e.target.value,
          }
        }

        return dynamicField
      },
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}

    setContactData(newContactData)
  }

  const onContactDynamicFieldAddClick = (e: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const {dynamicFields} = _contactData
    const dynamicField =
      _contactData?.dynamicFields[_contactData?.dynamicFields.length - 1]
    const newDynamicFields = [
      ...dynamicFields,
      {
        dynamicFieldId: dynamicField.dynamicFieldId + 1,
        contactFieldId: 1,
        value: undefined,
      },
    ]

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  const onContactDynamicFieldRemoveClick = (e: any, i: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const newDynamicFields = _contactData?.dynamicFields.filter(
      (dynamicField: any) => dynamicField.dynamicFieldId !== i,
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  return (
    <DescriptionBox
      className="mt-18"
      title={
        <div className="text-sm text-gray-700 ml-20">
          <h4 className="flex font-bold text-md justify-between">
            {billing ? 'Billing' : 'Default Site'} Contact *
          </h4>
        </div>
      }
    >
      <div className="-mx-16 flex flex-wrap items-center ml-10 mr-10">
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">First name *</div>
          <div className="mx-2 mb-18">
            <Input
              name="firstName"
              error={contactData?.firstName?.length === 0}
              value={contactData?.firstName}
              size="sm"
              onChange={(e: any) => {
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Last name *</div>
          <div className="mx-2 mb-18">
            <Input
              name="lastName"
              error={contactData?.lastName.length === 0}
              value={contactData?.lastName}
              size="sm"
              onChange={(e: any) => {
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Title/Position *</div>
          <div className="mx-2 mb-18">
            <Input
              name="title"
              error={contactData?.title?.length === 0}
              value={contactData?.title}
              size="sm"
              onChange={onContactInputChange}
            />
          </div>
        </div>
        {/* {!billing && (
          <div className="w-full lg:w-6/12 px-16">
            <div className="text-sm text-gray-400">Company</div>
            <div className="mx-2 mb-18">
              <Input
                name="company"
                value={contactData?.company}
                size="sm"
                onChange={onContactInputChange}
              />
            </div>
          </div>
        )} */}
      </div>

      {contactData?.dynamicFields?.map((field: any, index: any) => {
        return (
          <div
            className="-mx-16 flex flex-wrap items-center ml-10 mr-10"
            key={field.dynamicFieldId}
          >
            <div className="w-full lg:w-6/12 px-16">
              <div className="text-sm text-gray-400">Contact type</div>
              <div className="mx-2 mb-18">
                <Select
                  name="contactFieldId"
                  options={contactTypeOptions}
                  size="sm"
                  className="text-sm"
                  value={contactData?.dynamicFields[index].contactFieldId}
                  onChange={(e: any) =>
                    onContactDynamicFieldChange(
                      e,
                      'contactFieldId',
                      field.dynamicFieldId,
                    )
                  }
                />
              </div>
            </div>

            <div className="w-full lg:w-5/12 px-16">
              <div className="text-sm text-gray-400">Contact type value</div>
              <div className="mx-2 mb-18">
                <Input
                  name="value"
                  size="sm"
                  className="text-sm"
                  value={contactData?.dynamicFields[index].value}
                  onChange={(e: any) =>
                    onContactDynamicFieldChange(
                      e,
                      'value',
                      field.dynamicFieldId,
                    )
                  }
                />
              </div>
            </div>
            <div className="w-full lg:w-1/12 px-16">
              {contactData?.dynamicFields[index]?.value === undefined ? (
                <div className="-mx-16 flex">
                  <HiPlusSm
                    className="mx-6 mb-0 bg-gray-200 rounded-sm text-white cursor-not-allowed"
                    size={24}
                  />
                </div>
              ) : contactData?.dynamicFields?.length === index + 1 ? (
                <div className="-mx-16 flex">
                  <HiPlusSm
                    className="mx-6 mb-0 bg-blue-400 hover:bg-blue-500 rounded-sm text-white cursor-pointer"
                    size={24}
                    onClick={onContactDynamicFieldAddClick}
                  />
                </div>
              ) : (
                <div className="-mx-16 flex">
                  <HiMinusSm
                    className="mx-6 mb-0 bg-red-400 hover:bg-red-500 rounded-sm text-white cursor-pointer"
                    size={24}
                    onClick={(e: any) =>
                      onContactDynamicFieldRemoveClick(e, field.dynamicFieldId)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
    </DescriptionBox>
  )
}
