import {useCallback, useEffect, useState} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {
  // FaComments,
  FaEnvelope,
  FaFax,
  FaGlobe,
  FaPen,
  FaPhone,
  FaTimes,
  FaUser,
} from 'react-icons/fa'
import {
  HiLocationMarker,
  HiOfficeBuilding,
  HiOutlineRefresh,
  HiPlusSm,
  HiSearch,
} from 'react-icons/hi'

import {CustomerModal} from 'app/pages/customers/components'
import {
  DynamicSearchSelect,
  NewContactModal,
  NotesSummary,
} from 'app/components'
import {JobTitle} from '../../components'
import {ProjectDetails, SiteVisits} from './components'

import {SearchSelect, ToolTip} from 'app/common'

import {useDebounceValue} from 'hooks'
import {useSelector, useDispatch} from 'react-redux'
import {
  getAllInfoContacts,
  getCustomerById,
  getProjectByIdAction,
  getSiteById,
} from 'redux-src'

export const JobsSummaryPage = () => {
  const {customers}: RT.CustomerReduxType = useSelector(
    (state: any) => state.customer,
  )
  const {sites}: RT.SiteReduxType = useSelector((state: any) => state.site)
  // For Customer Details
  const [newCustomerModal, setNewCustomerModal] = useState(false)
  const [addNewContactModal, setNewContactModal] = useState(false)
  const [customerSiteId, setCustomerSiteId] = useState<{
    customerId: number
    siteId: number
  }>()
  const [activeEditMode, setActiveEditMode] = useState<boolean>(false)

  const [customerList, setCustomerList] = useState<any>()
  const [hideAddCustomerSection, setCustomerSection] = useState(false)
  const [changeMainContact, setChangeMainContact] = useState(false)
  const [customerData, setCustomerData] = useState<Api.CustomerById>()
  const [customerDetails, setCustomerDetails] = useState<any>()
  const [projectDetailsById, setProjectDetailsById] =
    useState<Api.ProjectDetailsById>()

  // console.log({ projectDetailsById })

  const [contactList, setContactList] = useState<any>()
  const [customerContacts, setCustomerContacts] = useState<Api.ContactType>()

  const [customerContactLists, setCustomerContactLists] =
    useState<Api.ContactType[]>()

  const [filterData] = useState('')
  const [contactFilterData, setContactFilterData] = useState('')

  const debouncedFilterData = useDebounceValue(filterData)
  const debouncedContactFilter = useDebounceValue(contactFilterData)

  const dispatch = useDispatch()

  const onAddNewReason = () => {
    setNewCustomerModal(true)
    setActiveEditMode(false)
  }

  const onAddNewContact = () => {
    setNewContactModal(true)
  }

  const customerRemapper = useCallback(
    (filterValue) => {
      const _temp: any = []
      customers?.map((item) => {
        if (
          item.customer_details?.name
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase())
        ) {
          return _temp.push({
            key: item.customer_details?.id,
            name: item.customer_details?.name,
            value: item.default_contact_details?.firstname,
          })
        }
        return null
      })
      setCustomerList(_temp)
    },
    [customers],
  )

  const contactRemapper = useCallback(
    (filterValue) => {
      if (customerData) {
        dispatch(
          getAllInfoContacts(
            customerData?.customer_details?.id,
            customerData?.info_id,
            (data) => {
              setCustomerContactLists(data.map((item) => item.contact_details))
              setContactList(
                data
                  .map(({contact_details: cd}) => ({
                    key: cd?.id,
                    name: cd?.phones ? cd?.phones[0] : null,
                    value: cd?.firstname,
                  }))
                  .filter((item) =>
                    item?.value
                      ?.toLowerCase()
                      .includes(filterValue?.toLowerCase()),
                  ),
              )
            },
          ),
        )
      }
    },
    [customerData, dispatch],
  )

  useEffect(() => {
    contactRemapper(debouncedContactFilter)
  }, [contactRemapper, debouncedContactFilter])

  useEffect(() => {
    customerRemapper(debouncedFilterData)
  }, [customerRemapper, debouncedFilterData])

  const setCustomerValue = useCallback(
    (selectedId: number) => {
      dispatch(
        getCustomerById(selectedId, (customerValue) => {
          setCustomerData(customerValue)
          setCustomerContacts(customerValue?.default_contact_details)
          setCustomerSection(true)
          setCustomerDetails(customerValue)
          setCustomerSiteId({
            ...customerSiteId,
            customerId: customerValue?.customer_details?.id,
          })
        }),
      )
    },
    [customerSiteId, dispatch],
  )

  useEffect(() => {
    setCustomerValue(projectDetailsById?.project_details?.customer_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetailsById?.project_details?.customer_id])

  const setContactValue = (id: any) => {
    const contactValue = customerContactLists.find(
      (item: any) => item.id === id,
    )
    //
    setCustomerContacts(contactValue)
    setCustomerDetails((prev: any) => ({
      ...prev,
      main_contact_details: contactValue,
    }))
    setChangeMainContact(false)
  }

  // For Site Details
  const [siteDetails, setSiteDetails] = useState<any>()
  const [newSiteModal, setNewSiteModal] = useState(false)
  const [isSameAsCustomer, setSameAsCustomer] = useState(false)
  const [isSiteSection, setSiteSection] = useState(false)
  const [activeSiteDetails, setActiveSiteDetails] =
    useState<Api.SiteDetailsByIdType>()

  const [siteFilterData] = useState('')
  const [siteList, setSiteList] = useState(null)
  const {toast} = useAuth()

  const siteRemapper = useCallback(
    (filterValue) => {
      const _temp: any = []
      sites.map((item) => {
        if (item?.site_details?.name === null) {
          if (
            item?.site_details?.name
              ?.toLowerCase()
              .includes(filterValue?.toLowerCase())
          ) {
            return _temp.push({
              key: item.site_details?.id,
              name: item.site_details?.name,
              value: item.main_contact_details?.firstname,
            })
          }
        } else if (
          item.site_details?.name
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase())
        ) {
          return _temp.push({
            key: item.site_details?.id,
            name: item.site_details?.name,
            value: item.main_contact_details?.firstname,
          })
        }
        return null
      })
      setSiteList(_temp)
    },
    [sites],
  )

  const onClickSameAsCustomer = () => {
    if (customerDetails !== undefined && customerDetails) {
      const {
        customer_details: {name},
        postal_address_details: physicalAddress,
        default_contact_details: mainContact,
      } = customerDetails

      const _siteDetails = {
        name,
        physicalAddress,
        defaultContact: mainContact,
      }

      setSiteDetails(_siteDetails)
      setSameAsCustomer(true)

      setSiteSection(true)
    } else toast.warning('Please enter the customer details first!')
  }

  const onAddNewSite = () => {
    setNewSiteModal(true)
  }

  const setSiteValue = useCallback((id: number) => {
    dispatch(
      getSiteById(id, (siteValue) => {
        const {
          site_details: {name},
          physical_address_details: physicalAddress,
          default_contact_details: mainContact,
        } = siteValue
        // console.log('siteValue: ', siteValue)
        const _siteDetails = {
          id,
          name: name ?? 'Site Name',
          physicalAddress,
          defaultContact: mainContact,
        }

        setSiteDetails(_siteDetails)

        setCustomerSiteId({
          ...customerSiteId,
          siteId: _siteDetails?.id,
        })

        setActiveSiteDetails(siteValue)
        setSameAsCustomer(false)
        setSiteSection(true)
      }),
    )
  }, [])

  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const {projectId} = params as any

  useEffect(() => {
    dispatch(
      getProjectByIdAction(Number(projectId), (data) => {
        setProjectDetailsById(data)
        data && data?.customer_address_details && setCustomerSection(true)
        data && data?.customer_address_details && setSiteSection(true)
      }),
    )
  }, [dispatch, projectId])

  useEffect(() => {
    siteRemapper(siteFilterData)
  }, [siteFilterData, siteRemapper])

  useEffect(() => {
    setSiteValue(projectDetailsById?.project_details?.site_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetailsById?.project_details?.site_id])

  return (
    <div>
      <JobTitle>Project Summary</JobTitle>

      <div className="flex gap-16 mt-16">
        <div className="flex gap-16 w-full bg-white">
          <div className="flex-1 w-full">
            <div className="border-1 border-gray-200 mt-1 relative rounded-md shadow-sm flex flex-wrap pt-16 pb-16">
              <div className="px-30 justify-center items-center text-blue-300 flex border-r-1 border-gray-200">
                <div className="flex-nowrap">
                  <FaUser size="50" className="mx-auto" />
                  <p className="text-center">Customer</p>
                </div>
              </div>
              {!hideAddCustomerSection && (
                <div className="mx-16 flex-1 ">
                  <h1 className="font-bold text-xl">Customer</h1>
                  <div className="flex justify-center items-center w-full  ">
                    <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
                      <HiSearch size={18} />
                    </div>
                    <DynamicSearchSelect
                      actionUrl="/customers"
                      placeholder="Search for an existing customer"
                      remapOptions={(data: Api.CustomerIndividual[]) => {
                        return data?.map(
                          ({
                            customer_details: cd,
                            default_contact_details: dc,
                            info_id: info,
                          }) => {
                            return {
                              id: cd?.id,
                              label: [cd?.name, dc?.firstname].join(' - '),
                              value: info,
                            }
                          },
                        )
                      }}
                      customOption={
                        <div
                          onClick={onAddNewReason}
                          className="flex flex-nowrap justify-start items-center gap-8 py-6 px-10 cursor-pointer border-b-1 border-gray-200 hover:bg-blue-300 hover:text-white transition-all duration-200 ease-linear"
                        >
                          <HiPlusSm size="20" />
                          New Customer
                        </div>
                      }
                      onChangeValue={(value) => {
                        setCustomerValue(value.id)
                      }}
                    />
                  </div>
                  <div className="flex justify-center items-center w-full ">
                    <button
                      className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                      onClick={() => onAddNewReason()}
                    >
                      {' '}
                      <span className="flex flex-nowrap justify-center items-center gap-4 py-4 text-gray-400">
                        <HiPlusSm size="20" />
                        Add New Customer
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {hideAddCustomerSection &&
              customerData !== null &&
              projectDetailsById !== null ? (
                <div className="mx-16 flex-1 items-center lg:w-12/12">
                  <div className="w-full lg:w-12/12 px-2">
                    <div className="-mx-16">
                      <div className="">
                        <div className="flex items-center justify-between  font-bold  px-12 border-gray-200 mb-20">
                          <span className="px-4 text-blue-300 hover:text-blue-400 cursor-pointer font-bold">
                            {customerData == null
                              ? projectDetailsById?.customer_contact_details
                                  ?.firstname +
                                ' ' +
                                projectDetailsById?.customer_contact_details
                                  ?.lastname
                              : customerData?.customer_details?.name}
                          </span>
                          <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100 overflow-hidden">
                            {/* <BootstrapTooltip title="Quick Email"> */}
                            {/* <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                //   onClick={handleQuickEmail}
                              >
                                <FaEnvelope size={12} />
                              </button>
                            </div>
                            <div className="border-r-1 border-gray-200" /> */}
                            {/* <BootstrapTooltip title="Quick SMS"> */}
                            {/* <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                //   onClick={handleQuickSms}
                              >
                                <FaComments size={12} />
                              </button>
                            </div>
                            <div className="border-r-1 border-gray-200" /> */}
                            {/* <BootstrapTooltip title="Map"> */}
                            {/* <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                //   onClick={handleQuickMap}
                              >
                                <HiLocationMarker size={12} />
                              </button>
                            </div>
                            <div className="border-r-1 border-gray-200" /> */}
                            {/* <BootstrapTooltip title="Edit this contact"> */}
                            <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                onClick={() => {
                                  setNewCustomerModal(true)
                                  setActiveEditMode(true)
                                }}
                              >
                                <FaPen size={12} />
                              </button>
                            </div>
                            <div className="border-r-1 border-gray-200" />
                            {/* <BootstrapTooltip title="Change customer contact"> */}
                            <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                onClick={() => {
                                  setCustomerSection(false)
                                  // setCustomerDetails(null)
                                }}
                              >
                                <FaTimes size={12} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-12/12 px-2">
                      <div className="-mx-16 px-22 pb-10">
                        <span className="inline-flex text-gray-300">
                          <HiLocationMarker
                            className="text-gray-300 mx-10 w-20"
                            size={16}
                          />
                          <p>
                            {customerData == null
                              ? projectDetailsById?.customer_address_details
                                  ?.address
                              : customerData?.postal_address_details
                                  ?.address}{' '}
                          </p>
                        </span>
                      </div>
                    </div>

                    <div className="w-full lg:w-12/12 px-2">
                      <div className="flex items-center justify-between py-8">
                        <h3
                          className="text-gray-500"
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Main Contact
                        </h3>
                        <div className="md-10">
                          {changeMainContact === false && (
                            <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100 overflow-hidden">
                              {/* <BootstrapTooltip title="Edit job main contact">  */}
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                onClick={() => {
                                  setNewCustomerModal(true)
                                  setActiveEditMode(true)
                                }}
                                //   onClick={() =>
                                //       {customerContacts  &&
                                // customerContacts.contactTypeId === 2 ?
                                // handleQuickEdit()
                                //   : openEditContactModal(customerContacts)
                                // }
                              >
                                <FaPen className="border-gray-200" size={11} />
                              </button>
                              <div className="border-r-1 border-gray-200" />
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                onClick={() => setChangeMainContact(true)}
                              >
                                <FaTimes
                                  className="border-gray-200"
                                  size={12}
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {changeMainContact === false ? (
                        <>
                          <div className="mx-4">
                            <span className="inline-flex text-gray-300">
                              <FaUser
                                className="text-gray-300 mx-10 w-20"
                                size={16}
                              />{' '}
                              {customerContacts == null
                                ? projectDetailsById?.customer_contact_details
                                    ?.firstname +
                                  ' ' +
                                  projectDetailsById?.customer_contact_details
                                    ?.lastname
                                : customerContacts &&
                                  customerContacts.firstname}{' '}
                              {customerContacts && customerContacts.lastname} (
                              {customerContacts == null
                                ? projectDetailsById?.customer_contact_details
                                    ?.position
                                : customerContacts && customerContacts.position}
                              )
                            </span>
                          </div>
                          {projectDetailsById?.customer_contact_details &&
                            customerContacts == null && (
                              <div>
                                <span className="flex mx-4 text-gray-300 flex-col">
                                  {projectDetailsById?.customer_contact_details
                                    ?.emails !== null &&
                                    projectDetailsById?.customer_contact_details
                                      ?.emails.length !== 0 && (
                                      <span className="inline-flex">
                                        <FaEnvelope
                                          className="mx-10 w-20"
                                          size={16}
                                        />{' '}
                                        {
                                          projectDetailsById
                                            ?.customer_contact_details
                                            ?.emails[0]
                                        }
                                      </span>
                                    )}
                                  {projectDetailsById?.customer_contact_details
                                    ?.phones !== null &&
                                    projectDetailsById?.customer_contact_details
                                      ?.phones.length !== 0 && (
                                      <span className="inline-flex">
                                        <FaPhone
                                          className="mx-10 w-20"
                                          size={16}
                                        />
                                        {
                                          projectDetailsById
                                            ?.customer_contact_details
                                            ?.phones[0]
                                        }
                                      </span>
                                    )}
                                  {projectDetailsById?.customer_contact_details
                                    ?.fax !== null &&
                                    projectDetailsById?.customer_contact_details
                                      ?.fax.length !== 0 && (
                                      <span className="inline-flex">
                                        <FaFax
                                          className="mx-10 w-20"
                                          size={16}
                                        />
                                        {
                                          projectDetailsById
                                            ?.customer_contact_details?.fax[0]
                                        }
                                      </span>
                                    )}
                                  {projectDetailsById?.customer_contact_details
                                    ?.websites !== null &&
                                    projectDetailsById?.customer_contact_details
                                      ?.websites.length !== 0 && (
                                      <span className="inline-flex">
                                        <FaGlobe
                                          className="mx-10 w-20"
                                          size={16}
                                        />
                                        {
                                          projectDetailsById
                                            ?.customer_contact_details
                                            ?.websites[0]
                                        }
                                      </span>
                                    )}
                                </span>
                              </div>
                            )}
                          {customerContacts && (
                            <div>
                              <span className="flex mx-4 text-gray-300 flex-col">
                                {customerContacts.emails !== null &&
                                  customerContacts.emails.length !== 0 && (
                                    <span className="inline-flex">
                                      <FaEnvelope
                                        className="mx-10 w-20"
                                        size={16}
                                      />{' '}
                                      {customerContacts.emails[0]}
                                    </span>
                                  )}
                                {customerContacts.phones !== null &&
                                  customerContacts.phones.length !== 0 && (
                                    <span className="inline-flex">
                                      <FaPhone
                                        className="mx-10 w-20"
                                        size={16}
                                      />
                                      {customerContacts.phones[0]}
                                    </span>
                                  )}
                                {customerContacts.fax !== null &&
                                  customerContacts.fax.length !== 0 && (
                                    <span className="inline-flex">
                                      <FaFax className="mx-10 w-20" size={16} />
                                      {customerContacts.fax[0]}
                                    </span>
                                  )}
                                {customerContacts.websites !== null &&
                                  customerContacts.websites.length !== 0 && (
                                    <span className="inline-flex">
                                      <FaGlobe
                                        className="mx-10 w-20"
                                        size={16}
                                      />
                                      {customerContacts.websites[0]}
                                    </span>
                                  )}
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="mb-4">
                          <div className="flex justify-center items-center w-full lg:w-12/12 md:w-12/12 px-16 sm:w-12/12 ">
                            <button
                              className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                              onClick={() => setChangeMainContact(false)}
                            >
                              {' '}
                              <span className="inline-flex text-gray-400 mt-4">
                                <HiOutlineRefresh className="mr-4" size={18} />{' '}
                                Customer Default Main Contact
                              </span>
                            </button>
                          </div>
                          <div className="flex justify-center items-center w-full lg:w-12/12 md:w-12/12 px-16 sm:w-12/12 ">
                            <button
                              className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                              onClick={() => onAddNewContact()}
                            >
                              {' '}
                              <span className="inline-flex text-gray-400 mt-4">
                                <HiPlusSm size={18} />
                                New Contact
                              </span>
                            </button>
                          </div>
                          <div className="flex justify-center items-center w-full px-16 ">
                            <SearchSelect
                              name="countryId"
                              options={contactList}
                              size="sm"
                              placeholder="Other Contacts"
                              value={contactFilterData}
                              onChange={(e: any) =>
                                setContactFilterData(e.target.value)
                              }
                              className="text-sm w-full"
                              containerClass="mt-6 w-full"
                              optionContainerClassName="left-30"
                              customOption
                              customValue="New Contact"
                              customAction={onAddNewContact}
                              setValue={setContactValue}
                            />

                            <div>
                              <button
                                className="over:bg-gray-200 border-1 border-gray-200 px-10 py-4 shadow-sm rounded-sm mx-4 ml-10 mt-6"
                                onClick={() => setChangeMainContact(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {!!customerData?.customer_details?.id ? (
              <div className="mt-10">
                <NotesSummary
                  type="customer"
                  hostId={customerData?.customer_details?.id}
                />
              </div>
            ) : (
              <div className="mt-10">
                <NotesSummary
                  type="customer"
                  hostId={projectDetailsById?.project_details?.customer_id}
                />
              </div>
            )}
          </div>

          {/* Site Details  */}

          <div className="flex-1 w-full">
            <div className="border-1 border-gray-200 mt-1 relative rounded-md shadow-sm flex py-16">
              <div className="px-30 justify-center items-center text-blue-300 flex border-r-1 border-gray-200">
                <div className="flex-nowrap">
                  <HiOfficeBuilding size="60" />
                  <p className="text-center">Site</p>
                </div>
              </div>
              {!isSameAsCustomer && !isSiteSection ? (
                <div className="mx-16 flex-1 items-center justify-center lg:w-12/12">
                  <h1 className="font-bold text-xl">Site Details</h1>
                  <div className="flex justify-center items-center w-full  ">
                    <button
                      className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200 cursro-pointer"
                      // disabled
                      onClick={() => onClickSameAsCustomer()}
                    >
                      {' '}
                      <span className="inline-flex text-gray-400 mt-4">
                        <HiOutlineRefresh size="20" />
                        Same as Customer
                      </span>
                    </button>
                  </div>
                  <div className="flex justify-center items-center  w-full  ">
                    <button
                      className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                      onClick={() => setNewSiteModal(true)}
                    >
                      {' '}
                      <span className="inline-flex text-gray-400 mt-4">
                        <HiPlusSm size="20" />
                        New Site Contact & Address
                      </span>
                    </button>
                  </div>
                  {customerDetails !== undefined && customerDetails && (
                    <div className="flex justify-center items-center w-full  ">
                      <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
                        <HiSearch size={18} />
                      </div>
                      <DynamicSearchSelect
                        actionUrl="/sites"
                        placeholder="Search for an existing sites"
                        remapOptions={(data: Api.SiteIndividual[]) => {
                          return data?.map(
                            ({
                              site_details: sd,
                              physical_address_details: pad,
                              main_contact_details: mc,
                            }) => {
                              return {
                                id: sd?.id,
                                label: [mc?.firstname, pad?.address].join(
                                  ' - ',
                                ),
                                value: sd?.info,
                              }
                            },
                          )
                        }}
                        customOption={
                          <div
                            onClick={onAddNewSite}
                            className="flex flex-nowrap justify-start items-center gap-8 py-6 px-10 cursor-pointer border-b-1 border-gray-200 hover:bg-blue-300 hover:text-white transition-all duration-200 ease-linear"
                          >
                            <HiPlusSm size="20" />
                            New Site
                          </div>
                        }
                        onChangeValue={(value) => {
                          setSiteValue(value.id)
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-full lg:w-12/12 px-2">
                  <div className="mx-4">
                    <div className="">
                      <div className="flex flex-row items-center justify-between  font-bold  px-6 border-gray-200 mb-20">
                        {/* <div className="mx-4 pb-10"> */}
                        <span className="inline-flex text-gray-300">
                          <HiLocationMarker
                            className="text-gray-300 mr-6 w-20"
                            size={16}
                          />
                          <p
                            className=" text-blue-300 hover:text-blue-400 cursor-pointer font-bold"
                            onClick={() => {
                              navigate(
                                `/sites/view/${
                                  projectDetailsById &&
                                  projectDetailsById?.project_details?.site_id
                                }`,
                              )
                            }}
                          >
                            {siteDetails == null
                              ? projectDetailsById?.site_address_details
                                  ?.address
                              : siteDetails?.physicalAddress?.address}
                            ,{' '}
                          </p>
                        </span>
                        {/* </div> */}
                        <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100 h-[max-content]">
                          {/* <BootstrapTooltip title="Quick Email"> */}
                          <div className="flex">
                            <div className="border-r-1 border-gray-200" />
                            {/* <BootstrapTooltip title="Map"> */}
                            {/* <div>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                //   onClick={handleQuickMap}
                              >
                                <HiLocationMarker size={12} />
                              </button>
                            </div>
                            <div className="border-r-1 border-gray-200" /> */}
                            {/* <BootstrapTooltip title="Edit this contact"> */}

                            <div className="border-r-1 border-gray-200" />
                            {/* <BootstrapTooltip title="Change customer contact"> */}
                            <ToolTip text="Cancel" top>
                              <button
                                className="p-6 hover:bg-gray-200"
                                type="button"
                                onClick={() => {
                                  setSiteSection(false)
                                  setSameAsCustomer(false)
                                }}
                              >
                                <FaTimes size={12} />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-12/12 px-6">
                      <div className="flex justify-between">
                        <span className="inline-flex text-blue-300 hover:text-blue-400 cursor-pointer font-bold">
                          <FaUser
                            className="text-gray-300 mr-6 w-20"
                            size={16}
                          />{' '}
                          {siteDetails == null
                            ? projectDetailsById?.site_contact_details
                                ?.firstname
                            : siteDetails?.defaultContact?.firstname}{' '}
                          {siteDetails == null
                            ? projectDetailsById?.site_contact_details?.lastname
                            : siteDetails?.defaultContact?.lastname}{' '}
                          (
                          {siteDetails == null
                            ? projectDetailsById?.site_contact_details?.position
                            : siteDetails?.defaultContact?.position}
                          )
                        </span>

                        <div className="flex border-1 border-gray-200 rounded-md shadow-sm bg-gray-100 h-[max-content]">
                          {/* <div>
                            <button
                              className="p-6 hover:bg-gray-200"
                              type="button"
                              //   onClick={handleQuickEmail}
                            >
                              <FaEnvelope size={12} />
                            </button>
                          </div>
                          <div className="border-r-1 border-gray-200" /> */}
                          {/* <BootstrapTooltip title="Quick SMS"> */}
                          {/* <div>
                            <button
                              className="p-6 hover:bg-gray-200"
                              type="button"
                              //   onClick={handleQuickSms}
                            >
                              <FaComments size={12} />
                            </button>
                          </div>
                          <div className="border-r-1 border-gray-200" /> */}
                          <ToolTip text="Edit" top>
                            {/* <div> */}
                            <button
                              className={`p-6 hover:bg-gray-200 ${
                                isSameAsCustomer
                                  ? 'text-gray-300 cursor-not-allowed'
                                  : ''
                              }`}
                              type="button"
                              onClick={() => {
                                setNewSiteModal(true)
                              }}
                            >
                              <FaPen size={12} />
                            </button>
                            {/* </div> */}
                          </ToolTip>
                        </div>
                      </div>
                    </div>

                    {projectDetailsById?.customer_contact_details &&
                      siteDetails == null && (
                        <div className="px-6">
                          <span className="flex flex-col">
                            {projectDetailsById?.customer_contact_details
                              ?.emails !== null &&
                              projectDetailsById?.customer_contact_details
                                ?.emails.length !== 0 && (
                                <span className="inline-flex text-blue-300">
                                  <FaEnvelope
                                    className=" w-20 text-blue-300 "
                                    size={16}
                                  />{' '}
                                  <span
                                    className=" text-blue-300"
                                    // style={{ color: 'rgb(52,100,176)' }}
                                  >
                                    {
                                      projectDetailsById
                                        ?.customer_contact_details?.emails[0]
                                    }
                                  </span>
                                </span>
                              )}
                            {projectDetailsById?.customer_contact_details
                              ?.phones !== null &&
                              projectDetailsById?.customer_contact_details
                                ?.phones.length !== 0 && (
                                <span className="inline-flex text-[inherit]">
                                  <FaPhone className="mx-10 w-20" size={16} />
                                  {
                                    projectDetailsById?.customer_contact_details
                                      ?.phones[0]
                                  }
                                </span>
                              )}
                            {projectDetailsById?.customer_contact_details
                              ?.fax !== null &&
                              projectDetailsById?.customer_contact_details?.fax
                                .length !== 0 && (
                                <span className="inline-flex">
                                  <FaFax className="mx-10 w-20" size={16} />
                                  {
                                    projectDetailsById?.customer_contact_details
                                      ?.fax[0]
                                  }
                                </span>
                              )}
                            {projectDetailsById?.customer_contact_details
                              ?.websites !== null &&
                              projectDetailsById?.customer_contact_details
                                ?.websites.length !== 0 && (
                                <span className="inline-flex">
                                  <FaGlobe className="mx-10 w-20" size={16} />
                                  {
                                    projectDetailsById?.customer_contact_details
                                      ?.websites[0]
                                  }
                                </span>
                              )}
                          </span>
                        </div>
                      )}

                    {siteDetails?.defaultContact && (
                      <div className="px-6">
                        <span className="flex text-gray-300 flex-col">
                          {siteDetails?.defaultContact?.emails &&
                            siteDetails?.defaultContact?.emails.length !==
                              0 && (
                              <span className="inline-flex">
                                <FaEnvelope className="mx-10 w-20" size={16} />{' '}
                                {siteDetails?.defaultContact?.emails[0]}
                              </span>
                            )}
                          {siteDetails?.defaultContact?.phones &&
                            siteDetails?.defaultContact?.phones.length !==
                              0 && (
                              <span className="inline-flex">
                                <FaPhone className="mx-10 w-20" size={16} />
                                {siteDetails?.defaultContact?.phones[0]}
                              </span>
                            )}
                          {siteDetails?.defaultContact?.fax &&
                            siteDetails?.defaultContact?.fax.length !== 0 && (
                              <span className="inline-flex">
                                <FaFax className="mx-10 w-20" size={16} />
                                {siteDetails?.defaultContact?.fax[0]}
                              </span>
                            )}
                          {siteDetails?.defaultContact?.websites &&
                            siteDetails?.defaultContact?.websites.length !==
                              0 && (
                              <span className="inline-flex">
                                <FaGlobe className="mx-10 w-20" size={16} />
                                {siteDetails?.defaultContact?.websites[0]}
                              </span>
                            )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* <SiteModal
                newSiteModal={newSiteModal}
                setNewSiteModal={setNewSiteModal}
              /> */}
            </div>
          </div>

          <CustomerModal
            newCustomerModal={newCustomerModal}
            setNewCustomerModal={setNewCustomerModal}
            // customerAddCallback={() => dispatch(getCustomer())}
            activeCustomer={customerData}
            isEdit={activeEditMode}
          />

          <NewContactModal
            addNewContactModal={newSiteModal}
            setNewContactModal={setNewSiteModal}
            activeSiteDetails={activeSiteDetails}
          />
        </div>
      </div>

      <div className="mt-20">
        <ProjectDetails customerSiteId={customerSiteId} />
      </div>

      <div className="mt-20">
        <SiteVisits />
      </div>
    </div>
  )
}
