import {useCallback, useEffect, useMemo} from 'react'
import {ActiveLink, useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'

import {DataTable} from 'app/components'
import {
  deletePreliminaryTemplateAction,
  getPreliminaryTemplateAction,
} from 'redux-src'
import {ConfirmationModal, ToolTip} from 'app/common'
import {FaPen, FaTrash} from 'react-icons/fa'

interface PreliminaryTemplateType {
  id: number
  title: string
  totalCost: string
  isPublished: boolean
}

export const PreliminaryTemplateList = ({
  filterValue,
}: {
  filterValue: string
}) => {
  const {
    preliminaryTemplateList,
    preliminaryTemplateTotal,
    preliminaryTemplateListLoading,
    updatePreliminaryTemplateLoading,
  }: RT.PreliminaryTemplateReduxType = useSelector(
    (state: any) => state.preliminaryTemplate,
  )

  const {currencySymbol} = useAuth()
  const {
    navigation: {navigate},
  } = useNavigation()

  const dispatch = useDispatch()

  const remappedPreliminaryTemplates = useMemo(() => {
    const remappedTemplates = preliminaryTemplateList?.map(
      ({prelim_template_details}) => {
        return {
          id: prelim_template_details?.id,
          title: prelim_template_details?.title,
          totalCost: String(prelim_template_details?.total_cost),
          isPublished: prelim_template_details?.is_published,
        }
      },
    )

    return remappedTemplates
  }, [preliminaryTemplateList])

  const onRemovePreliminaryTemplate = (templateId: number) => {
    if (!!templateId) {
      dispatch(
        deletePreliminaryTemplateAction(templateId, () => {
          getPreliminaryTemplateList()
        }),
      )
    }
  }

  const getPreliminaryTemplateList = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getPreliminaryTemplateAction({page, limit, search: filterValue}))
    },
    [dispatch, filterValue],
  )

  useEffect(() => {
    getPreliminaryTemplateList()
  }, [getPreliminaryTemplateList])

  const columns: TableColumn<PreliminaryTemplateType>[] = [
    {
      name: 'Template Title',
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <ActiveLink
            to={`/settings/preliminary-templates/${row.id}`}
            className={'text-blue-300 font-medium'}
          >
            {row.title}
          </ActiveLink>
        )
      },
      sortable: true,
    },
    {
      name: 'Total Cost',
      selector: (row) => row.totalCost,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {currencySymbol} {row.totalCost}
          </div>
        )
      },
    },
    {
      name: 'Published Status',
      selector: (row) => row.isPublished,
      sortable: true,
      cell: (row) => {
        return (
          <div
            className={`px-2 py-[4px] text-white text-center text-[12px] rounded-sm ${
              row.isPublished ? 'bg-blue-300' : 'bg-red-300'
            }`}
          >
            {row.isPublished ? 'Published' : 'Not Published'}
          </div>
        )
      },
    },
    {
      name: 'Actions',
      selector: (row) => row.totalCost,
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex items-center justify-end gap-10">
            <ToolTip text="Edit Template" top>
              <div
                className="px-4 py-4 cursor-pointer text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
                onClick={() => {
                  navigate(`/settings/preliminary-templates/${row.id}`)
                }}
              >
                <span className="text-left inline-flex cursor-pointer">
                  <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
                </span>
              </div>
            </ToolTip>
            <ConfirmationModal
              loading={updatePreliminaryTemplateLoading}
              onConfirmClick={(closeModalHandler) => {
                onRemovePreliminaryTemplate(row?.id)
                closeModalHandler()
              }}
              danger
              label={
                'Are you sure you want to delete this Preliminary Template?'
              }
              displayElement={
                <ToolTip text="Delete Template" left>
                  <div className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center cursor-pointer">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </div>
                </ToolTip>
              }
              confirmLabel="Delete"
            ></ConfirmationModal>
          </div>
        )
      },
    },
  ]

  return (
    <DataTable
      data={remappedPreliminaryTemplates ?? []}
      columns={columns}
      pagination
      totalRows={preliminaryTemplateTotal}
      fetchData={getPreliminaryTemplateList}
      loading={preliminaryTemplateListLoading}
    />
  )
}
