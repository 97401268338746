import {ToolTip} from 'app/common'
import {DynamicSearchSelect} from 'app/components'
import {useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {
  // FaComments,
  FaEnvelope,
  FaFax,
  FaGlobe,
  FaPen,
  FaPhone,
  FaTimes,
  FaUser,
} from 'react-icons/fa'
import {
  HiLocationMarker,
  HiOfficeBuilding,
  HiOutlineRefresh,
  HiPlusSm,
  HiSearch,
} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {getSiteById} from 'redux-src'
import {SiteModal} from '../../../sites/components'

export const SiteDetails = ({
  customerProps: {customerDetails, setCustomerDetails},
  siteProps: {siteDetails, setSiteDetails},
}: Comp.SiteDetailsProps) => {
  const {toast} = useAuth()
  const {sites}: RT.SiteReduxType = useSelector((state: any) => state.site)

  const [newSiteModal, setNewSiteModal] = useState(false)
  const [isSameAsCustomer, setSameAsCustomer] = useState(false)
  const [isSiteSection, setSiteSection] = useState(false)
  const [activeSiteDetails, setActiveSiteDetails] =
    useState<Api.SiteDetailsByIdType>()

  const [filterData, setFilterData] = useState('')
  const [siteList, setSiteList] = useState(null)

  const dispatch = useDispatch()

  const siteRemapper = useCallback(
    (filterValue) => {
      const _temp: any = []
      sites.map((item) => {
        if (item?.site_details?.name === null) {
          if (
            item?.site_details?.name
              ?.toLowerCase()
              .includes(filterValue?.toLowerCase())
          ) {
            return _temp.push({
              key: item.site_details?.id,
              name: item.site_details?.name,
              value: item.main_contact_details?.firstname,
            })
          }
        } else if (
          item.site_details?.name
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase())
        ) {
          return _temp.push({
            key: item.site_details?.id,
            name: item.site_details?.name,
            value: item.main_contact_details?.firstname,
          })
        }
        return null
      })
      setSiteList(_temp)
    },
    [sites],
  )

  const onClickSameAsCustomer = () => {
    if (customerDetails !== undefined && customerDetails) {
      const {
        customer_details: {name, info},
        postal_address_details,
        default_contact_details,
        billing_contact_details,
        physical_address_details,
      } = customerDetails

      // console.log('siteDetails: ', _siteDetails)
      setSiteDetails({
        site_details: {
          id: undefined,
          info,
          name,
          status: 'active',
          company_id: 1,
          created_at: '2023-04-25T09:12:24.996+00:00',
        },
        physical_address_details,
        postal_address_details,
        billing_contact_details,
        default_contact_details,
      } as Api.SiteDetailsByIdType)
      setSameAsCustomer(true)

      setSiteSection(true)
    } else toast.warning('Please enter the customer details first!')
  }

  const onAddNewSite = () => {
    setNewSiteModal(true)
  }

  const setSiteValue = (id: number) => {
    // const siteValue = sites.find((site) => site?.site_details?.id === id)
    dispatch(
      getSiteById(id, (siteValue) => {
        // console.log(_siteDetails)
        console.log(siteValue)
        setActiveSiteDetails(siteValue)
        setSiteDetails(siteValue)

        setSameAsCustomer(false)
        setSiteSection(true)
      }),
    )
  }

  useEffect(() => {
    siteRemapper(filterData)
  }, [filterData, siteRemapper])

  return (
    <div>
      <div className="flex bg-white">
        <div className="flex-1 w-full px-16">
          <div className="border-1 border-gray-200 mt-1 relative rounded-md shadow-sm flex py-20">
            <div className="px-30 justify-center items-center text-blue-300 flex border-r-1 border-gray-200">
              <div className="flex-nowrap">
                <HiOfficeBuilding size="60" />
                <p className="text-center">Site</p>
              </div>
            </div>
            {/* {true && ( */}
            {!isSameAsCustomer && !isSiteSection ? (
              <div className="flex flex-col mx-16 flex-1 lg:w-12/12 gap-14">
                <h1 className="font-bold text-xl">Site Details</h1>
                <div className="flex justify-center items-center w-full  ">
                  <button
                    className=" w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200 cursro-pointer"
                    // disabled
                    onClick={() => onClickSameAsCustomer()}
                  >
                    {' '}
                    <span className="inline-flex text-gray-400 mt-4">
                      <HiOutlineRefresh size="20" />
                      Same as Customer
                    </span>
                  </button>
                </div>
                <div className="flex justify-center items-center  w-full">
                  <button
                    className=" w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                    onClick={() => {
                      setNewSiteModal(true)
                      setActiveSiteDetails(undefined)
                    }}
                  >
                    {' '}
                    <span className="inline-flex text-gray-400 mt-4">
                      <HiPlusSm size="20" />
                      New Site Contact & Address
                    </span>
                  </button>
                </div>
                {customerDetails !== undefined && customerDetails && (
                  <div className="flex justify-center items-center w-full  ">
                    <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
                      <HiSearch size={18} />
                    </div>
                    <DynamicSearchSelect
                      actionUrl="/sites"
                      placeholder="Search for an existing sites"
                      remapOptions={(data: Api.SiteIndividual[]) => {
                        return data?.map(
                          ({
                            site_details: sd,
                            physical_address_details: pad,
                            main_contact_details: mc,
                          }) => {
                            return {
                              id: sd?.id,
                              label: [mc?.firstname, pad?.address].join(' - '),
                              value: sd?.info,
                            }
                          },
                        )
                      }}
                      customOption={
                        <div
                          onClick={onAddNewSite}
                          className="flex flex-nowrap justify-start items-center gap-8 py-6 px-10 cursor-pointer border-b-1 border-gray-200 hover:bg-blue-300 hover:text-white transition-all duration-200 ease-linear"
                        >
                          <HiPlusSm size="20" />
                          New Site
                        </div>
                      }
                      onChangeValue={(value) => {
                        setSiteValue(value.id)
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full lg:w-12/12 px-2">
                <div className="mx-4">
                  <div className="">
                    <div className="flex items-center justify-between  font-bold  px-12 border-gray-200 mb-20">
                      <span className="px-4 text-blue-300 hover:text-blue-400 cursor-pointer font-bold">
                        {siteDetails?.site_details?.name}
                      </span>
                      <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100">
                        {/* <BootstrapTooltip title="Quick Email"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickEmail}
                          >
                            <FaEnvelope size={12} />
                          </button>
                        </div>
                        <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Quick SMS"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickSms}
                          >
                            <FaComments size={12} />
                          </button>
                        </div>
                        <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Map"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickMap}
                          >
                            <HiLocationMarker size={12} />
                          </button>
                        </div>
                        <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Edit this contact"> */}
                        <ToolTip text="Edit" top>
                          <button
                            className={`p-6 hover:bg-gray-200 ${
                              isSameAsCustomer
                                ? 'text-gray-300 cursor-not-allowed'
                                : ''
                            } `}
                            type="button"
                            onClick={() => {
                              if (isSameAsCustomer) return
                              setNewSiteModal(true)
                            }}
                          >
                            <FaPen size={12} />
                          </button>
                        </ToolTip>
                        <div className="border-r-1 border-gray-200" />
                        {/* <BootstrapTooltip title="Change customer contact"> */}
                        <ToolTip text="Cancel" top>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            onClick={() => {
                              setSiteSection(false)
                              setSameAsCustomer(false)
                            }}
                          >
                            <FaTimes size={12} />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-2">
                  <div className="mx-4 px-22 pb-10">
                    <span className="inline-flex text-gray-300">
                      <HiLocationMarker
                        className="text-gray-300 mx-10 w-20"
                        size={16}
                      />
                      <p>{siteDetails?.physical_address_details?.address}, </p>
                    </span>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 px-2 mx-4">
                  <div className="px-22">
                    <span className="inline-flex text-gray-300">
                      <FaUser className="text-gray-300 mx-10 w-20" size={16} />{' '}
                      {siteDetails?.default_contact_details &&
                        siteDetails?.default_contact_details?.firstname}{' '}
                      {siteDetails?.default_contact_details &&
                        siteDetails?.default_contact_details?.lastname}{' '}
                      (
                      {siteDetails?.default_contact_details &&
                        siteDetails?.default_contact_details?.position}
                      )
                    </span>
                  </div>

                  {siteDetails?.default_contact_details && (
                    <div className="px-22">
                      <span className="flex text-gray-300 flex-col">
                        {siteDetails?.default_contact_details?.emails &&
                          siteDetails?.default_contact_details?.emails
                            .length !== 0 && (
                            <span className="inline-flex">
                              <FaEnvelope className="mx-10 w-20" size={16} />{' '}
                              {siteDetails?.default_contact_details?.emails[0]}
                            </span>
                          )}
                        {siteDetails?.default_contact_details?.phones &&
                          siteDetails?.default_contact_details?.phones
                            .length !== 0 && (
                            <span className="inline-flex">
                              <FaPhone className="mx-10 w-20" size={16} />
                              {siteDetails?.default_contact_details?.phones[0]}
                            </span>
                          )}
                        {siteDetails?.default_contact_details?.fax &&
                          siteDetails?.default_contact_details?.fax.length !==
                            0 && (
                            <span className="inline-flex">
                              <FaFax className="mx-10 w-20" size={16} />
                              {siteDetails?.default_contact_details?.fax[0]}
                            </span>
                          )}
                        {siteDetails?.default_contact_details?.websites &&
                          siteDetails?.default_contact_details?.websites
                            .length !== 0 && (
                            <span className="inline-flex">
                              <FaGlobe className="mx-10 w-20" size={16} />
                              {
                                siteDetails?.default_contact_details
                                  ?.websites[0]
                              }
                            </span>
                          )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
            <SiteModal
              newSiteModal={newSiteModal}
              setNewSiteModal={setNewSiteModal}
              siteAddCallback={(id) => {
                setSiteValue(id)
              }}
              activeSiteDetails={activeSiteDetails}
            />

            {/* <NewContactModal
              addNewContactModal={newSiteModal}
              setNewContactModal={setNewSiteModal}
              activeSiteDetails={activeSiteDetails}
              setSiteDetails={setSiteValue}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
