import {useCallback, useEffect} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth} from 'react-auth-navigation'

import {Button, Textarea} from 'app/common'
import {DescriptionBox} from 'app/components'

import {getQuoteSettings, updateQuoteSettings} from 'redux-src'

import {SettingsHeader} from '../../components'

export const QuotingPage = () => {
  const {toast} = useAuth()

  const {companyDetails: company}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const dispatch = useDispatch()

  const {defaultQuoteSettings: defaultQuote}: RT.QuoteSettingsReduxType =
    useSelector((state: any) => state.quoteSetting)

  const [data, {onChange, onSubmit, setValue}] = useFormInput(
    {
      expiresIn: 60,
      fontSize: 'medium',
      orientation: 'portrait',
      descriptionText: 'This is quote description text',
      footerText: 'Quote footer text',
      showLineItemQuantities: true,
      showLineItemPrices: true,
      showLineItemTotals: true,
      showLineItems: true,
      showSectionTotals: true,
      emailCustomerOnManualQuoteAccept: true,
    },
    (data) => {
      // console.log(data)
      dispatch(
        updateQuoteSettings(
          company?.company_details?.id,
          data,
          () => {
            dispatch(getQuoteSettings(company?.company_details?.id))
          },
          toast,
        ),
      )
    },
  )

  useEffect(() => {
    if (company?.company_details?.id)
      dispatch(getQuoteSettings(company?.company_details?.id))
  }, [company?.company_details?.id, dispatch])

  const setDefaultQuoteSettings = useCallback(() => {
    setValue('expiresIn', defaultQuote?.expires_in ?? '')
    setValue('fontSize', defaultQuote?.font_size ?? '')
    setValue('orientation', defaultQuote?.orientation ?? '')
    setValue('descriptionText', defaultQuote?.description_text ?? '')
    setValue('footerText', defaultQuote?.footer_text ?? '')
    setValue(
      'showLineItemQuantities',
      defaultQuote?.show_line_item_quantities ?? false,
    )
    setValue('showLineItemPrices', defaultQuote?.show_line_item_prices ?? false)
    setValue('showLineItemTotals', defaultQuote?.show_line_item_totals ?? false)
    setValue('showLineItems', defaultQuote?.show_line_items ?? false)
    setValue('showSectionTotals', defaultQuote?.show_section_totals ?? false)
    setValue(
      'emailCustomerOnManualQuoteAccept',
      defaultQuote?.email_customer_on_manual_quote_accept ?? false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultQuote?.description_text,
    defaultQuote?.email_customer_on_manual_quote_accept,
    defaultQuote?.expires_in,
    defaultQuote?.font_size,
    defaultQuote?.footer_text,
    defaultQuote?.orientation,
    defaultQuote?.show_line_item_prices,
    defaultQuote?.show_line_item_quantities,
    defaultQuote?.show_line_item_totals,
    defaultQuote?.show_line_items,
    defaultQuote?.show_section_totals,
  ])

  useEffect(() => {
    setDefaultQuoteSettings()
  }, [setDefaultQuoteSettings])

  return (
    <div className="mx-10 mt-6">
      <SettingsHeader title="Quoting Settings" />
      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <form id="quoting-settings" className="relative" onSubmit={onSubmit}>
        <div className="relative">
          <DescriptionBox
            className="mb-20 h-full"
            title="Default Description Text"
          >
            <div className="px-12 py-2">
              <div className="mb-20">
                <div className="-mx-16">
                  <div className="w-full">
                    <Textarea
                      name="descriptionText"
                      value={data.descriptionText}
                      onChange={onChange}
                      containerClass="h-[200px] px-16 w-full"
                      className="h-[400px] resize-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DescriptionBox>
          <DescriptionBox className="mb-20 h-full" title="Default Footer Text">
            <div className="mb-20">
              <div className="px-12 py-2">
                <div className="-mx-16">
                  <div className="w-full">
                    <Textarea
                      name="footerText"
                      value={data.footerText}
                      onChange={onChange}
                      containerClass="h-[200px] px-16 w-full"
                      className="h-[400px] resize-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DescriptionBox>

          <div className="sticky bottom-0 flex justify-end items-end bg-white px-28 py-20 border-t-1 border-gray-200">
            <Button
              title="Save All Settings"
              type="submit"
              form="quoting-settings"
              className=" shadow-lg"
            />
          </div>
        </div>
      </form>
    </div>
  )
}
