import {NavLinkProps} from '../components'

export const NAV_LINKS: NavLinkProps[] = [
  {to: '/onboarding', title: 'Getting Started'},
  {to: '/dashboard', title: 'Home'},
  {to: '/customers', title: 'Customers'},
  {to: '/sites', title: 'Sites'},
  // { to: '/health-and-safety', title: 'Health & Safety', premium: true },
  {to: '/calendar', title: 'Calendar'},
  // { to: '/map', title: 'Map', premium: true },
  // {to: '/supplier-doc', title: 'Supplier Doc'},
]
