import {ReactNode, useCallback, useEffect, useState} from 'react'
// import {PiDotsThreeOutlineVerticalFill} from 'react-icons/pi'
import {MdSearch} from 'react-icons/md'
import {
  Button,
  Checkbox,
  CustomModal,
  InfiniteScrollBlock,
  Loading,
} from 'app/common'
import {useDispatch, useSelector} from 'react-redux'
import {
  getNotifications,
  markAllNotificatonAsRead,
  markNotificatonAsRead,
  markNotificatonAsUnread,
} from 'redux-src/actions/notification.action'
import moment from 'moment'
import {MessageBox} from 'app/components/messageBox'
import {useNavigation} from 'react-auth-navigation'
import {useDebounceValue} from 'hooks'

export function NotificationModal({
  displayElement,
}: {
  displayElement: ReactNode
}) {
  // getting notifications from api
  const dispatch = useDispatch()
  const [params, setParams] = useState<{
    page?: number
    limit?: number
    search?: string
    isRead?: boolean
  }>({
    page: 1,
    limit: 10,
  })
  const {
    notifications,
    isNotificationsLoading,
    isNotificationLast,
  }: RT.NotificationReduxType = useSelector((state: any) => state.notifications)

  const refetchNotification = useCallback(() => {
    dispatch(getNotifications({...params}))
  }, [dispatch, params])

  // Change status of single notification from read or unread
  // Function is passed to notification body and used in 'mark as read' button
  const markReadUnread = (
    notification: Api.Notification,
    markUnreadOnly?: boolean,
  ) => {
    if (markUnreadOnly && notification.is_read) {
      return
    }
    if (notification.is_read) {
      dispatch(
        markNotificatonAsUnread(
          notification.notification_details.id,
          refetchNotification,
        ),
      )
    } else {
      dispatch(
        markNotificatonAsRead(
          notification.notification_details.id,
          refetchNotification,
        ),
      )
    }
  }

  const markAllAsRead = () => {
    // Only dispatch to mark all as read if notifications exists
    if (notifications?.length > 0) {
      dispatch(markAllNotificatonAsRead(refetchNotification))
    }
  }

  const handleInfinityScroll = () => {
    setParams((prev) => ({...prev, limit: prev?.limit + 10}))
  }

  useEffect(() => {
    refetchNotification()
  }, [refetchNotification])

  return (
    <CustomModal
      size="md"
      title={
        <div className="header-holder flex justify-between w-full ">
          <NotificationModalHeader
            setParams={setParams}
            markAllAsRead={markAllAsRead}
          />
        </div>
      }
      displayElement={displayElement}
    >
      {({onCloseModalHandler}) => (
        <div className="notification-body-holder w-full">
          {isNotificationsLoading && notifications?.length <= 0 ? (
            <Loading />
          ) : (
            <NotificationBody
              onClose={onCloseModalHandler}
              handleInfinityScroll={handleInfinityScroll}
              markReadUnread={markReadUnread}
              notifications={notifications}
              isNotificationLast={isNotificationLast}
            />
          )}
        </div>
      )}
    </CustomModal>
  )
}

function NotificationModalHeader({
  markAllAsRead,
  setParams,
}: {
  markAllAsRead: () => void
  setParams?: any
}) {
  const {notifications, unreadCount}: RT.NotificationReduxType = useSelector(
    (state: any) => state.notifications,
  )

  const [showUnread, setShowUnread] = useState<boolean>(undefined)

  const dispatch = useDispatch()

  // Fetch notification once when modal is opened
  const [searchQuery, setSearchQuery] = useState<string>(null)
  const searchDebounce = useDebounceValue(searchQuery)

  useEffect(() => {
    setParams((prev: any) => ({
      ...prev,
      search: !!searchDebounce ? searchDebounce : undefined,
      isRead: !!showUnread ? false : undefined,
      limit: 10,
    }))
  }, [dispatch, showUnread, searchDebounce, setParams])

  return (
    <div className="flex flex-col notification-heading gap-y-10 w-full">
      <div className="flex justify-between notification-title w-full">
        <div className="title-text flex">
          <h1 className="font-bold text-lg">All Notifications</h1>
          <div className="text-sm tracking-wide font-medium">
            Unread Notifications{' '}
            <span className="badge text-sm">{unreadCount ?? 0}</span>
          </div>
        </div>
      </div>

      <div className="flex justify-between w-full">
        {/* Left section of subheading */}
        <div className="search-field flex gap-x-10">
          {/* <div className="search flex p-10 gap-x-10">
            <FaSearch />
            <input
              type="text"
              className="text-md search-input"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              placeholder="Search Notifications"
            />

            <MdClose
              onClick={() => {
                setSearchValue('')
              }}
              className={`${
                searchValue !== '' ? 'cursor-pointer' : ''
              } text-md`}
              style={{opacity: `${searchValue !== '' ? 1 : 0}`}}
            />
          </div> */}

          <div className="mx-16 flex justify-between text-black">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-8 flex items-center pointer-events-none">
                <MdSearch className="text-lg" />
              </div>
              <input
                autoComplete="off"
                type="text"
                name="search"
                id="search"
                className="focus:outline-none block w-full  pl-32 py-8 sm:text-sm bg-[#f1f1f1] border-gray-300 rounded-sm"
                placeholder="Search"
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="view-only-unread flex gap-x-6 items-center">
            <Checkbox
              dataId="notification-unread"
              label="View only unread notifications"
              isChecked={showUnread}
              value={showUnread}
              onChange={(value, e) => {
                setShowUnread((value) => !value)
              }}
            />
          </div>
        </div>

        {/* Right section that consists of mark all as read button and more */}
        <div className="notification-header-buttons flex">
          <div
            onClick={() => {
              markAllAsRead()
            }}
            className={`mark-all-read-btn font-bold text-md ${
              unreadCount <= 0 ||
              (notifications?.length <= 0 && 'mark-all-read-btn--disabled')
            }
            bg-blue-300 hover:bg-blue-400 px-20 py-8  text-sm text-white cursor-pointer rounded-sm whitespace-nowrap font-bold
            `}
          >
            Mark all as read
          </div>

          {/* <div className="more-options-dots flex text-lg font-bold">
            <PiDotsThreeOutlineVerticalFill />
          </div> */}
        </div>
      </div>
    </div>
  )
}

interface NotificationBodyProp {
  markReadUnread: (
    notification: Api.Notification,
    markUnreadOnly?: boolean,
  ) => void
  notifications: Array<Api.Notification>
  handleInfinityScroll: () => void
  isNotificationLast: boolean
  onClose: () => void
}

const NotificationBody = (props: NotificationBodyProp) => {
  const {
    markReadUnread,
    notifications,
    handleInfinityScroll,
    isNotificationLast,
    onClose,
  } = props

  const {
    navigation: {navigate},
  } = useNavigation()

  const handleNavigation = (notification: Api.Notification, cb: () => void) => {
    let url = ''
    // let resultingProjectId = notification?.notification_details?.details?.project_id
    switch (notification?.type) {
      case 'ticket':
        url = `/projects/${notification?.notification_details?.details?.project_id}/ticket?showTicket=true&ticketId=${notification?.notification_details?.details?.id}`
        break
      case 'project':
        url = `/projects/${notification?.notification_details?.details?.id}/projects-summary`
        break
      case 'quote':
        url = `/projects/${notification?.notification_details?.details?.project_id}/quote`
        break
      case 'invoice':
        url = `/projects/${notification?.notification_details?.details?.project_id}/invoicing`
        break

      case 'worker_invoice':
        url = `/settings/users/${notification?.notification_details?.details?.user_id}/invoice`
        break
      case 'project_note':
        url = `/projects/${notification?.notification_details?.details?.project_id}?note_id=${notification?.notification_details?.details?.note_id}`
        break
      default:
        return
    }

    markReadUnread(notification, true)
    navigate(url)
    cb()
  }

  return (
    <div className="notification-body w-full flex gap-y-6 flex-col py-6">
      {!notifications || notifications?.length <= 0 ? (
        <MessageBox message={'You do not have any new notifications!'} error />
      ) : (
        <InfiniteScrollBlock
          key={'Notification Scroll'}
          className="flex flex-1 flex-col gap-6 py-6 pr-10 border-r-1 border-gray-200 overflow-y-scroll overflow-x-hidden h-full"
          triggerCallback={() => {
            handleInfinityScroll()
          }}
          isLast={isNotificationLast}
        >
          {notifications.map((notification, i) => (
            <div
              key={notification.notification_details.id}
              className={`notification-tab ${
                notification.is_read ? '' : 'notification-tab--unread'
              } ${`notification-tab--${notification.type}`} flex w-full justify-between`}
              onClick={() => {
                handleNavigation(notification, () => {
                  onClose()
                })
              }}
            >
              <div className="notification-text">
                <div className="time-frame ">
                  {moment(
                    notification.notification_details.created_at,
                  ).fromNow()}
                </div>
                <div className="notification-title">
                  {notification.notification_details.message}
                </div>
              </div>
              <Button
                onClick={(e) => {
                  markReadUnread(notification)
                  e.stopPropagation()
                }}
                className="notification-button flex rounded-sm"
              >
                {notification.is_read ? 'Mark as unread' : 'Mark as read'}
              </Button>
            </div>
          ))}
        </InfiniteScrollBlock>
      )}
    </div>
  )
}

// function NotificationTab({notification}: {notification: Api.Notification}) {
//   const dispatch = useDispatch()
//   const markReadUnread = (notification: Api.Notification) => {
//     if (notification.is_read) {
//       dispatch(markNotificatonAsUnread(notification.notification_details.id))
//     } else {
//       dispatch(markNotificatonAsUnread(notification.notification_details.id))
//     }

//     dispatch(getNotifications())
//   }

//   return (
//     <div
//       className={`notification-tab
//       ${notification.is_read ? '' : 'notification-tab--unread'}
//       ${`notification-tab--${notification.notification_details.details.type}`}
//       flex w-full justify-between`}
//     >
//       <div className="notification-text text-md">
//         <div className="time-frame ">
//           {moment(notification.notification_details.created_at).fromNow()}
//         </div>
//         <div className="notification-title">
//           {notification.notification_details.message}
//         </div>
//       </div>

//       <div
//         onClick={(e) => {
//           markReadUnread(notification)
//         }}
//         className="notification-button flex rounded-sm"
//       >
//         {notification.is_read ? 'Mark as unread' : 'Mark as read'}
//       </div>
//     </div>
//   )
// }
