import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'
import {
  addLabourElementAction,
  getUnitListAction,
  updateLabourElementAction,
  getLabourElementAction,
  getLabourElementForSelectFieldAction,
  uploadLabourElementFile,
} from 'redux-src'

import {Button, Input, Modal, SelectField, toast} from 'app/common'

interface AddLabourElementProps {
  addLabourElementModal: boolean
  setLabourElementModal: React.Dispatch<React.SetStateAction<boolean>>
  mode?: 'edit' | 'attach'
  labourElementDetails?: any
}

export const AddLabourElement = ({
  addLabourElementModal,
  setLabourElementModal,
  mode,
  labourElementDetails,
}: AddLabourElementProps) => {
  const [newUnitList, setNewUnitList] = useState<
    {
      id: number
      label: string
      value: string
    }[]
  >()
  const {unitList}: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements,
  )

  const dispatch = useDispatch()

  const [data, {onChange, onSubmit, errors, modified, clear, setValue}] =
    useFormInput(
      {
        name: '',
        timeMins: '',
        unitName: '',
      },
      (data) => {
        if (mode !== 'edit') {
          dispatch(
            addLabourElementAction(
              {
                name: data.name,
                unitName: data.unitName,
                timeMins: Number(data.timeMins),
              },
              () => {
                clear()
                setLabourElementModal(false)
                dispatch(getLabourElementAction())
                dispatch(getLabourElementForSelectFieldAction())
              },
            ),
          )
        } else {
          dispatch(
            updateLabourElementAction(
              labourElementDetails.id,
              {
                name: data.name,
                unitName: data.unitName,
                timeMins: Number(data.timeMins),
              },
              () => {
                clear()
                setLabourElementModal(false)
                dispatch(getLabourElementAction())
                dispatch(getLabourElementForSelectFieldAction())
              },
              toast,
            ),
          )
        }
      },
      (data) => {
        const errors: any = {}

        if (data.name.length === 0) {
          errors.name = 'Please enter labour element name!'
        } else if (data.timeMins.length === 0) {
          errors.timeMins = 'Please enter timeMins'
        }

        return errors
      },
    )

  // Mark: Clear Fields
  const clearFields = () => {
    setLabourElementModal(false)
    if (mode !== 'edit') {
      clear()
    }
  }

  const remappedUnitList = useCallback(() => {
    const finalUnitList = unitList?.map((lists: any, idx: number) => {
      return {
        id: idx + 1,
        label: lists.unit_details.name,
        value: lists.unit_details.symbol,
      }
    })

    setNewUnitList(finalUnitList)
  }, [unitList])

  const prePopulatedValue = useCallback(() => {
    if (!!labourElementDetails) {
      Object.keys(labourElementDetails).map((labourItem: any) => {
        return setValue(labourItem, labourElementDetails[labourItem])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labourElementDetails])

  useEffect(() => {
    dispatch(getUnitListAction(() => {}))
  }, [dispatch])

  useEffect(() => {
    remappedUnitList()
  }, [remappedUnitList])

  useEffect(() => {
    prePopulatedValue()
  }, [prePopulatedValue])

  // * for controlling upload of csv or xls file
  const fileInputRef = useRef<HTMLInputElement>(null)

  const acceptedFileTypes = useMemo(
    () => [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ],
    [],
  )

  // const [file, setFile] = useState<File>()

  const handleFileSelection = (file: File) => {
    // const file = file

    if (!file) {
      toast.error('Please select a file in order to import labour elements.')
      setHasFileError(true)
    } else {
      if (acceptedFileTypes.includes(file.type)) {
        // * The type of file is excel or csv
        const formData = new FormData()
        formData.append('csvFile', file)

        if (file.type === 'text/csv') {
          dispatch(
            uploadLabourElementFile(formData, 'csv', () => {
              setLabourElementModal(false)
              dispatch(getLabourElementAction())
            }),
          )
        } else {
          dispatch(
            uploadLabourElementFile(formData, 'excel', () => {
              setLabourElementModal(false)
              dispatch(getLabourElementAction())
            }),
          )
        }
        setHasFileError(false)
      } else {
        toast.error(
          'Please select a valid file type. Accepted file type includes csv and excel.',
        )
        setHasFileError(true)
      }
      // e.target.value = null
      // e.target.files = null
    }
  }

  const [dragActive, setDragActive] = useState<boolean>(false)

  const [hasFileError, setHasFileError] = useState<boolean>(false)

  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileSelection(e.dataTransfer.files[0])
    }
  }

  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else {
      setDragActive(false)
    }
  }

  return (
    <Modal
      isActive={addLabourElementModal}
      toggleModal={setLabourElementModal}
      title={
        mode === 'edit' ? 'Update a labour element' : 'Add a new labour element'
      }
      onClose={clearFields}
    >
      {/* Upload file form */}
      {/* Import from file button */}

      <form
        id="labour-element-type"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(e)
        }}
      >
        <div className="pt-[18px] px-[20px]">
          {mode !== 'edit' && (
            <>
              <input
                id="labour-elements-file"
                type="file"
                accept={String(acceptedFileTypes)}
                className="hidden"
                ref={fileInputRef}
                onChange={(e) => {
                  handleFileSelection(e?.target?.files[0])
                }}
                // value={file}
              />

              <label
                htmlFor="labour-elements-file"
                className={
                  'cursor-pointer border-1 w-full h-[110px] rounded-sm mb-20 flex justify-center items-center flex-col ' +
                  `${
                    dragActive
                      ? 'bg-gray-200 border-gray-300'
                      : hasFileError
                      ? 'bg-red-100 border-red-300'
                      : 'bg-blue-100 border-blue-200'
                  }`
                }
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onClick={() => {
                  if (fileInputRef) {
                    fileInputRef.current.click()
                  }
                }}
              >
                <label className="cursor-pointer text-gray-400 mt-auto">
                  Use these titles <i>"name", "unit_name", "time_mins"</i> in
                  your file compulsorily.
                </label>
                <label className="cursor-pointer text-gray-400 mt-auto">
                  Drag & drop your labour element files{' '}
                  <i>[.csv,.xlsx,.xls] </i>
                  here or
                </label>
                <label className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400">
                  Choose file
                </label>
              </label>
            </>
          )}
          <div className="flex flex-col gap-6">
            <Input
              type="text"
              name="name"
              value={data.name}
              onChange={onChange}
              error={errors.name && modified.name}
              placeholder="Name"
              label={'Name'}
              required
            />
            {modified.name && errors.name && (
              <span className="text-red-500 -mt-10">{errors.name}</span>
            )}
          </div>
          <div className="flex justify-between">
            <span className="flex flex-col gap-6 w-1/2 ">
              <div className="flex gap-4 font-bold">
                <span>Unit Name</span>
                <span className="text-red-500">*</span>
              </div>
              <SelectField
                options={newUnitList}
                value={newUnitList?.find((item) => {
                  return item.label === data.unitName
                })}
                onChangeValue={(e) =>
                  !Array.isArray(e) && setValue('unitName', e.label)
                }
                placeholder={'Select unit name'}
              />
            </span>
            <label htmlFor="timeMins" className="flex flex-col gap-6">
              <Input
                type="number"
                name="timeMins"
                value={data.timeMins}
                onChange={onChange}
                error={errors.timeMins && modified.timeMins}
                placeholder="Time Mins"
                label={'Time Mins'}
                required
              />
              {modified.timeMins && errors.timeMins && (
                <span className="text-red-500">{errors.timeMins}</span>
              )}
            </label>
          </div>
        </div>
        <div className="border border-t-1 border-gray-200 px-20 py-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => clearFields()}
            >
              Cancel
            </Button>
            <Button
              form="labour-element-type"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode !== 'edit' ? 'Save' : 'Edit'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
