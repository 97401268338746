import {Button, Input, Modal, toast} from 'app/common'
import {PasswordValidationInfo} from '../../../../components'
import {useInput, validatePassword} from 'hooks'
import {useDispatch} from 'react-redux'
import {changeIndividualUserPasswordAction} from 'redux-src'
import {useNavigation} from 'react-auth-navigation'

interface ChangePasswordProps {
  openModel: boolean
  setOpenModel: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChangePassword = (props: ChangePasswordProps) => {
  const {data, onChangeHandler, getErrors, error} = useInput({
    password: '',
    confirmPassword: '',
  })

  const {params} = useNavigation()
  const {id} = params as any

  const dispatch = useDispatch()

  const onChangePassword = () => {
    const {password, confirmPassword} = data

    const {errorFlag} = getErrors([
      {
        key: 'password',
        condition: validatePassword(password).condition,
        cb: (msg: any) => {
          toast.error(msg ?? `Please enter vaid password.`)
        },
      },
      {
        key: 'confirmPassword',
        condition: password !== confirmPassword,
        cb: () => {
          toast.error(`Password doesnt match.`)
        },
      },
    ])

    if (errorFlag) return

    dispatch(
      changeIndividualUserPasswordAction(
        id,
        {newPassword: confirmPassword},
        () => {
          props.setOpenModel(false)
        },
      ),
    )
  }

  return (
    <Modal
      isActive={props.openModel}
      toggleModal={props.setOpenModel}
      title="Change your password"
    >
      <div className="m-20">
        <div className="mt-16">
          <PasswordValidationInfo />

          <div className="flex flex-col mt-16 gap-16 text-gray-500">
            <Input
              label="Password"
              name="password"
              type="password"
              placeholder="********"
              size="sm"
              error={error['password']}
              onChange={onChangeHandler('password')}
            />
            <Input
              type="password"
              label="Confirm password"
              name="confirmPassword"
              placeholder="********"
              size="sm"
              error={error['confirmPassword']}
              onChange={onChangeHandler('confirmPassword')}
            />
          </div>
        </div>
      </div>
      <div className="border-t-1 border-gray-200 "></div>

      <div className="flex justify-end px-16 py-10">
        <Button
          title="cancel"
          buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
          size="sm"
          type="button"
          onClick={() => props.setOpenModel(false)}
        />
        <div className="ml-16">
          <Button
            title="Save"
            size="sm"
            type="submit"
            onClick={onChangePassword}
          />
        </div>
      </div>
    </Modal>
  )
}
