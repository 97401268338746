import {useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {FaInfoCircle} from 'react-icons/fa'
import {MdWarning} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {Button, Input, Modal, Loading, ColorPicker} from 'app/common'
import {GroupSelectedAppUserTable} from '../groupTable'

import {capitalizeFirstLetterWithSpace} from 'utils'
import {DynamicSearchSelect} from 'app/components'

import {
  getCompanyUserList,
  createUserGroupAction,
  getCompanyUserGroupList,
} from 'redux-src'

export const GroupModal = ({newGroupModal, setNewGroupModal}: any) => {
  //! REDUX HANDLING
  const {
    // userDetails: { clientId },
    toast,
  } = useAuth()

  const dispatch = useDispatch()
  const {
    appUsers,
    getAppUserList_loading,
  }: // companyUserList,
  RT.UserReduxType = useSelector((state: any) => state.user)

  const {add_loading} = useSelector((state: any) => state.group)

  const [appUserBufferList, setAppUserBufferList] = useState([])

  const [selectedAppUsers, setSelectedAppUsers] = useState([])

  const [isTable, setTable] = useState(false)

  const [formData, {onSubmit, onChange, setValue, errors, modified, clear}] =
    useFormInput(
      {
        // clientId,
        name: '',
        colorCode: '',
        description: '',
        companyUsers: [],
      },
      (data) => {
        const {name, colorCode, description, companyUsers} = data

        const userGroupBody = {
          name,
          description:
            !!description || description !== '' ? description : undefined,
          userIds: companyUsers,
          color: colorCode,
        }

        data.colorCode.length === 0
          ? toast.error('Please choose a color for the group!')
          : dispatch(
              createUserGroupAction(userGroupBody, () => {
                clearFields()
                dispatch(getCompanyUserGroupList())
              }),
            )
      },
      (data) => {
        const errors: any = {}
        if (data.name.length === 0) {
          errors.name = 'Please enter the group name!'
        }

        if (data.description.length === 0) {
          errors.description = 'Please enter the group description!'
        }

        if (data.companyUsers.length === 0) {
          errors.appUsers = 'Please select at least one app user!'
        }
        return errors
      },
    )

  // MARK: Clear Fields
  const clearFields = () => {
    setNewGroupModal(false)
    clear()
  }

  // let appUserBufferList
  const appUserBuffer = useCallback(
    (newAppUserList?: any) => {
      if (newAppUserList) {
        setAppUserBufferList(newAppUserList.flat())
        return [...newAppUserList]
      } else {
        setAppUserBufferList(appUsers)
        return [...appUsers]
      }
    },
    [appUsers],
  )

  const onSelectedUserDelete = (itemKey: number) => {
    const _tempAppUserList: any = selectedAppUsers
      ?.filter((item: any) => item.id !== itemKey)
      .flat()
    const _tempAppUserBufferList: any = [...appUserBufferList]

    _tempAppUserBufferList.push(
      appUsers?.find((item: any) => item.id === itemKey),
    )

    appUserBuffer(_tempAppUserBufferList)
    setSelectedAppUsers(_tempAppUserList)
    setValue('companyUsers', _tempAppUserList)
    errors.companyUsers && setTable(false)
  }

  const handelGroupColorChange = (colorValue: string) => {
    setValue('colorCode', colorValue)
  }

  useEffect(() => {
    appUserBuffer()
  }, [appUserBuffer])

  useEffect(() => {
    dispatch(getCompanyUserList())
  }, [dispatch])

  return (
    <>
      <Modal
        size="sm"
        isActive={newGroupModal}
        toggleModal={setNewGroupModal}
        title="Add a New Group"
        onClose={clearFields}
      >
        {getAppUserList_loading || add_loading ? (
          <Loading />
        ) : (
          <form onSubmit={onSubmit}>
            <div className="mx-16 my-6 mb-0 h-[40vh] flex flex-col flex-nowrap  overflow-y-auto">
              <div className="w-full lg:w-12/12 px-10 mb-10">
                <div className="flex items-center gap-6 px-8 py-6">
                  <ColorPicker
                    onChange={handelGroupColorChange}
                    value={formData.colorCode}
                  />

                  <p>Pick a colour for this group</p>
                </div>
              </div>

              <div className="w-full px-16 flex flex-col gap-6">
                <div className="text-sm text-gray-400">
                  Group Name (80 char.max)
                </div>
                <div className="">
                  <Input
                    name="name"
                    size="sm"
                    value={formData.name}
                    onChange={(e: any) =>
                      setValue(
                        'name',
                        capitalizeFirstLetterWithSpace(e.target.value),
                      )
                    }
                    error={modified.name && errors.name}
                  />
                </div>
              </div>

              <div className="w-full px-16 flex flex-col gap-6 mt-[-4px]">
                <div className="text-sm text-gray-400">
                  Description (80 char.max)
                </div>
                <div className="">
                  <Input
                    name="description"
                    size="sm"
                    value={formData.description}
                    onChange={onChange}
                    error={modified.description && errors.description}
                  />
                </div>
              </div>

              <div className="w-full px-16 my-8 flex flex-col gap-6 mt-[-4px]">
                <div className="text-sm text-gray-400">Group Members</div>
                <div className="">
                  <DynamicSearchSelect
                    actionUrl={`/users/company-users`}
                    remapOptions={(data: Api.SupplierType[]) => {
                      return data?.map((item: any) => {
                        console.log(item, 'dataUserList')

                        return {
                          id: item?.users_details?.id,
                          label: `${item?.users_details?.display_name} ${item?.users_details?.lastname}`,
                          value: item?.users_details?.display_name,
                        }
                      })
                    }}
                    onMultiChangeValue={(value) => {
                      value?.map((user) => {
                        return setValue('companyUsers', [
                          ...formData?.companyUsers,
                          user?.id,
                        ])
                      })
                    }}
                    placeholder={'Select users'}
                    isMulti
                  />
                </div>
                <div>
                  {!isTable ? (
                    modified.companyUsers && errors.companyUsers ? (
                      <div className="p-6 rounded-sm bg-red-100 text-sm flex">
                        <div className="mx-6">
                          <MdWarning size={14} className="text-orange-300" />
                        </div>
                        <div className="text-[10px]">
                          This group has no members yet. Add some users to
                          create a new group.
                        </div>
                      </div>
                    ) : (
                      <div className="p-6 rounded-sm bg-blue-100 text-sm flex">
                        <div className="mx-6">
                          <FaInfoCircle size={14} className="text-blue-400" />
                        </div>
                        <div className="text-[10px]">
                          This group has no members yet. Add some users to
                          create a new group.
                        </div>
                      </div>
                    )
                  ) : (
                    <div>
                      <GroupSelectedAppUserTable
                        selectedAppUsers={selectedAppUsers}
                        onDelete={onSelectedUserDelete}
                      />
                    </div>
                  )}
                </div>
                {/* {errors && (
                  <div className="text-sm text-red-300">{errors.appUsers}</div>
                )} */}
              </div>
            </div>

            <div className="border-t-1 border-gray-200 "></div>

            <div className="flex justify-end px-16 py-10">
              <Button
                title="cancel"
                buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
                size="sm"
                type="button"
                onClick={() => clearFields()}
              />
              <div className="ml-16">
                <Button
                  title="Save"
                  size="sm"
                  type="submit"
                  // onClick={handleSaveChanges}
                />
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}
