import {
  ACTIVATE_COMPANY_USER,
  ADD_USER_TO_GROUP,
  CHANGE_INDIVIDUAL_USER_PASSWORD,
  EDIT_COMPANY_USER_IMAGE,
  GET_COMPANY_DISABLED_USER,
  GET_COMPANY_USER_BY_ID,
  GET_COMPANY_USER_GROUP_BY_ID,
  GET_COMPANY_USER_GROUP_LIST,
  GET_USER_TO_ADDED_IN_GROUP,
  REMOVE_USER_FROM_GROUP,
  UPDATE_COMPANY_USER,
  UPDATE_PROFILE_PIC_OF_OTHER_USER,
} from './actionTypes.action'
import {userType} from 'app/userRoles.app'
import {
  USER,
  GET_APPUSER_LIST,
  LOGOUT,
  GET_USER_LIST,
  GET_COMPANY_USER_LIST,
  UPDATE_USER_PROFILE_PIC,
  CREATE_COMPANY_USER,
  DEACTIVATE_USER,
  CREATE_USER_GROUP,
  DELETE_USER_GROUP,
  UPDATE_USER_GROUP_BASIC_DETAILS,
} from '.'
import {api, APIS} from '../../config'
import {getCookie, getParsedUrl} from '../../helpers'
import {errorHandler} from 'utils'
import {toast} from 'app/common'

export function userAuthAction(
  loading: any,
  loginSuccess: any,
  loginFailure: any,
  userId: number,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      if (!getCookie('token')) {
        return loading(false)
      }

      dispatch({type: USER.LOADING})
      res = await api<Api.Base<Api.UserById>>(`${APIS.user + userId}`)
      const {
        success,
        data: {data: appUser, message},
      } = res.data

      const {id, email, role, display_name} = appUser?.user_details
      const loggedInUser = {
        clientId: id,
        email,
        role,
        companyName: display_name,
        appUser,
      }

      if (success) {
        dispatch({type: USER.SUCCESS, payload: loggedInUser})

        loginSuccess(role || userType.FULL_USER)
        successCallback?.()
        loading(false)
        return 1
      } else {
        dispatch({type: USER.ERROR, payload: message})
        loading(false)
        // dispatch(userAuthLogoutAction(errorCallback))
        errorCallback()

        return 0
      }
    } catch (e) {
      dispatch({type: USER.ERROR})
      loginFailure()
      loading(false)
      // dispatch(userAuthLogoutAction(errorCallback))
      errorCallback()
      return 1
    }
  }
}

export function getUserById(userId: number, successCallback?: () => void) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: USER.LOADING})
      res = await api<Api.Base<Api.UserById>>(`${APIS.user + userId}`)
      const {
        success,
        data: {data: appUser, message},
      } = res.data

      const {id, email, role, display_name} = appUser?.user_details
      const loggedInUser = {
        clientId: id,
        email,
        role,
        companyName: display_name,
        appUser,
      }

      if (success) {
        dispatch({type: USER.SUCCESS, payload: loggedInUser})

        successCallback?.()
        return 1
      } else {
        dispatch({type: USER.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: USER.ERROR})
      return 1
    }
  }
}

export function getAppUserList(successCallback?: () => void) {
  return async function (dispatch: any, getState: any) {
    const {
      user: {
        userDetails: {clientId},
      },
    } = getState()
    let res
    try {
      dispatch({type: GET_APPUSER_LIST.LOADING})

      res = await api<any>(`${APIS.userList + clientId}`, 'GET')

      const {errors} = res.data

      if (res.data.length !== 0) {
        dispatch({type: GET_APPUSER_LIST.SUCCESS, payload: res.data})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_APPUSER_LIST.ERROR, payload: errors[0]})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_APPUSER_LIST.ERROR})
      return 0
    }
  }
}

export function userAuthLogoutAction(callback: () => void) {
  return async function (dispatch: any) {
    // let res
    try {
      // res = await api<any>(APIS.logout)

      // const { success } = res.data
      dispatch({type: LOGOUT.LOADING})

      const success = true
      if (success) {
        setTimeout(() => {
          dispatch({type: LOGOUT.SUCCESS})
          callback()
        }, 1000)
        return 1
      }
      return 1
    } catch (e) {
      dispatch({type: LOGOUT.ERROR})
      return 0
    }
  }
}

export function getUserList(successCallback?: () => void) {
  return async function (dispatch: any, getState: any) {
    let res
    try {
      dispatch({type: GET_USER_LIST.LOADING})

      res = await api<any>(`${APIS.userList}`, 'GET')

      // console.log('$#$#$$#$#$#', res)
      const {errors, data, success} = res.data

      if (success) {
        dispatch({type: GET_USER_LIST.SUCCESS, payload: data.data})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_USER_LIST.ERROR, payload: errors[0]})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_USER_LIST.ERROR})
      return 0
    }
  }
}

export function getCompanyUserList(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_COMPANY_USER_LIST.LOADING})

      res = await api<Api.Base<Api.CompanyUsersList>>(
        getParsedUrl(APIS.companyUserList, params),
      )

      const {
        data: {
          data: {rows, total},
        },
        success,
      } = res.data

      if (success) {
        dispatch({
          type: GET_COMPANY_USER_LIST.SUCCESS,
          payload: {rows, total},
        })

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_COMPANY_USER_LIST.ERROR})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_COMPANY_USER_LIST.ERROR})
      return 0
    }
  }
}

export function updateUserProfilePic(
  body: FormData,
  callback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_USER_PROFILE_PIC.LOADING})

      res = await api<any>(APIS.updateUserProfilePic, 'PATCH', body, {
        file: true,
      })

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_USER_PROFILE_PIC.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: UPDATE_USER_PROFILE_PIC.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_USER_PROFILE_PIC.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

// Change profile pic of other users

export function updateProfilePicOfOtherUser(
  userId: number,
  body: FormData,
  callback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PROFILE_PIC_OF_OTHER_USER.LOADING})

      res = await api<any>(`${APIS.user}${userId}/change-pic`, 'PATCH', body, {
        file: true,
      })

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_PROFILE_PIC_OF_OTHER_USER.SUCCESS})
          callback()
          toast.success(message)
        } else
          dispatch({
            type: UPDATE_PROFILE_PIC_OF_OTHER_USER.ERROR,
            payload: message,
          })
      }
      //
    } catch (message) {
      dispatch({
        type: UPDATE_PROFILE_PIC_OF_OTHER_USER.ERROR,
        payload: message,
      })
      errorHandler(res.data, toast)
    }
  }
}

//GET company user details

//MARK: CREATE COMPANY USER

export function createCompanyUserAction(
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_COMPANY_USER.LOADING})

      res = await api<any>(APIS.user, 'POST', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: CREATE_COMPANY_USER.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: CREATE_COMPANY_USER.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: CREATE_COMPANY_USER.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function updateCompanyUserAction(
  userId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_COMPANY_USER.LOADING})

      res = await api<any>(`${APIS.user}${userId}`, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_COMPANY_USER.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: UPDATE_COMPANY_USER.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_COMPANY_USER.ERROR, payload: message})
    }
  }
}

//Edit company user profile

export function editCompanyUserImage(body: FormData, callback?: () => void) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: EDIT_COMPANY_USER_IMAGE.LOADING})

      res = await api<any>(APIS.updateUserProfilePic, 'PATCH', body, {
        file: true,
      })

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: EDIT_COMPANY_USER_IMAGE.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: EDIT_COMPANY_USER_IMAGE.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: EDIT_COMPANY_USER_IMAGE.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

//MARK: DEACTIVATE COMPANY USER

export function deactivateUser(userId: number, successCallback?: () => void) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: DEACTIVATE_USER.LOADING})

      res = await api<any>(`${APIS.user}${userId}/deactivate`, 'PATCH')

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: DEACTIVATE_USER.SUCCESS})
        successCallback()
        toast.success(message ?? 'Successfully deactivated user !!')
      } else dispatch({type: DEACTIVATE_USER.ERROR, payload: message})
      //
    } catch (message) {
      dispatch({type: DEACTIVATE_USER.ERROR, payload: message})
    }
  }
}

//MARK: ACTIVATE COMPANY USER

export function activateCompanyUser(
  userId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: ACTIVATE_COMPANY_USER.LOADING})

      res = await api<any>(`${APIS.user}${userId}/activate`, 'PATCH')

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ACTIVATE_COMPANY_USER.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message ?? 'Successfully activated user !!')
      } else
        dispatch({
          type: ACTIVATE_COMPANY_USER.ERROR,
          payload: data,
        })
    } catch (message) {
      dispatch({
        type: ACTIVATE_COMPANY_USER.ERROR,
        payload: message,
      })
    }
  }
}

//MARK GET COMPANY USER

export function getCompanyUserByIdAction(
  userId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_COMPANY_USER_BY_ID.LOADING})

      res = await api<any>(`${APIS.user}${userId}`, 'GET')

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: GET_COMPANY_USER_BY_ID.SUCCESS,
            payload: data,
          })
          successCallback()
        } else
          dispatch({
            type: GET_COMPANY_USER_BY_ID.ERROR,
            payload: data,
          })
      }
    } catch (message) {
      dispatch({
        type: GET_COMPANY_USER_BY_ID.ERROR,
        payload: message,
      })
    }
  }
}

// get disabled user

export function getCompanyDisabledUser(
  page: number,
  limit: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_COMPANY_DISABLED_USER.LOADING})

      res = await api<any>(
        `${APIS.user}company-users/disabled?page=${page}&limit=${limit}`,
        'GET',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: GET_COMPANY_DISABLED_USER.SUCCESS,
            payload: data,
          })
          successCallback()
        } else
          dispatch({
            type: GET_COMPANY_DISABLED_USER.ERROR,
            payload: data,
          })
      }
    } catch (message) {
      dispatch({
        type: GET_COMPANY_DISABLED_USER.ERROR,
        payload: message,
      })
    }
  }
}

//MARK: COMPANY GROUP

// CREATE USER GROUP
export function createUserGroupAction(body: any, successCallback?: () => void) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_USER_GROUP.LOADING})

      res = await api<any>(`${APIS.user}/group`, 'POST', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: CREATE_USER_GROUP.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: CREATE_USER_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: CREATE_USER_GROUP.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

//MARK: DELETE USER GROUP

export function deleteUserGroupAction(
  userGroupId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: DELETE_USER_GROUP.LOADING})

      res = await api<any>(`${APIS.user}group/${userGroupId}`, 'DELETE')

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: DELETE_USER_GROUP.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: DELETE_USER_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: DELETE_USER_GROUP.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

//MARK: UPDATE USER GROUP BASIC DETAILS

export function updateUserGroupBasicDetails(
  userGroupId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_USER_GROUP_BASIC_DETAILS.LOADING})

      res = await api<any>(`${APIS.user}group/${userGroupId}`, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_USER_GROUP_BASIC_DETAILS.SUCCESS})
          successCallback()
          toast.success(message)
        } else
          dispatch({
            type: UPDATE_USER_GROUP_BASIC_DETAILS.ERROR,
            payload: message,
          })
      }
    } catch (message) {
      dispatch({
        type: UPDATE_USER_GROUP_BASIC_DETAILS.ERROR,
        payload: message,
      })
      errorHandler(res.data, toast)
    }
  }
}

//MARK: REMOVE USER FROM GROUP

export function removeUserFromGroupAction(
  userGroupId: number,
  userId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: REMOVE_USER_FROM_GROUP.LOADING})

      res = await api<any>(
        `${APIS.user}group/${userGroupId}/${userId}`,
        'DELETE',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: REMOVE_USER_FROM_GROUP.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: REMOVE_USER_FROM_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: REMOVE_USER_FROM_GROUP.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function getUserToAddedInGroup(
  userGroupId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_USER_TO_ADDED_IN_GROUP.LOADING})

      res = await api<any>(
        `${APIS.user}group/${userGroupId}/company-users`,
        'GET',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: GET_USER_TO_ADDED_IN_GROUP.SUCCESS,
            payload: data,
          })
          successCallback()
        } else
          dispatch({
            type: GET_USER_TO_ADDED_IN_GROUP.ERROR,
            payload: data,
          })
      }
    } catch (message) {
      dispatch({
        type: GET_USER_TO_ADDED_IN_GROUP.ERROR,
        payload: message,
      })
      // errorHandler(res.data, toast)
    }
  }
}

//MARK: GET USER GROUP BY ID

export function getUserGroupByIdAction(
  userGroupId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_COMPANY_USER_GROUP_BY_ID.LOADING})

      res = await api<any>(`${APIS.user}group/${userGroupId}`, 'GET')

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: GET_COMPANY_USER_GROUP_BY_ID.SUCCESS,
            payload: data,
          })
          successCallback()
        } else
          dispatch({
            type: GET_COMPANY_USER_GROUP_BY_ID.ERROR,
            payload: data,
          })
      }
    } catch (message) {
      dispatch({
        type: GET_COMPANY_USER_GROUP_BY_ID.ERROR,
        payload: message,
      })
      // errorHandler(res.data, toast)
    }
  }
}

//MARK: ADD USER TO THE GROUP

export function addUserToGroupAction(
  userGroupId: number,
  userId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: ADD_USER_TO_GROUP.LOADING})

      res = await api<any>(`${APIS.user}group/${userGroupId}/${userId}`, 'PUT')

      if (res.data !== undefined) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({type: ADD_USER_TO_GROUP.SUCCESS, payload: data})
          successCallback()
          toast.success(message)
        } else dispatch({type: ADD_USER_TO_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: ADD_USER_TO_GROUP.ERROR, payload: message})
      // errorHandler(res.data, toast)
    }
  }
}

//MARK: GET COMPANY USER GROUP LIST

export function getCompanyUserGroupList(
  params: {
    page?: number
    limit?: number
    search?: string
    projectIds?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_COMPANY_USER_GROUP_LIST})

      res = await api<Api.Base<Api.CompanyUserGroupList>>(
        getParsedUrl(`${APIS.user}group`, params),
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {
            message,
            data: {rows, total},
          },
        } = res.data
        if (success) {
          dispatch({
            type: GET_COMPANY_USER_GROUP_LIST.SUCCESS,
            payload: {total, rows},
          })
          successCallback?.()
          // toast.success(message)
        } else
          dispatch({
            type: GET_COMPANY_USER_GROUP_LIST.ERROR,
            payload: message,
          })
      }
    } catch ({message}: any) {
      dispatch({type: GET_COMPANY_USER_GROUP_LIST.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

// * Mark Change password of a user
export function changeIndividualUserPasswordAction(
  userId: number,
  body: {newPassword: string},
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CHANGE_INDIVIDUAL_USER_PASSWORD.LOADING})

      res = await api<any>(`/users/${userId}/change-password`, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: CHANGE_INDIVIDUAL_USER_PASSWORD.SUCCESS,
            payload: data,
          })
          successCallback()
          toast.success(message)
        } else
          dispatch({
            type: CHANGE_INDIVIDUAL_USER_PASSWORD.ERROR,
            payload: message,
          })
      }
    } catch (message) {
      dispatch({type: CHANGE_INDIVIDUAL_USER_PASSWORD.ERROR, payload: message})
      // errorHandler(res.data, toast)
    }
  }
}
