/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {
  HiOutlinePlusCircle,
  HiPlusSm,
  HiMinusSm,
  HiDocumentRemove,
} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Geocode from 'react-geocode'

import {
  Button,
  Input,
  Modal,
  Loading,
  Select,
  Checkbox,
  IconButton,
  Collapse,
  SelectField,
  ConfirmationModal,
} from 'app/common'
import {DescriptionBox} from 'app/components'

import {
  addressRemapper,
  addressStyles,
  finalAddressRemapper,
  getAccumulatedContacts,
  getContactDynamicFields,
  getCountryId,
  getRemappedContacts,
} from 'helpers'
import {
  addCustomer,
  deleteOtherContact,
  getCustomerById,
  updateCustomer,
} from 'redux-src'
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterWithSpace,
  validationHandler,
} from 'utils'
import {REACT_APP_GOOGLE_KEY} from 'config'

export const CustomerModal = ({
  newCustomerModal,
  setNewCustomerModal,
  customerAddCallback,
  activeCustomer,
  isEdit,
}: {
  newCustomerModal: boolean
  setNewCustomerModal: React.Dispatch<React.SetStateAction<boolean>>
  customerAddCallback?: () => void
  activeCustomer?: Api.CustomerById
  isEdit?: boolean
}) => {
  //! REDUX HANDLING
  const {toast} = useAuth()
  const dispatch = useDispatch()
  const {add_loading, update_customer_loading}: RT.CustomerReduxType =
    useSelector((state: any) => state.customer)
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {paymentOptions, contactTypeOptions, disableInvoiceRemindersOptions} =
    selectOptions

  //? CUSTOMER/COMPANY NAME
  const [customerName, customerNameHandler] = useFormInput(
    {
      name: '',
    },
    (data) => {
      const errors: any = {}

      if (data.name.length === 0) {
        errors.name = 'Please enter the company or customer name!'
      }

      return errors
    },
  )

  //? PHYSICAL ADDRESS
  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1,
  })

  //? POSTAL ADDRESS
  const [postalAddressData, postalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 2,
  })
  const [postalAddressActive, setPostalAddressActive] = useState(false)

  //? CUSTOMER SETTING
  const [customerSettingData, customerSettingHandler] = useFormInput(
    {
      attachInvoicePdf: false,
      disableInvoiceReminder: 0,
      paymentTermsId: 0,
      chargeOutRate: 0,
      materialDiscount: 0,
      labourDiscount: 0,
      customTaxRate: 0,
    },
    (data) => {
      // const errors: any = {}
      const {errors}: any = customerSettingHandler

      if (data.disableInvoiceReminder === 0 || data.paymentTermsId === 0) {
        errors.paymentTermsId = 'Please provide all customer settings!'
      }

      return errors
    },
  )

  //? DEFAULT MAIN CONTACT
  const [mainContactData, setMainContactData] = useState({
    contactTypeId: 2,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    attn: '',
  })
  const [mainContactError, setMainContactError] = useState('')

  //? BILLING CONTACT
  const [billingContactData, setBillingContactData] = useState({
    contactTypeId: 3,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
    firstName: '',
    lastName: '',
    title: '',
    attn: '',
  })
  const [billingContactActive, setBillingContactActive] = useState(false)
  const [billingContactError, setBillingContactError] = useState('')

  // //? OTHER CONTACT
  const [otherContactData, setOtherContactData] = useState([])

  //! SETTTING CUSTOMER DETAILS
  const setCustomerDetails = useCallback(() => {
    if (activeCustomer) {
      // dispatch({ type: GET_CUSTOMER_BY_ID.CLEAR })

      customerNameHandler.setValue(
        'name',
        activeCustomer?.customer_details?.name ?? '',
      )

      const getCustomerAddresses = (
        addressType: 'physical' | 'postal',
        setValue: any,
      ) => {
        const addressKey =
          addressType === 'physical'
            ? 'physical_address_details'
            : 'postal_address_details'
        setValue('address', activeCustomer[addressKey]?.address ?? '')
        setValue('state', activeCustomer[addressKey]?.state ?? '')
        setValue('suburb', activeCustomer[addressKey]?.suburb ?? '')
        setValue('city', activeCustomer[addressKey]?.city ?? '')
        setValue('place', activeCustomer[addressKey]?.place ?? '')
        setValue(
          'countryId',
          getCountryId(activeCustomer[addressKey]?.country) ?? 0,
        )
        setValue('zip', activeCustomer[addressKey]?.zip_code ?? '')
        setValue(
          'lat',
          activeCustomer[addressKey]?.coordinates
            ? activeCustomer[addressKey]?.coordinates[0]
            : 0,
        )
        setValue(
          'lng',
          activeCustomer[addressKey]?.coordinates
            ? activeCustomer[addressKey]?.coordinates[1]
            : 0,
        )
      }

      getCustomerAddresses('physical', physicalAddressHandler.setValue)
      getCustomerAddresses('postal', postalAddressHandler.setValue)
      setPostalAddressActive(
        activeCustomer?.postal_address_details?.id ===
          activeCustomer?.physical_address_details?.id,
      )

      const getContacts = (contactTypeId: 2 | 3) => {
        const contactType =
          contactTypeId === 2
            ? 'default_contact_details'
            : 'billing_contact_details'

        return {
          contactTypeId,
          dynamicFields: getContactDynamicFields({
            emails: activeCustomer[contactType]?.emails?.slice(1) ?? [],
            phones: activeCustomer[contactType]?.phones ?? [],
            otherPhones: activeCustomer[contactType]?.other_phones ?? [],
            fax: activeCustomer[contactType]?.fax ?? [],
            websites: activeCustomer[contactType]?.websites ?? [],
          }),
          firstName: activeCustomer[contactType]?.firstname ?? '',
          lastName: activeCustomer[contactType]?.lastname ?? '',
          title: activeCustomer[contactType]?.position ?? '',
          email: activeCustomer[contactType]?.emails?.at(0),
          attn: '',
        }
      }

      setMainContactData(getContacts(2))
      setBillingContactData(getContacts(3))
      setBillingContactActive(
        activeCustomer?.default_contact_details?.id ===
          activeCustomer?.billing_contact_details?.id,
      )

      if (!!activeCustomer?.other_contacts) {
        const getOtherContacts = (otherContact: Api.ContactType) => {
          return {
            contactTypeId: 4,
            id: otherContact?.id,
            dynamicFields: getContactDynamicFields({
              emails: otherContact?.emails ?? [],
              phones: otherContact?.phones ?? [],
              otherPhones: otherContact?.other_phones ?? [],
              fax: otherContact?.fax ?? [],
              websites: otherContact?.websites ?? [],
            }),
            firstName: otherContact?.firstname ?? '',
            lastName: otherContact?.lastname ?? '',
            title: otherContact?.position ?? '',
            attn: '',
          }
        }

        const remappedOtherContacts = activeCustomer?.other_contacts.map(
          (otherContact) => {
            return getOtherContacts(otherContact)
          },
        )

        setOtherContactData(remappedOtherContacts)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCustomer])

  useEffect(() => {
    setCustomerDetails()
  }, [dispatch, setCustomerDetails])

  const onPhysicalAddressSubmit = () => {
    const physical = validationHandler(
      {
        address: physicalAddressData.address,
        city: physicalAddressData.city,
        country: physicalAddressData.countryId,
      },
      toast,
      'physical',
    )
    const postal = postalAddressActive
      ? true
      : validationHandler(
          {
            address: postalAddressData.address,
            city: postalAddressData.city,
            country: postalAddressData.countryId,
          },
          toast,
          'postal',
        )

    // return postalAddressActive ? physical : physical && postal
    return physical && postal
  }

  const onMainContactSubmit = () => {
    const main = validationHandler(
      {
        'first name': mainContactData.firstName,
        'last name': mainContactData.lastName,
        title: mainContactData.title,
        email: mainContactData.email,
      },
      toast,
      'main contact',
    )
    const billing = billingContactActive
      ? true
      : validationHandler(
          {
            'first name': billingContactData.firstName,
            'last name': billingContactData.lastName,
            title: billingContactData.title,
          },
          toast,
          'billing contact',
        )

    // return billingContactActive ? main : (main && billing)
    return main && billing
  }

  //? OTHER CONTACT CHANGE HANDLING
  const onAddOtherContact = () => {
    const _otherContactData = [...otherContactData]
    _otherContactData.push({
      contactTypeId: 4,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
      attn: '',
    })
    setOtherContactData(_otherContactData)
  }

  const onRemoveOtherContact = (i: any, id?: number, cb?: () => void) => {
    if (id) {
      dispatch(
        deleteOtherContact(
          activeCustomer?.customer_details?.id,
          activeCustomer?.info_id,
          id,
          () => {
            dispatch(getCustomerById(activeCustomer?.customer_details?.id, cb))
          },
          toast,
        ),
      )
      return
    }
    const _otherContactData = [...otherContactData]
    _otherContactData.splice(i, 1)
    setOtherContactData(_otherContactData)
  }

  const onOtherContactInputChange = (e: any, i: any) => {
    const _otherContactData = [...otherContactData]
    _otherContactData.map(() => {
      _otherContactData[i] = {
        ..._otherContactData[i],
        [e.target.name]: e.target.value ?? undefined,
      }
      return null
    })
    setOtherContactData(_otherContactData)
  }

  const onOtherContactDynamicFieldIdChange = (
    e: any,
    itemId: any,
    dynamicItemId: any,
  ) => {
    const _otherContactData = [...otherContactData]
    _otherContactData.map(() => {
      _otherContactData[itemId].dynamicFields[dynamicItemId].contactFieldId =
        parseInt(e.target.value) ?? undefined
      return null
    })

    setOtherContactData(_otherContactData)
  }

  const onOtherContactDynamicFieldValueChange = (
    e: any,
    itemId: any,
    dynamicItemId: any,
  ) => {
    const _otherContactData = [...otherContactData]
    _otherContactData.map(() => {
      _otherContactData[itemId].dynamicFields[dynamicItemId].value =
        e.target.value ?? undefined
      return null
    })

    setOtherContactData(_otherContactData)
  }

  const onOtherContactDynamicFieldAddClick = (itemId: any) => {
    const _otherContactData = [...otherContactData]

    _otherContactData[itemId].dynamicFields.push({
      contactFieldId: 1,
      value: undefined,
    })
    setOtherContactData(_otherContactData)
  }

  const onOtherContactDynamicFieldRemoveClick = (
    itemId: any,
    dynamicItemId: any,
  ) => {
    const _otherContactData = [...otherContactData]
    _otherContactData[itemId].dynamicFields.splice(dynamicItemId, 1)
    setOtherContactData(_otherContactData)
  }

  //? NEW CUSTOMER SUBMISSION HANDLING
  const addCustomerSuccessCallback = useCallback(() => {
    setNewCustomerModal(false)
    customerNameHandler.clear()
    physicalAddressHandler?.clear()
    postalAddressHandler.clear()
    customerSettingHandler.clear()

    setMainContactData({
      contactTypeId: 2,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      attn: '',
    })
    setBillingContactData({
      contactTypeId: 3,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
      attn: '',
    })

    customerAddCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAddCallback, setNewCustomerModal])

  const onAddNewCustomer = (e: any) => {
    e.preventDefault()
    const {defaultContact, billingContact} = getAccumulatedContacts(
      mainContactData,
      billingContactData,
    )

    const otherContacts = otherContactData.map((contact) =>
      getRemappedContacts(contact, true),
    )

    const physicalAddress = finalAddressRemapper(physicalAddressData)

    const updatedPostalAddress = postalAddressActive
      ? {...physicalAddress, id: activeCustomer?.physical_address_details?.id}
      : {
          ...finalAddressRemapper(postalAddressData),
          id: activeCustomer?.postal_address_details?.id,
        }

    const updatedBillingContact = billingContactActive
      ? {...defaultContact, id: activeCustomer?.default_contact_details?.id}
      : {...billingContact, id: activeCustomer?.billing_contact_details?.id}

    const _requestData: any = {
      name: customerName.name,
      info: {
        physicalAddress,
        postalAddress: postalAddressActive
          ? undefined
          : finalAddressRemapper(postalAddressData),
        defaultContact,
        billingContact: billingContactActive ? undefined : billingContact,
        otherContacts,
      },
    }

    const updateData: any = {
      // id: activeCustomer?.customer_details?.id,
      name: customerName.name,
      info: {
        // id: activeCustomer?.info_id,
        physicalAddress: {
          ...physicalAddress,
          id: activeCustomer?.physical_address_details?.id,
        },
        postalAddress: updatedPostalAddress,
        defaultContact: {
          ...defaultContact,
          id: activeCustomer?.default_contact_details?.id,
        },
        billingContact: updatedBillingContact,
        otherContacts,
      },
    }

    if (
      // false &&
      !!customerName.name &&
      onPhysicalAddressSubmit() &&
      onMainContactSubmit()
    ) {
      activeCustomer?.info_id
        ? dispatch(
            updateCustomer(
              activeCustomer?.customer_details?.id,
              updateData,
              addCustomerSuccessCallback,
              toast,
            ),
          )
        : dispatch(addCustomer(_requestData, addCustomerSuccessCallback, toast))
    } else {
      toast.error(
        !customerName.name
          ? 'Please fill the customer or company name!'
          : 'Please fill all the required fields!',
      )
    }
  }

  // MARK: Clear Fields
  const clearFields = () => {
    if (isEdit) {
      return
    }

    customerNameHandler.clear()
    physicalAddressHandler?.clear()
    postalAddressHandler.clear()
    customerSettingHandler.clear()

    setMainContactData({
      contactTypeId: 2,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      attn: '',
    })
    setBillingContactData({
      contactTypeId: 3,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
      attn: '',
    })
  }

  useEffect(() => {
    if (!isEdit) {
      clearFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  return (
    <>
      <Modal
        isActive={newCustomerModal}
        size="xl"
        toggleModal={() => setNewCustomerModal(false)}
        onClose={clearFields}
        title={activeCustomer?.info_id ? 'Edit Customer' : 'Add Customer'}
      >
        {add_loading ? (
          <Loading />
        ) : (
          <>
            <div className="h-modal  overflow-y-scroll ">
              <div className="flex px-14">
                {/* customer detail */}
                <div className="flex-1 w-full p-20">
                  <div className="font-bold pb-10 border-b-1 border-gray-200 mb-20 text-gray-800 text-md pl-6">
                    Customer Details
                  </div>
                  {/* customer / company name */}
                  <div className="mb-32">
                    <DescriptionBox
                      title={
                        <div className="text-sm text-gray-700 ml-20">
                          <h4 className="flex font-bold text-md justify-between">
                            Customer/Company Name *
                          </h4>
                        </div>
                      }
                    >
                      {customerNameHandler.errors.name && (
                        <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                          {customerNameHandler.errors.name}
                        </p>
                      )}
                      <Input
                        name="name"
                        size="sm"
                        value={customerName.name}
                        error={customerName.name.length === 0}
                        placeholder="Customer/Company Name"
                        onChange={(e: any) =>
                          customerNameHandler.setValue(
                            e.target.name,
                            capitalizeFirstLetterWithSpace(e.target.value),
                          )
                        }
                        className="mx-20"
                      />
                    </DescriptionBox>
                  </div>
                  {/* //physical firstname */}
                  <div className="mb-32">
                    <DescriptionBox
                      title={
                        <div className="flex items-center align-center justify-between text-sm text-gray-700 ml-20">
                          <h4 className="flex font-bold text-md justify-between">
                            Physical Address *
                          </h4>

                          <div
                            className={`border-1 px-8 py-3 rounded-[2px] text-white font-normal cursor-pointer ${
                              !physicalAddressData?.address
                                ? 'bg-red-200 border-red-200'
                                : 'bg-red-300 border-red-300'
                            }`}
                            onClick={() => {
                              if (!!physicalAddressData?.address) {
                                physicalAddressHandler.clear()
                              }
                            }}
                          >
                            Clear
                          </div>
                        </div>
                      }
                    >
                      {physicalAddressHandler?.errors.address && (
                        <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                          {physicalAddressHandler?.errors.address}
                        </p>
                      )}
                      <AddressTable
                        addressProps={{
                          addressData: physicalAddressData,
                          addressHandler: physicalAddressHandler,
                        }}
                      />
                    </DescriptionBox>
                  </div>
                  {/* //postal address */}
                  <div className="mb-32">
                    <Collapse
                      trigger={!postalAddressActive}
                      header={
                        <table className="w-full ml-20">
                          <tbody>
                            <tr>
                              <td width="40%">
                                <div className="text-sm text-gray-700">
                                  <h4 className="flex font-bold text-md justify-between">
                                    Postal Address *
                                  </h4>
                                </div>
                              </td>
                              <td width="100%">
                                <div className="flex items-center justify-between">
                                  <Checkbox
                                    dataId="postal-address"
                                    label="Same as Physical Address"
                                    onChange={() => {
                                      setPostalAddressActive((prev) => !prev)
                                    }}
                                    className="w-full"
                                    isChecked={postalAddressActive}
                                    value={postalAddressActive}
                                  />

                                  {!postalAddressActive && (
                                    <div
                                      className={`border-1 px-8 py-3 rounded-[2px] text-white font-normal cursor-pointer ${
                                        !postalAddressData?.address
                                          ? 'bg-red-200 border-red-200'
                                          : 'bg-red-300 border-red-300'
                                      }`}
                                      style={{
                                        marginRight: 16,
                                      }}
                                      onClick={() => {
                                        if (!!postalAddressData?.address) {
                                          postalAddressHandler.clear()
                                        }
                                      }}
                                    >
                                      Clear
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      }
                    >
                      {postalAddressHandler.errors.address && (
                        <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                          {postalAddressHandler.errors.address}
                        </p>
                      )}

                      {
                        <AddressTable
                          addressProps={{
                            addressData: postalAddressData,
                            addressHandler: postalAddressHandler,
                          }}
                        />
                      }
                    </Collapse>
                  </div>
                  {/* //customer setting */}
                  <div className="mb-32">
                    <DescriptionBox
                      title={
                        <div className="text-sm text-gray-700 ml-20">
                          <h4 className="font-bold text-md">
                            Customer Setting
                          </h4>
                        </div>
                      }
                    >
                      {customerSettingHandler.errors.paymentTermsId && (
                        <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                          {customerSettingHandler.errors.paymentTermsId}
                        </p>
                      )}
                      <table className="w-full ml-20">
                        <tbody>
                          <tr>
                            <td>
                              <div className="text-sm text-gray-400">
                                Payment Terms:
                              </div>
                            </td>
                            <td>
                              <Select
                                name="paymentTermsId"
                                options={paymentOptions}
                                size="sm"
                                containerClass="pb-0"
                                className="text-sm"
                                // defaultValue={customerSettingData.paymentTermsId}
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    e.target.value,
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="text-sm text-gray-400">
                                Charge Out Rate:
                              </div>
                            </td>
                            <td>
                              <Input
                                type="number"
                                min="0"
                                name="chargeOutRate"
                                size="sm"
                                placeholder="0"
                                containerClass="pb-0"
                                className="text-sm"
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    parseFloat(e.target.value),
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="text-sm text-gray-400">
                                Material Discount:
                              </div>
                            </td>
                            <td>
                              <Input
                                type="number"
                                min="0"
                                max="100"
                                name="materialDiscount"
                                size="sm"
                                placeholder="0"
                                containerClass="pb-0"
                                className="text-sm"
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    parseFloat(e.target.value),
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="text-sm text-gray-400">
                                Labour Discount:
                              </div>
                            </td>
                            <td>
                              <Input
                                type="number"
                                min="0"
                                max="100"
                                name="labourDiscount"
                                size="sm"
                                placeholder="0"
                                containerClass="pb-0"
                                className="text-sm"
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    parseFloat(e.target.value),
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td width="40%">
                              <div className="text-sm text-gray-400">
                                Custom Tax Rate:
                              </div>
                            </td>
                            <td width="50%">
                              <Input
                                type="number"
                                min="0"
                                max="100"
                                name="customTaxRate"
                                size="sm"
                                containerClass="pb-0"
                                placeholder="15"
                                className="text-sm"
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    parseFloat(e.target.value),
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td width="40%">
                              <div className="text-sm text-gray-400">
                                Disable Invoice Reminders:
                              </div>
                            </td>
                            <td width="50%">
                              <Select
                                name="disableInvoiceReminder"
                                options={disableInvoiceRemindersOptions}
                                size="sm"
                                containerClass="pb-0"
                                className="text-sm"
                                // defaultValue={
                                //   customerSettingData.disableInvoiceReminder
                                // }
                                onChange={(e: any) =>
                                  customerSettingHandler.setValue(
                                    e.target.name,
                                    e.target.value,
                                  )
                                }
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <Checkbox
                                dataId="attachInvoicePdf"
                                label="Attach Invoice PDF"
                                onChange={() =>
                                  customerSettingHandler.setValue(
                                    'attachInvoicePdf',
                                    !customerSettingData.attachInvoicePdf,
                                  )
                                }
                                isChecked={customerSettingData.attachInvoicePdf}
                                value={customerSettingData.attachInvoicePdf}
                              />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </DescriptionBox>
                  </div>
                </div>
                {/* contacts */}
                <div className="flex-1 w-full p-20">
                  <div className="font-bold pb-10 border-b-1 border-gray-200 mb-20">
                    Contacts
                  </div>
                  <div className="col-md-6">
                    {/* //main contact */}
                    <div className="mb-32">
                      <DescriptionBox
                        title={
                          <div className="text-sm text-gray-700 ml-20">
                            <h4 className="font-bold text-md">
                              Default Main Contact *
                            </h4>
                          </div>
                        }
                      >
                        {mainContactError && (
                          <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                            {mainContactError}
                          </p>
                        )}
                        <ContactTable
                          isUpdate={activeCustomer?.info_id}
                          contactProps={{
                            contactData: mainContactData,
                            setContactData: setMainContactData,
                          }}
                        />
                      </DescriptionBox>
                    </div>

                    {/* //billing contact */}
                    <div className="mb-32">
                      <Collapse
                        trigger={!billingContactActive}
                        header={
                          <table className="w-full ml-20">
                            <tbody>
                              <tr>
                                <td width="40%">
                                  <div className="text-sm text-gray-700">
                                    <h4 className="font-bold text-md">
                                      Billing Contact *
                                    </h4>
                                  </div>
                                </td>
                                <td width="100%">
                                  <Checkbox
                                    dataId="billing-contact"
                                    label="Same as Default Main Contact"
                                    onChange={() => {
                                      setBillingContactActive(
                                        !billingContactActive,
                                      )
                                    }}
                                    isChecked={billingContactActive}
                                    value={billingContactActive}
                                  />
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        }
                      >
                        {billingContactError && (
                          <p className="ml-20 mr-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                            {billingContactError}
                          </p>
                        )}
                        {!billingContactActive && (
                          <ContactTable
                            contactProps={{
                              contactData: billingContactData,
                              setContactData: setBillingContactData,
                            }}
                            billing
                          />
                        )}
                      </Collapse>
                    </div>

                    {/* //other contact */}
                    {otherContactData.map((field, idx) => {
                      return (
                        <div className="mb-32" key={idx}>
                          <div key={`${field}-${idx}`}>
                            <DescriptionBox
                              title={
                                <table className="w-full ml-20">
                                  <tbody>
                                    <tr>
                                      <td width="70%">
                                        <div className="text-sm text-gray-700">
                                          <h4 className="font-bold text-md">
                                            Other Contact
                                          </h4>
                                        </div>
                                      </td>
                                      <td width="30%">
                                        <ConfirmationModal
                                          loading={update_customer_loading}
                                          danger
                                          label={
                                            'Are you sure you want to delete this contact?'
                                          }
                                          displayElement={
                                            <button className="flex justify-between font-bold text-red-400 cursor-pointer text-sm">
                                              Remove{' '}
                                              {update_customer_loading ? (
                                                <Loading small />
                                              ) : (
                                                <HiDocumentRemove
                                                  size={20}
                                                  style={{padding: 4}}
                                                />
                                              )}
                                            </button>
                                          }
                                          onConfirmClick={(closeModal) => {
                                            onRemoveOtherContact(
                                              idx,
                                              field.id,
                                              closeModal,
                                            )
                                          }}
                                          confirmLabel="Delete Contact"
                                        >
                                          <div className="p-10 font-bold bg-red-200 text-red-400 rounded-sm">
                                            This will delete the current
                                            contact. This cannot be undone.
                                          </div>
                                        </ConfirmationModal>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                            >
                              <table className="w-full ml-20">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="text-sm text-gray-400">
                                        First Name:
                                      </div>
                                    </td>
                                    <td>
                                      <Input
                                        name="firstName"
                                        size="sm"
                                        containerClass="pb-0"
                                        className="text-sm"
                                        value={field.firstName}
                                        onChange={(e: any) =>
                                          onOtherContactInputChange(e, idx)
                                        }
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="text-sm text-gray-400">
                                        Last Name:
                                      </div>
                                    </td>
                                    <td>
                                      <Input
                                        name="lastName"
                                        size="sm"
                                        containerClass="pb-0"
                                        className="text-sm"
                                        value={field.lastName}
                                        onChange={(e: any) =>
                                          onOtherContactInputChange(e, idx)
                                        }
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td width="40%">
                                      <div className="text-sm text-gray-400">
                                        Title/Position
                                      </div>
                                    </td>
                                    <td width="50%">
                                      <Input
                                        name="title"
                                        size="sm"
                                        containerClass="pb-0"
                                        className="text-sm"
                                        value={field.title}
                                        onChange={(e: any) =>
                                          onOtherContactInputChange(e, idx)
                                        }
                                      />
                                    </td>
                                    <td></td>
                                  </tr>

                                  {field.dynamicFields.map(
                                    (_: any, id: any) => {
                                      return (
                                        <tr key={id}>
                                          <td>
                                            <div className="text-sm text-gray-400">
                                              <Select
                                                name="contactFieldId"
                                                options={contactTypeOptions}
                                                size="sm"
                                                containerClass="pb-0"
                                                className="text-sm"
                                                value={
                                                  field.dynamicFields[id]
                                                    .contactFieldId
                                                }
                                                onChange={(e: any) =>
                                                  onOtherContactDynamicFieldIdChange(
                                                    e,
                                                    idx,
                                                    id,
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <Input
                                              name="value"
                                              size="sm"
                                              containerClass="pb-0"
                                              className="text-sm"
                                              value={
                                                field.dynamicFields[id].value
                                              }
                                              onChange={(e: any) =>
                                                onOtherContactDynamicFieldValueChange(
                                                  e,
                                                  idx,
                                                  id,
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <div className="flex">
                                              {field.dynamicFields[id].value ===
                                              undefined ? (
                                                <span className="cursor-not-allowed text-gray-400">
                                                  <HiPlusSm />
                                                </span>
                                              ) : field.dynamicFields.length ===
                                                id + 1 ? (
                                                <span className="cursor-pointer">
                                                  <HiPlusSm
                                                    onClick={() =>
                                                      onOtherContactDynamicFieldAddClick(
                                                        idx,
                                                      )
                                                    }
                                                  />
                                                </span>
                                              ) : (
                                                <span className="cursor-pointer">
                                                  <HiMinusSm
                                                    onClick={() =>
                                                      onOtherContactDynamicFieldRemoveClick(
                                                        idx,
                                                        id,
                                                      )
                                                    }
                                                  />
                                                </span>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    },
                                  )}
                                </tbody>
                              </table>
                            </DescriptionBox>
                          </div>
                        </div>
                      )
                    })}
                    <div className="ml-20 mr-30 mt-30">
                      <IconButton
                        size="35"
                        icon={<HiOutlinePlusCircle />}
                        label="Add Contact"
                        type="button"
                        onClick={() => onAddOtherContact()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 border-t-1 border-gray-200"></div>
            <div className="flex justify-end px-16 pt-4 pb-12">
              <Button
                title="cancel"
                buttonColor="bg-red-400 text-white hover:bg-red-500 "
                size="sm"
                type="button"
                // style="ghost"
                onClick={() => {
                  setNewCustomerModal(false)
                  clearFields()
                }}
                // loading={false}
              />
              <div className="ml-16">
                <Button
                  loading={update_customer_loading || add_loading}
                  title={
                    activeCustomer?.info_id
                      ? 'Update Customer'
                      : 'Save Customer'
                  }
                  size="sm"
                  type="button"
                  onClick={(e: any) => onAddNewCustomer(e)}
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

// MARK: - Address
export const AddressTable = ({
  addressProps: {addressData, addressHandler},
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions} = selectOptions
  const [addressValue, setAddressValue] = useState(null)

  const handleAddressDropChange = (item: any) => {
    setAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(
      async (response) => {
        const responseFromHelper = await addressRemapper(response)
        //

        const _aaddressData = {
          ...addressData,
          ...responseFromHelper,
        }

        const {
          address,
          city,
          countryId,
          lat,
          lng,
          placeId,
          state,
          zip,
          suburb,
          country,
        } = _aaddressData
        const {setValue} = addressHandler

        setValue('address', address)
        setValue('city', city)
        setValue('countryId', countryId)
        setValue('lat', lat)
        setValue('lng', lng)
        setValue('placeId', placeId)
        setValue('state', state)
        setValue('zip', zip)
        setValue('suburb', suburb)
        setValue('country', country)
      },
      (error) => {},
    )
  }

  return (
    <table className="w-full ml-20">
      <tbody>
        <tr>
          <td>
            <div className="text-sm text-gray-400">Address *:</div>
          </td>
          <td>
            <Input
              name="address"
              size="sm"
              containerClass="pb-0"
              value={addressData?.address}
              className="text-sm hidden"
              onChange={addressHandler?.onChange}
              required
              error={
                addressHandler?.modified?.address &&
                addressData?.address?.length === 0
              }
            />
            <GooglePlacesAutocomplete
              apiKey={REACT_APP_GOOGLE_KEY}
              selectProps={{
                value:
                  {
                    label: addressData?.address,
                    value: addressData?.address,
                  } ?? addressValue,
                onChange: handleAddressDropChange,
                styles: addressStyles(addressData?.address.length === 0),
              }}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <div className="text-sm text-gray-400">Suburb:</div>
          </td>
          <td>
            <Input
              name="suburb"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              value={addressData?.suburb}
              onChange={addressHandler?.onChange}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <div className="text-sm text-gray-400">City *:</div>
          </td>
          <td>
            <Input
              name="city"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              value={addressData?.city}
              error={
                // addressHandler?.modified.city &&
                addressData?.city.length === 0
              }
              onChange={addressHandler?.onChange}
            />
          </td>
          <td></td>
        </tr>

        <tr>
          <td>
            <div className="text-sm text-gray-400">State / Region:</div>
          </td>
          <td>
            <Input
              name="state"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              value={addressData?.state}
              onChange={addressHandler?.onChange}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <div className="text-sm text-gray-400">Zip / Post Code:</div>
          </td>
          <td>
            <Input
              name="zip"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              value={addressData?.zip}
              onChange={addressHandler?.onChange}
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td width="40%">
            <div className="text-sm text-gray-400">Country *:</div>
          </td>
          <td width="50%">
            <SelectField
              options={countryOptions}
              placeholder="Select country"
              getOptionLabel={'key'}
              getOptionValue={'value'}
              value={countryOptions.filter((country: any) => {
                return country.value === addressData.countryId
              })}
              onChangeValue={(e) => {
                addressHandler?.setValue('countryId', e.value)
              }}
              error={addressData?.countryId === 0}
            />
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )
}

const ContactTable = ({
  contactProps: {contactData, setContactData},
  billing,
  isUpdate,
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {contactTypeOptions} = selectOptions

  //? MAIN CONTACT CHANGE HANDLING
  const onContactInputChange = (e: any) =>
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value ?? undefined,
    })

  const onContactDynamicFieldChange = (
    e: any,
    key: 'value' | 'contactFieldId',
    i: any,
  ) => {
    const _contactData = {...contactData}

    const newDynamicFields = _contactData.dynamicFields.map(
      (dynamicField: any) => {
        if (dynamicField.dynamicFieldId === i) {
          return {
            ...dynamicField,
            [key]: key === 'contactFieldId' ? +e.target.value : e.target.value,
          }
        }

        return dynamicField
      },
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}

    setContactData(newContactData)
  }

  const onContactDynamicFieldAddClick = (e: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const {dynamicFields} = _contactData
    const dynamicField =
      _contactData.dynamicFields[_contactData.dynamicFields.length - 1]
    const newDynamicFields = [
      ...dynamicFields,
      {
        dynamicFieldId: dynamicField.dynamicFieldId + 1,
        contactFieldId: 1,
        value: undefined,
      },
    ]

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  const onContactDynamicFieldRemoveClick = (e: any, i: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const newDynamicFields = _contactData.dynamicFields.filter(
      (dynamicField: any) => dynamicField.dynamicFieldId !== i,
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  return (
    <table className="w-full ml-20">
      <tbody>
        {billing && (
          <tr>
            <td>
              <div className="text-sm text-gray-400">ATTN:</div>
            </td>
            <td>
              <Input
                name="attn"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={contactData?.attn}
                onChange={onContactInputChange}
              />
            </td>
            <td></td>
          </tr>
        )}
        <tr>
          <td>
            <div className="text-sm text-gray-400">First Name *:</div>
          </td>
          <td>
            <Input
              name="firstName"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              value={contactData?.firstName}
              error={contactData?.firstName?.length === 0}
              onChange={(e: any) =>
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <div className="text-sm text-gray-400">Last Name *:</div>
          </td>
          <td>
            <Input
              name="lastName"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              error={contactData?.lastName?.length === 0}
              value={contactData?.lastName}
              onChange={(e: any) =>
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <td width="40%">
            <div className="text-sm text-gray-400">Title/Position *:</div>
          </td>
          <td width="50%">
            <Input
              name="title"
              size="sm"
              containerClass="pb-0"
              className="text-sm"
              error={contactData?.title?.length === 0}
              value={contactData?.title}
              onChange={onContactInputChange}
            />
          </td>
          <td></td>
        </tr>
        {contactData.contactTypeId === 2 && (
          <tr>
            <td>
              <div className="text-sm text-gray-400">Email *:</div>
            </td>
            <td>
              <Input
                name="email"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                error={contactData?.email?.length === 0}
                value={contactData?.email}
                onChange={onContactInputChange}
              />
            </td>
            <td></td>
          </tr>
        )}
        {contactData?.dynamicFields?.map((dynamicField: any, id: number) => {
          return (
            <tr key={dynamicField.dynamicFieldId}>
              <td>
                <div className="text-sm text-gray-400">
                  <Select
                    name="contactFieldId"
                    options={contactTypeOptions}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    value={dynamicField.contactFieldId}
                    onChange={(e: any) =>
                      onContactDynamicFieldChange(
                        e,
                        'contactFieldId',
                        dynamicField.dynamicFieldId,
                      )
                    }
                  />
                </div>
              </td>
              <td>
                <Input
                  name="value"
                  size="sm"
                  containerClass="pb-0"
                  className="text-sm"
                  value={dynamicField.value}
                  onChange={(e: any) =>
                    onContactDynamicFieldChange(
                      e,
                      'value',
                      dynamicField.dynamicFieldId,
                    )
                  }
                />
              </td>
              <td>
                <div className="flex">
                  {contactData?.dynamicFields[id]?.value === undefined ? (
                    <span className="cursor-not-allowed text-gray-400">
                      <HiPlusSm />
                    </span>
                  ) : contactData?.dynamicFields?.length === id + 1 ? (
                    <span className="cursor-pointer">
                      <HiPlusSm onClick={onContactDynamicFieldAddClick} />
                    </span>
                  ) : (
                    <span className="cursor-pointer">
                      <HiMinusSm
                        onClick={(e: any) =>
                          onContactDynamicFieldRemoveClick(
                            e,
                            dynamicField.dynamicFieldId,
                          )
                        }
                      />
                    </span>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
