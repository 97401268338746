import {useDispatch} from 'react-redux'
import {FormBuilder} from '../../components'
import {useQuery} from 'react-auth-navigation'
import {useEffect} from 'react'
import {getFormByIdAction} from 'redux-src'

export const FormBuilderCreate = () => {
  const dispatch = useDispatch()
  const query: any = useQuery()

  useEffect(() => {
    if (!!query.duplicateId) {
      dispatch(getFormByIdAction(query?.duplicateId))
    }
  }, [dispatch, query?.duplicateId])

  return (
    <div className="m-10 mt-6 mb-0 min-h-[85vh]">
      <FormBuilder />
    </div>
  )
}
