import {allSelectOptions, SelectOption} from './selectOptions.helper'

const {countryOptions, industryTypeOptions, companyCategoryOptions} =
  allSelectOptions

export const addressRemapper = async (response: any) => {
  const address_components = response.results[0].address_components
  const formatted_address = response.results[0].formatted_address

  let country: string, suburb: string, city: string, state: string, zip: string

  const {lat, lng} = response.results[0].geometry.location

  address_components.map((obj: any) => {
    if (obj.types.find((item: any) => item === 'country')) {
      country = obj.long_name
    } else if (obj.types.find((item: any) => item === 'sublocality')) {
      suburb = obj.long_name
    } else if (obj.types.find((item: any) => item === 'locality')) {
      city = obj.long_name
    } else if (
      !obj.types.find((item: any) => item === 'locality') &&
      obj.types.find((item: any) => item === 'postal_town')
    ) {
      city = obj.long_name
    } else if (
      obj.types.find((item: any) => item === 'administrative_area_level_1')
    ) {
      state = obj.long_name
    } else if (obj.types.find((item: any) => item === 'postal_code')) {
      zip = obj.long_name
    }
    return true
  })

  const countryId = getCountryId(country)

  return {
    address: formatted_address,
    zip: zip ? zip : '',
    country: country ? country : '',
    state: state ? state : '',
    city: city ? city : '',
    suburb: suburb ? suburb : '',
    lat: lat ? lat : 0,
    lng: lng ? lng : 0,
    countryId: countryId ? countryId : 0,
    placeId: response.results[0].place_id,
  }
}

export const getCountry = (valueItem: any) => {
  return countryOptions?.find(
    (item: SelectOption) => item.value === parseInt(valueItem),
  )?.key
}

export const getCountryId = (keyValue: any) => {
  return countryOptions?.find((item: SelectOption) =>
    item.key.includes(keyValue),
  )?.value
}

export const getStaff = (value: any) => {
  return companyCategoryOptions?.find(
    (item: SelectOption) => item.value === parseInt(value),
  )?.key
}

export const getIndustry = (value: any) => {
  return industryTypeOptions?.find(
    (item: SelectOption) => item.value === parseInt(value),
  )?.key
}

export const finalAddressRemapper = (addressData: any) => {
  const {address, city, state, countryId, zip, lat, lng, suburb, place} =
    addressData
  let country = getCountry(countryId)
  return {
    address,
    city,
    suburb: suburb === '' ? undefined : suburb,
    place: place === '' ? undefined : place,
    state: state === '' ? undefined : state,
    zipCode: zip === '' ? undefined : String(zip),
    country,
    coordinates: [lat, lng],
  }
}

export const addressStyles = (error: boolean) => ({
  container: (styles: any) => ({
    ...styles,
    height: 'auto',
    fontSize: 12,
    color: 'black',
    padding: '0px',
    width: 'inherit',
  }),
  control: (styles: any) => ({
    ...styles,
    fontSize: 12,
    color: 'black',
    padding: '0px',
    height: 30,
    minHeight: '30px',
    maxHeight: '30px',
    borderRadius: 4,
    overflow: 'hidden',
    borderColor: error ? 'red' : 'rgb(229, 229, 229)',
  }),
  option: (styles: any) => {
    return {
      ...styles,
      fontSize: 12,
      color: 'black',
      padding: '0px',
    }
  },
  input: (styles: any) => ({
    ...styles,
    resize: 'none',
    width: '100%',
    outline: 'none',
    border: 'none',
    fontSize: 12,
    color: 'black',
    overflow: 'hidden',
    margin: '0px',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    padding: '0px',
    display: 'none',
  }),
  menu: (styles: any) => ({
    padding: '0px',
    ...styles,
  }),
  menuList: (styles: any) => ({
    padding: '0px',
    ...styles,
  }),
  singleValue: (styles: any) => {
    return {
      ...styles,
      fontSize: 12,
      color: 'black',
      margin: '0px',
      overflow: 'hidden',
      padding: '0px',
      // transform: 'translateY(0)',
      // margin: 0,
    }
  },
  multiValue: (styles: any) => {
    return {
      padding: '0px',
      ...styles,
    }
  },
  multiValueLabel: (styles: any) => {
    return {
      padding: '0px',
      ...styles,
    }
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      padding: '0px',
      display: 'none',
    }
  },
  dropdownIndicator: (styles: any) => {
    return {
      ...styles,
      padding: '0px',
      display: 'none',
    }
  },
})
