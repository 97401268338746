import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useAuth, useNavigation, Auth} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {AiOutlinePlus} from 'react-icons/ai'
import {saveAs} from 'file-saver'
import classNames from 'classnames'
import {BiImport} from 'react-icons/bi'
import {BsCloudDownload} from 'react-icons/bs'
import {useFormInput} from 'use-form-input'

import {
  createPreliminaryItemAction,
  deletePreliminaryItem,
  deletePreliminaryItemsAction,
  downloadPdfAction,
  generateProjectPreliminaryFromTemplate,
  getPreliminaryListAction,
  getPreliminaryTemplateDetailsAction,
  getProjectByIdAction,
  updateProjectPreliminaryDetails,
} from 'redux-src'

import {
  Button,
  ConfirmationModal,
  DefaultButton,
  Input,
  toast,
} from 'app/common'

import {PreliminarySectionCard} from './components'
import {PreliminarySectionTable} from './components/preliminarySectionCard/components'
import {PRELIMINARY_TABLE_HEADINGS} from './constants'
import {generatePreliminaryHTMLString} from 'helpers/generateHtmlString.helper'
import {DynamicSearchSelect} from '../dynamicSearchSelect'
import {MessageBox} from '../messageBox'

type PrelimType = 'transport' | 'fuel' | 'item' | 'dynamic item'

interface PreviewPreliminaryTemplateItemsType {
  name: string
  lineItemList: Array<Api.PreliminaryTemplateLineItemType>
  type: string
  header: (typeof PRELIMINARY_TABLE_HEADINGS)['dynamic item']

  data: Array<{
    id: number
    name: string
    noOfWeeks: number | string
    noOfQuantity: number
    rate: number
    noOfTransport: number
    // quantityUnit: item.item_details?.frequency_unit,
    totalCost: number
    isChecked: boolean
  }>
  sectionDetails: Api.PreliminaryItemTemplateDetails['prelim_item_template_details']
}

export const PreliminaryEditor = () => {
  const {preliminaryList}: RT.PreliminaryReduxType = useSelector(
    (state: any) => state.preliminary,
  )

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const {downloadPdfLoading: downloadLoading} = useSelector(
    (state: any) => state.file,
  )

  const {
    history: {
      location: {pathname},
    },
    params,
    navigation: {navigate},
  } = useNavigation()

  const {projectId} = params as any
  const {currencySymbol} = useAuth()
  const dispatch = useDispatch()

  const [chooseTab, setChooseTab] = useState<
    Array<{id: number; label: string; value: string; isChecked: boolean}>
  >([
    {
      id: 1,
      label: 'Create Preliminary Manually',
      value: 'createPreliminary',
      isChecked: false,
    },
    {
      id: 2,
      label: 'Import / Generate Preliminary',
      value: 'importPreliminary',
      isChecked: false,
    },
  ])

  const [activeTab, setActiveTab] = useState<
    Array<{
      id: number
      label: string
      isActive: boolean
      isDisabled: boolean
      link: string
    }>
  >([
    {
      id: 1,
      label: 'Import/Generate Preliminary',
      isActive: false,
      isDisabled: false,
      link: 'generate',
    },
    {
      id: 2,
      label: 'Edit',
      isActive: false,
      isDisabled: false,
      link: 'edit',
    },
    {
      id: 3,
      label: 'Preview',
      isActive: false,
      isDisabled: false,
      link: 'preview',
    },
  ])

  const handleChooseTabChange = (selectedItem: {
    id: number
    label: string
    value: string
    isChecked: boolean
  }) => {
    const changedArray = chooseTab?.map((item) => {
      if (item?.id === selectedItem?.id) {
        return {...item, isChecked: true}
      } else {
        return {
          ...item,
          isChecked: false,
        }
      }
    })

    setChooseTab(changedArray)

    if (selectedItem?.value === 'createPreliminary') {
      const changedTabsArr = activeTab?.map((item) => {
        if (item?.id === 1) {
          return {
            ...item,
            isDisabled: true,
            isActive: false,
          }
        } else if (item?.id === 2) {
          return {
            ...item,
            isActive: true,
          }
        } else {
          return {
            ...item,
            isDisabled: false,
            isActive: false,
          }
        }
      })

      setActiveTab(changedTabsArr)
    } else {
      const changedTabsArr = activeTab?.map((item) => ({
        ...item,
        isDisabled: false,
        isActive: item?.id === 1,
      }))

      setActiveTab(changedTabsArr)
    }
  }

  const generatePreliminaryPdf = () => {
    dispatch(
      downloadPdfAction(
        {
          html: generatePreliminaryHTMLString(
            companyDetails,
            projectDetail,
            preliminaryList?.preliminary_details,
            preliminaryList?.project_details?.total_prelim_cost,
            currencySymbol,
          ),
        },
        (data) => {
          saveAs(
            data,
            `${projectDetail?.project_details?.project_prefix}-${projectDetail?.project_details?.project_number}-Preliminary`,
          )
        },
      ),
    )
  }

  const deleteAllProjectPrelimineries = () => {
    if (preliminaryList?.preliminary_details?.length === 0) {
      return toast.error('There is nothing to remove !!!')
    }

    dispatch(
      deletePreliminaryItemsAction(projectId, () => {
        navigate(`/projects/${projectId}/quote/preliminary`)
        dispatch(getPreliminaryListAction(projectId))
      }),
    )
  }

  useEffect(() => {
    if (!!preliminaryList && preliminaryList?.preliminary_details?.length > 0) {
      const filteredArray = activeTab.map((item) => ({
        ...item,
        isActive: item?.id === 2,
        isDisabled: item?.id === 1,
      }))

      setActiveTab(filteredArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preliminaryList])

  useEffect(() => {
    const activatedTab = activeTab.find(
      (item) => !item.isDisabled && item.isActive,
    )

    !!activatedTab &&
      navigate(`/projects/${projectId}/quote/preliminary/${activatedTab?.link}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, projectId])

  useEffect(() => {
    if (projectDetail?.project_details?.is_quote_accepted) {
      const previewTab = activeTab
        ?.map((item) => {
          if (item?.id === 3) {
            return {
              ...item,
              isActive: true,
            }
          } else {
            return {...item}
          }
        })
        ?.at(2)

      if (previewTab?.isActive) {
        navigate(`/projects/${projectId}/quote/preliminary/${previewTab?.link}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projectDetail, activeTab])

  useEffect(() => {
    projectId && dispatch(getPreliminaryListAction(projectId))
  }, [dispatch, projectId])

  return (
    <div className="flex flex-col gap-14">
      {!pathname.includes('preview') &&
        preliminaryList &&
        preliminaryList?.preliminary_details?.length === 0 && (
          <div className="flex border-t-1 border-b-1 border-gray-200 p-30 justify-evenly items-center">
            {chooseTab?.map((item, index: number) => {
              return (
                <span
                  className="flex cursor-pointer gap-6 justify-center items-center"
                  key={index}
                >
                  {item?.value === 'createPreliminary' ? (
                    <AiOutlinePlus
                      size={22}
                      strokeWidth={2}
                      className={`${
                        item?.isChecked
                          ? 'text-blue-200 hover:text-blue-300 transition-all duration-100 ease-in-out'
                          : 'text-gray-400'
                      }`}
                    />
                  ) : (
                    <BiImport
                      size={22}
                      className={`${
                        item?.isChecked
                          ? 'text-blue-200 hover:text-blue-300 transition-all duration-100 ease-in-out'
                          : 'text-gray-400'
                      }`}
                    />
                  )}

                  <span
                    className={`text-xl   ${
                      item.isChecked
                        ? 'text-blue-200 hover:text-blue-300 transition-all duration-100 ease-in-out'
                        : 'text-gray-400 hover:text-gray-500'
                    }`}
                    onClick={() => {
                      handleChooseTabChange(item)
                    }}
                  >
                    {item.label}
                  </span>
                </span>
              )
            })}
          </div>
        )}

      {preliminaryList?.preliminary_details?.length > 0 && (
        <div
          className={`flex ${
            projectDetail?.project_details?.is_quote_accepted
              ? 'justify-end'
              : 'justify-between'
          }  rounded-md shadow-sm`}
          role="group"
        >
          {!projectDetail?.project_details?.is_quote_accepted && (
            <div>
              <button
                type="button"
                className={classNames(
                  'border-1  py-4 px-10 text-sm font-medium rounded-l-sm  focus:z-10 ',
                  `${
                    activeTab.at(1).isActive
                      ? 'bg-blue-200 text-white border-blue-200'
                      : 'border-gray-200'
                  }`,
                )}
                onClick={() => {
                  setActiveTab((prev) =>
                    prev.map((tabItem) => ({
                      ...tabItem,
                      isActive: activeTab?.at(1)?.id === tabItem?.id,
                    })),
                  )
                }}
              >
                Edit
              </button>

              <button
                type="button"
                className={classNames(
                  'border-1 py-4 px-10 text-sm font-medium  rounded-r-sm border focus:z-10',
                  `${
                    activeTab.at(2).isActive
                      ? 'bg-blue-200 text-white border-blue-200'
                      : 'border-gray-200 '
                  }`,
                )}
                onClick={() => {
                  setActiveTab((prev) =>
                    prev.map((tabItem) => ({
                      ...tabItem,
                      isActive: activeTab?.at(2)?.id === tabItem?.id,
                    })),
                  )
                }}
              >
                Preview
              </button>
            </div>
          )}

          <div className="flex flex-nowrap gap-16 items-center justify-items-end">
            {projectDetail?.project_details?.is_quote_accepted && (
              <div className="flex gap-6 items-center">
                <label className="text-md font-bold">
                  Preliminary cost per week : {currencySymbol}
                  {Number(
                    projectDetail?.project_details?.prelim_cost_per_week ?? 0,
                  ).toFixed(2)}
                </label>
              </div>
            )}
            <div>
              {!projectDetail?.project_details?.is_quote_accepted &&
                preliminaryList?.preliminary_details?.length !== 0 && (
                  <ConfirmationModal
                    onConfirmClick={(closeModalHandler) => {
                      deleteAllProjectPrelimineries()
                      closeModalHandler()
                    }}
                    danger
                    label={
                      'Are you sure you want to remove all the preliminaries?'
                    }
                    displayElement={
                      <Button
                        title="Remove Prelimineries"
                        className={`w-[max-content] ${
                          preliminaryList?.preliminary_details?.length === 0
                            ? 'cursor-not-allowed bg-blue-200 text-white border-none'
                            : 'bg-red-300 hover:bg-red-400 active:bg-red-300'
                        } `}
                      />
                    }
                    confirmLabel="Remove All Preliminaries"
                  ></ConfirmationModal>
                )}
            </div>
            {preliminaryList?.preliminary_details?.length > 0 && (
              <DefaultButton
                title="Download preliminary"
                leftIcon={<BsCloudDownload />}
                className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                onClick={generatePreliminaryPdf}
                loading={downloadLoading}
              />
            )}
          </div>
        </div>
      )}
      <div>
        <Auth.Screens path={`/projects/:projectId/quote/preliminary`} />
      </div>
    </div>
  )
}

export const PreliminaryEditPage = () => {
  const {preliminaryList}: RT.PreliminaryReduxType = useSelector(
    (state: any) => state.preliminary,
  )

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any
  const {currencySymbol} = useAuth()

  const [constantData, {onChange, setValue}] = useFormInput({
    noOfWeeks: 0,
    delivaryCost: 0,
  })

  const fetchPreliminaryItems = useCallback(() => {
    dispatch(getPreliminaryListAction(projectId))
  }, [dispatch, projectId])

  const removePreliminarySection = (sectionId: number | string) => {
    dispatch(
      deletePreliminaryItem(projectId, sectionId, () => {
        fetchPreliminaryItems()
      }),
    )
  }

  const onUpdateProjectPreliminaryDetails = () => {
    if (
      projectDetail?.project_details?.prelim_delivery_cost ===
      constantData?.delivaryCost
    )
      return

    const preliminaryBody = {
      prelimDeliveryCost: Number(constantData?.delivaryCost),
    }

    dispatch(
      updateProjectPreliminaryDetails(projectId, preliminaryBody, () => {
        dispatch(getProjectByIdAction(projectId))
      }),
    )
  }

  const prepopulateProjectPrelData = useCallback(() => {
    setValue('noOfWeeks', projectDetail?.project_details?.prelim_total_weeks)
    setValue(
      'delivaryCost',
      projectDetail?.project_details?.prelim_delivery_cost,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])

  const initialPreliminarySection = (e: React.FormEvent) => {
    e.preventDefault()

    const body = {
      title: 'Title',
      type: 'item',
      totalFrequency: 0,
      frequencyUnit: 'Quantity',
    }

    dispatch(
      createPreliminaryItemAction(
        projectId,
        body,
        () => {
          fetchPreliminaryItems()
        },
        () => {
          console.log('error while creating section !!')
        },
      ),
    )
  }

  useEffect(() => {
    fetchPreliminaryItems()
    prepopulateProjectPrelData()
  }, [projectId, fetchPreliminaryItems, prepopulateProjectPrelData])

  return (
    <div>
      <div className="flex justify-end gap-16 items-center w-full">
        <div className="flex gap-6 items-center">
          <label className="text-md ">Delivary cost : </label>
          <Input
            type="number"
            name="delivaryCost"
            value={constantData?.delivaryCost}
            onChange={onChange}
            min={0}
            onBlur={onUpdateProjectPreliminaryDetails}
            containerClass="m-0 w-[160px]"
          />
        </div>
        <div className="flex gap-6 items-center">
          <label className="text-md ">No. of weeks : </label>

          <Input
            type="number"
            name="noOfWeeks"
            value={constantData?.noOfWeeks}
            min={0}
            containerClass="m-0 w-[80px]"
            disabled={true}
          />
        </div>
      </div>

      {preliminaryList &&
        preliminaryList?.preliminary_details?.length > 0 &&
        preliminaryList?.preliminary_details?.map(
          ({item_details, line_item_list}, index: number) => {
            return (
              <PreliminarySectionCard
                key={index}
                sectionDetail={item_details}
                lineItemList={line_item_list}
                onRemoveSection={removePreliminarySection}
                fetchPreliminaryItems={fetchPreliminaryItems}
              />
            )
          },
        )}

      {preliminaryList && preliminaryList?.preliminary_details?.length > 0 && (
        <>
          <div className="border-t-1 border-t-gray-200 mt-8 pt-8 flex flex-row justify-end">
            <div className="flex flex-col justify-start">
              <div className="flex flex-row justify-end gap-10 mt-6">
                <div className="font-bold text-right">Preliminary Cost</div>
                <div className="w-[85px] font-bold text-right">
                  {currencySymbol}
                  {(
                    preliminaryList?.project_details?.total_prelim_cost ?? 0.0
                  ).toFixed(2)}
                </div>
              </div>
              <div className="flex flex-row justify-end gap-10 mt-6">
                <div className="font-bold text-right">Delivery Cost</div>
                <div className="w-[85px] font-bold text-right">
                  {currencySymbol}
                  {(
                    preliminaryList?.project_details?.prelim_delivery_cost ??
                    0.0
                  ).toFixed(2)}
                </div>
              </div>
              <div className="flex flex-row justify-end gap-10 mt-6">
                <div className="font-bold text-right">Grand Total</div>
                <div className="w-[85px] font-bold text-right">
                  {currencySymbol}
                  {(
                    Number(
                      preliminaryList?.project_details?.total_prelim_cost ??
                        0.0,
                    ) +
                    Number(
                      preliminaryList?.project_details?.prelim_delivery_cost ??
                        0.0,
                    )
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className={`flex gap-16 border-t-1 border-b-1 border-gray-200 p-30 justify-center items-center mt-12`}
      >
        <span
          className="flex cursor-pointer gap-6 justify-center items-center"
          onClick={initialPreliminarySection}
        >
          <AiOutlinePlus size={22} strokeWidth={2} className="text-gray-400" />
          <span className="text-xl text-gray-400 hover:text-gray-500 ">
            Add new preliminary
          </span>
        </span>
      </div>
    </div>
  )
}

export const PreliminaryPreviewPage = () => {
  const {preliminaryList}: RT.PreliminaryReduxType = useSelector(
    (state: any) => state.preliminary,
  )

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {currencySymbol} = useAuth()

  const [remappedPrelim, setRemappedPrelim] = useState<any>(undefined)

  const remappedPreliminaryPreviewList = useCallback(() => {
    const remappedPrelim = preliminaryList?.preliminary_details?.map((item) => {
      return {
        name: item.item_details.title,
        lineItemList: item.line_item_list,
        type: item.item_details.type,
        header:
          PRELIMINARY_TABLE_HEADINGS[
            (item.item_details?.type as PrelimType) ?? 'dynamic item'
          ],

        data: item?.line_item_list?.map((lineItem: any) => {
          return {
            id: lineItem?.id,
            name: lineItem?.name,
            noOfWeeks: projectDetail?.project_details?.prelim_total_weeks,
            noOfQuantity: item.item_details?.total_frequency,
            rate: lineItem?.rate,
            noOfTransport: item.item_details?.total_vehicles,
            // quantityUnit: item.item_details?.frequency_unit,
            totalCost: lineItem?.total_cost,
          }
        }),
        sectionDetails: item.item_details,
      }
    })

    setRemappedPrelim(remappedPrelim)
  }, [preliminaryList, projectDetail?.project_details?.prelim_total_weeks])

  useEffect(() => {
    remappedPreliminaryPreviewList()
  }, [preliminaryList, remappedPreliminaryPreviewList])

  return (
    <>
      <div className="w-full flex flex-col gap-y-3">
        {preliminaryList?.preliminary_details?.length !== 0 ? (
          remappedPrelim?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className="font-bold pr-1 text-md">{item?.name}</div>
                <div className="p-6">
                  <PreliminarySectionTable
                    preliminaryType={item?.type}
                    preliminaryHeader={item?.header}
                    preliminaryData={item?.data}
                    sectionDetails={item.sectionDetails}
                    isPreview={true}
                  />
                </div>
              </div>
            )
          })
        ) : (
          <MessageBox message={'There is no preliminary item to preview !!!'} />
        )}
      </div>
      {preliminaryList?.preliminary_details?.length !== 0 && (
        <div className="border-t-1 border-t-gray-200 mt-8 pt-8 flex flex-row justify-end">
          <div className="flex flex-col justify-start">
            <div className="flex flex-row justify-end gap-10 mt-6">
              <div className="font-bold text-right">Preliminary Cost</div>
              <div className="w-[85px] font-bold text-right">
                {currencySymbol}
                {(
                  preliminaryList?.project_details?.total_prelim_cost ?? 0.0
                ).toFixed(2)}
              </div>
            </div>
            <div className="flex flex-row justify-end gap-10 mt-6">
              <div className="font-bold text-right">Delivery Cost</div>
              <div className="w-[85px] font-bold text-right">
                {currencySymbol}
                {(
                  preliminaryList?.project_details?.prelim_delivery_cost ?? 0.0
                ).toFixed(2)}
              </div>
            </div>
            <div className="flex flex-row justify-end gap-10 mt-6">
              <div className="font-bold text-right">Grand Total</div>
              <div className="w-[85px] font-bold text-right">
                {currencySymbol}
                {(
                  Number(
                    preliminaryList?.project_details?.total_prelim_cost ?? 0.0,
                  ) +
                  Number(
                    preliminaryList?.project_details?.prelim_delivery_cost ??
                      0.0,
                  )
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const GeneratePreliminaryPage = () => {
  const {preliminaryTemplateDetails}: RT.PreliminaryTemplateReduxType =
    useSelector((state: any) => state.preliminaryTemplate)

  const {preliminaryList}: RT.PreliminaryReduxType = useSelector(
    (state: any) => state.preliminary,
  )

  const [isImportBtnDisabled, setImportBtnDisabled] = useState<boolean>(false)

  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const {projectId} = params as any

  const {currencySymbol} = useAuth()

  const dispatch = useDispatch()

  const [constantData, {setValue}] = useFormInput({
    selectedTemplated: undefined,
  })

  const remappedPreliminaryTemplateDetails: PreviewPreliminaryTemplateItemsType[] =
    useMemo(() => {
      const remappedDetails = preliminaryTemplateDetails?.items?.map(
        ({prelim_item_template_details, line_items}) => {
          return {
            name: prelim_item_template_details.title,
            lineItemList: line_items,
            type: prelim_item_template_details.type,
            header:
              PRELIMINARY_TABLE_HEADINGS[
                (prelim_item_template_details?.type as PrelimType) ??
                  'dynamic item'
              ],

            data: line_items?.map((lineItem) => {
              return {
                id: lineItem?.id,
                name: lineItem?.name,
                noOfWeeks: 1,
                noOfQuantity: prelim_item_template_details?.total_frequency,
                rate: lineItem?.rate,
                noOfTransport: prelim_item_template_details?.total_vehicles,
                // quantityUnit: item.item_details?.frequency_unit,
                totalCost: lineItem?.total_cost,
                isChecked: true,
              }
            }),
            sectionDetails: prelim_item_template_details,
          }
        },
      )

      return remappedDetails
    }, [preliminaryTemplateDetails?.items])

  useEffect(() => {
    remappedPreliminaryTemplateDetails &&
      setPreviewPreliminaryTemplate(remappedPreliminaryTemplateDetails)
  }, [remappedPreliminaryTemplateDetails])

  const [previewPreliminaryTemplateItems, setPreviewPreliminaryTemplate] =
    useState<Array<PreviewPreliminaryTemplateItemsType>>()

  const onSelectLineItemHandler = (
    lineItemId: number,
    preliminaryItemId: number,
  ) => {
    const changeLineItems = previewPreliminaryTemplateItems?.map(
      (preliminaryItems) => {
        if (preliminaryItems?.sectionDetails?.id === preliminaryItemId) {
          return {
            ...preliminaryItems,
            data: preliminaryItems?.data?.map((lineItem) => {
              if (lineItem?.id === lineItemId) {
                return {
                  ...lineItem,
                  isChecked: !lineItem.isChecked,
                }
              } else {
                return {...lineItem}
              }
            }),
          }
        } else {
          return {...preliminaryItems}
        }
      },
    )
    setImportBtnDisabled(() => {
      const changeItems = changeLineItems?.map((item) =>
        item?.data?.filter(({isChecked}) => isChecked)?.map(({id}) => id),
      )

      return changeItems?.every((item) => item?.length === 0)
    })
    setPreviewPreliminaryTemplate(changeLineItems)
  }

  const generateProjectPreliminary = () => {
    const remappedPreliminaryItems = previewPreliminaryTemplateItems
      ?.map(({data, sectionDetails}) => {
        return {
          prelimItemTemplateId: sectionDetails?.id,
          prelimLineItemTemplateIds: data
            ?.filter(({isChecked}) => isChecked)
            ?.map(({id}) => id),
        }
      })
      ?.filter((item) => item?.prelimLineItemTemplateIds?.length > 0)

    const body = {
      items: remappedPreliminaryItems,
    }

    if (body?.items?.length > 0) {
      dispatch(
        generateProjectPreliminaryFromTemplate(
          projectId,
          preliminaryTemplateDetails?.details?.id,
          body,
          () => {
            navigate(`/projects/${projectId}/quote/preliminary/edit`)
          },
        ),
      )
    } else {
      toast.error(
        'Please select the preliminary line items to generate the project preliminary !!',
      )
    }
  }

  useEffect(() => {
    !!constantData?.selectedTemplated &&
      dispatch(
        getPreliminaryTemplateDetailsAction(
          constantData?.selectedTemplated?.id,
          () => {},
        ),
      )
  }, [constantData?.selectedTemplated, dispatch])

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-8">
        <div className="w-[30%] flex flex-col gap-6">
          <div className="text-black font-bold text-md">
            Select Preliminary Template
          </div>
          <DynamicSearchSelect
            actionUrl={`/preliminary-templates`}
            params={{isPublished: true}}
            value={constantData?.selectedTemplated ?? undefined}
            remapOptions={(data: Api.PreliminaryTemplateIndividual[]) => {
              return data?.map(({prelim_template_details}) => {
                return {
                  id: prelim_template_details?.id,
                  label: prelim_template_details?.title,
                  value: prelim_template_details?.id,
                }
              })
            }}
            onChangeValue={(template) => {
              setValue('selectedTemplated', template)
            }}
          />
        </div>

        {!!constantData?.selectedTemplated && (
          <div className="flex flex-col gap-6">
            <div className="flex justify-end items-center">
              <Button
                title="Import This Template"
                isDisabled={isImportBtnDisabled}
                onClick={generateProjectPreliminary}
              />
            </div>
            <div className="w-full flex flex-col gap-y-3 bg-gray-100 p-10 mt-8 rounded-md">
              {previewPreliminaryTemplateItems?.map(
                (item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="font-bold pr-1 text-md">{item?.name}</div>
                      <div className="p-6">
                        <PreliminarySectionTable
                          preliminaryType={item?.type}
                          preliminaryHeader={item?.header}
                          preliminaryData={item?.data}
                          sectionDetails={item.sectionDetails}
                          isGeneratePreliminary={true}
                          onChangeHandler={onSelectLineItemHandler}
                        />
                      </div>
                    </div>
                  )
                },
              )}
            </div>
            <div className="px-14 py-10 flex justify-end items-center border-y-1 mt-8 border-gray-200">
              <span className="text-black font-bold text-md">
                Total Cost : {currencySymbol}{' '}
                {preliminaryList?.project_details?.total_prelim_cost}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
