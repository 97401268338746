import React, {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useAuth} from 'react-auth-navigation'
import classNames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {HiPlusSm} from 'react-icons/hi'
import {saveAs} from 'file-saver'
import {AiOutlineDelete, AiOutlinePlus} from 'react-icons/ai'
import {BsCloudDownload} from 'react-icons/bs'
import {FaAngleDown} from 'react-icons/fa'
import {CiEdit} from 'react-icons/ci'
import {IoMdEye} from 'react-icons/io'
import {BiSolidMessageSquareDetail} from 'react-icons/bi'

import {Textarea, Dropdown, Input, ToolTip, DefaultButton} from 'app/common'

import {
  createNewQuoteItemAction,
  createNewVersionAction,
  deleteQuoteItemAction,
  getProjectByIdAction,
  getQuoteItem,
  getQuotesAction,
  GET_QUOTE_ITEMS,
  getQuoteBasicDetailsAction,
  getQuoteOverviewAction,
  voidQuoteAction,
  getQuoteSettings,
  downloadPdfAction,
} from 'redux-src'
import {getChipData} from 'app/pages/jobs/pages/quote/components'
import {generateQuoteHTMLString} from 'helpers/generateHtmlString.helper'

import {QUOTE_TABLE_HEADINGS} from './constants'
import {
  SectionCard,
  LetterHead,
  ButtonAction,
  QuoteOverviewModal,
} from './components'

export function InvoiceEditor({
  value = 'Invoice',
  // activeQuoteId,
  projectId,
}: {
  value?: any
  // activeQuoteId?: any
  projectId?: any
  setActiveQuoteId?: (a: number) => void
}) {
  const dispatch = useDispatch()
  const {toast, currencySymbol} = useAuth()

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const {defaultQuoteSettings}: RT.QuoteSettingsReduxType = useSelector(
    (state: any) => state.quoteSetting,
  )

  const {
    quotes,
    quoteDetail,
    quoteBasicDetails,
    activeQuoteId,
  }: RT.QuoteReduxType = useSelector((state: any) => state.quotes)

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {downloadPdfLoading} = useSelector((state: any) => state.file)

  const grandTotal = useMemo(() => {
    if (projectDetail?.project_details?.is_prelim_included) {
      return (
        quoteBasicDetails?.quote_details?.total_cost +
        projectDetail?.project_details?.total_prelim_cost
      )
    } else {
      return quoteBasicDetails?.quote_details?.total_cost
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quoteBasicDetails?.quote_details?.total_cost,
    projectDetail?.project_details?.total_prelim_cost,
  ])

  useEffect(() => {
    dispatch(getProjectByIdAction(projectId))
  }, [dispatch, projectId])

  const quoteVersionDetail = useMemo(
    () =>
      quotes?.find(({quote_details}) => activeQuoteId === quote_details?.id),
    [activeQuoteId, quotes],
  )
  const [descriptionText, setDescriptionText] = useState<string>(
    quoteBasicDetails?.quote_details?.description ?? '',
  )

  const [footerNotes, setFooterNotes] = useState<string>(
    quoteBasicDetails?.quote_details?.footer_notes ?? '',
  )

  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [initialSectionName, setInitialSectionName] = useState<string>()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const fetchQuoteItem = useCallback(() => {
    if (!!projectId && !!activeQuoteId) {
      // dispatch(getQuotesAction(projectId))  //* This will cause it so that quotes are fetched and changed again so active id is set back to latest
      dispatch(getQuoteItem(projectId, activeQuoteId))
    }

    return
  }, [activeQuoteId, dispatch, projectId])

  useEffect(() => {
    fetchQuoteItem()
    return () => {
      dispatch({type: GET_QUOTE_ITEMS.CLEAR})
    }
  }, [fetchQuoteItem, dispatch])

  useEffect(() => {
    if (companyDetails?.company_details?.id)
      dispatch(getQuoteSettings(companyDetails?.company_details?.id))
  }, [companyDetails?.company_details?.id, dispatch])

  const initialSubmitSection = (e: React.FormEvent) => {
    e.preventDefault()

    if (initialSectionName.length > 0) {
      const initialSectionNameClone = initialSectionName
      // const sectionListClone = [...sectionList]
      const body = {title: initialSectionNameClone}

      dispatch(
        createNewQuoteItemAction(
          projectId,
          activeQuoteId,
          body,
          toast,
          () => {
            fetchQuoteItem()
          },
          () => {},
        ),
      )
      setInitialSectionName('')
    }
  }

  const onRemoveHandler = (quoteId: number, quoteItemId: number) => {
    dispatch(
      deleteQuoteItemAction(
        projectId,
        quoteId,
        quoteItemId,
        toast,
        () => {
          fetchQuoteItem()
        },
        () => {},
      ),
    )
  }

  const onCreateNewVersion = () => {
    dispatch(
      createNewVersionAction(projectId, activeQuoteId, () => {
        dispatch(getQuotesAction(projectId))
      }),
    )
  }

  const fetchQuoteBasicDetails = useCallback(() => {
    activeQuoteId &&
      dispatch(getQuoteBasicDetailsAction(projectId, activeQuoteId, () => {}))
  }, [activeQuoteId, dispatch, projectId])

  useEffect(() => {
    fetchQuoteBasicDetails()
  }, [fetchQuoteBasicDetails])

  const textDesc = (status: string, delivery?: boolean) => {
    switch (true) {
      case status === 'draft':
        return `This quote version is a Draft`

      case status === 'published' && delivery === false:
        return 'Waiting to be sent'
      case status === 'published' && delivery === true:
        return 'Awaiting a response '

      case status === 'accepted':
        return 'This quote has been Accepted'

      case status === 'declined':
        return 'This quote has been Declined'

      default:
        return null
    }
  }

  const generateQuotePDF = (fileName?: string) => {
    dispatch(
      downloadPdfAction(
        {
          html: generateQuoteHTMLString(
            companyDetails,
            projectDetail,
            quoteDetail,
            quoteBasicDetails,
            currencySymbol,
          ).toString(),
        },
        (data: any) => {
          saveAs(data, fileName)
        },
      ),
    )
  }

  const handleOverview = (quoteId: number) => {
    setModalOpen((prev) => !prev)
    dispatch(getQuoteOverviewAction(projectId, quoteId, () => {}))
  }

  const handleVoidQuoteAction = () => {
    dispatch(
      voidQuoteAction(projectId, activeQuoteId, () => {
        dispatch(getQuotesAction(projectId))
      }),
    )
  }

  return (
    <>
      <div className="flex flex-row justify-between bg-blue-150 p-10 ">
        <div className="flex justify-center items-center gap-10">
          <Dropdown
            triggerElement={
              <div className="flex flex-row justify-between items-center cursor-pointer">
                version {quoteVersionDetail?.quote_details?.version}
                <div className="flex flex-row justify-between items-center gap-4 pl-10">
                  {quoteVersionDetail?.quote_details?.is_super_seeded ? (
                    <div
                      key={value}
                      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-800`}
                    >
                      SUPERSEDED
                    </div>
                  ) : (
                    getChipData(quoteVersionDetail?.quote_details?.status)
                  )}
                  <FaAngleDown fill="#6a6a6a" />
                </div>
              </div>
            }
          >
            <div className="w-[250px] bg-white shadow-md border-1 border-gray-200 rounded-sm py-0">
              {quotes?.length > 0 &&
                quotes?.map((item) => {
                  let isActive = item.quote_details?.id === activeQuoteId

                  return (
                    <div
                      key={item.quote_details.id}
                      className={classNames(
                        'flex items-center justify-between p-12 cursor-pointer text-sm border-b-1 border-gray-200 ',
                        isActive
                          ? 'bg-blue-300 text-white'
                          : 'hover:bg-gray-200',
                      )}
                      onClick={() => {
                        dispatch({
                          type: 'SET_ACTIVE_QUOTE',
                          payload: item?.quote_details?.id,
                        })
                        // setActiveQuoteId(item?.quote_details?.id)
                      }}
                    >
                      <div className="flex items-center">
                        <div>Version {item.quote_details.version}</div>
                        <div
                          className={classNames(
                            'px-6 mx-4 text-[10px] font-[10px]  rounded-sm ',
                            isActive ? 'text-white' : 'text-gray-900',
                          )}
                        >
                          {/* {item.quote_details?.status.toUpperCase()} */}
                          {item?.quote_details?.is_super_seeded ? (
                            <div
                              key={value}
                              className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-800`}
                            >
                              SUPERSEDED
                            </div>
                          ) : (
                            getChipData(item.quote_details?.status)
                          )}
                        </div>
                      </div>
                      <div>
                        {' '}
                        {moment(item.quote_details.created_at).format(
                          'DD/MM/YYYY',
                        )}
                      </div>
                    </div>
                  )
                })}

              {quoteVersionDetail?.quote_details?.status !== 'accepted' &&
                quoteVersionDetail?.quote_details?.status !== 'declined' &&
                !quoteVersionDetail?.quote_details?.is_super_seeded && (
                  <div
                    className="flex items-center justify-center gap-6 p-12 cursor-pointer text-sm hover:bg-blue-200 font-bold hover:text-white"
                    onClick={onCreateNewVersion}
                  >
                    <div className="text-md">+</div>
                    <div>Create New Version</div>
                  </div>
                )}
            </div>
          </Dropdown>

          {quoteVersionDetail?.quote_details?.status === 'draft' &&
            !quoteVersionDetail?.quote_details?.is_super_seeded && (
              <ToolTip text="Void Quote" top>
                <div className="cursor-pointer" onClick={handleVoidQuoteAction}>
                  <AiOutlineDelete size={20} />
                </div>
              </ToolTip>
            )}
        </div>

        <div className="inline-flex rounded-md shadow-sm " role="group">
          <button
            type="button"
            className={classNames(
              'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 mr-10 text-[10px] font-medium text-blue-500 bg-white rounded-sm border  border-blue-500 hover:bg-gray-100 hover:text-blue-700 active:bg-gray-200 active:text-blue-700 focus:z-10 focus:border-blue-200',
            )}
            onClick={() => {
              handleOverview(quoteVersionDetail?.quote_details?.id)
            }}
          >
            <BiSolidMessageSquareDetail size={14} />
            Detail Overview
          </button>

          {quoteVersionDetail?.quote_details?.status !== 'accepted' &&
            quoteVersionDetail?.quote_details?.status !== 'declined' && (
              <button
                type="button"
                className={classNames(
                  'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-l-sm',
                  isPreview
                    ? ' text-white z-10 border-blue-400 bg-blue-400'
                    : 'text-gray-400 border-white bg-white',
                )}
                onClick={() => {
                  setIsPreview(true)
                }}
              >
                <IoMdEye size={14} />
                Preview
              </button>
            )}
          {quoteVersionDetail?.quote_details?.status === 'draft' && (
            <button
              type="button"
              className={classNames(
                'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-r-sm',
                !isPreview
                  ? ' text-white z-10 border-blue-400 bg-blue-400'
                  : 'text-gray-400 border-white bg-white',
              )}
              onClick={() => {
                setIsPreview(false)
                // console.log('isPreview: ', isPreview)
              }}
            >
              <CiEdit size={14} />
              Edit
            </button>
          )}
        </div>
      </div>
      <div className=" bg-blue-200 p-8 flex flex-row justify-between">
        {quoteVersionDetail?.quote_details?.status !== 'accepted' &&
          quoteVersionDetail?.quote_details?.status !== 'declined' &&
          !quoteVersionDetail?.quote_details?.is_super_seeded && (
            <button
              type="button"
              className="flex justify-center items-center gap-10 border-1 py-5 px-14 text-sm font-medium text-gray-900 bg-white rounded-sm border 
            border-gray-200 hover:bg-gray-100 active:bg-gray-100 focus:z-10 focus:text-blue-700"
              onClick={onCreateNewVersion}
            >
              <AiOutlinePlus size={16} /> Create New Version
            </button>
          )}

        <div className="text-white flex items-center align-middle">
          {quoteVersionDetail?.quote_details?.is_super_seeded
            ? 'This quote has been superseded'
            : textDesc(
                quoteVersionDetail?.quote_details?.status,
                quoteVersionDetail?.quote_details?.is_delivered,
              )}{' '}
          (v
          {quoteVersionDetail?.quote_details?.version})
        </div>

        {!quoteVersionDetail?.quote_details?.is_super_seeded && (
          <div className="flex gap-10">
            {quoteVersionDetail?.quote_details?.status === 'published' ||
              (quoteVersionDetail?.quote_details?.status === 'accepted' && (
                <DefaultButton
                  title="Download Pdf"
                  leftIcon={<BsCloudDownload />}
                  className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                  onClick={() =>
                    generateQuotePDF(
                      `${
                        projectDetail?.project_details?.project_prefix +
                        '-' +
                        projectDetail?.project_details?.project_number
                      }-Quote-v${quoteVersionDetail?.quote_details?.version}`,
                    )
                  }
                  loading={downloadPdfLoading}
                />
              ))}

            <ButtonAction
              projectId={projectId}
              activeQuoteId={activeQuoteId}
              quoteDetails={quoteVersionDetail?.quote_details}
              quote={quoteDetail}
              generateQuotePDF={generateQuotePDF}
            />
          </div>
        )}
      </div>
      <LetterHead
        previewMode={isPreview}
        companyDetails={companyDetails}
        quoteDetail={{site_details: projectDetail?.site_address_details}}
        quoteVersionDetail={quoteVersionDetail}
        quoteBasicDetails={quoteBasicDetails}
      />
      <div className="my-16 mx-8">
        <div id="quote-title">
          <input
            type="text"
            className="text-lg font-bold"
            value={value}
            onChange={() => {}}
          />
        </div>

        <div
          id="quote-description"
          className="text-sm pb-16 border-b-1 border-b-gray-200"
        >
          {/* {isPreview ? ( */}
          {true ? (
            <div
              dangerouslySetInnerHTML={{
                __html: defaultQuoteSettings?.description_text?.replaceAll(
                  '\n',
                  '<br/>',
                ),
              }}
              className="mt-16 text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16"
              name="invoiceDescription"
              placeholder={`${value} description`}
              size="sm"
              value={descriptionText}
              onChange={(e: any) => setDescriptionText(e.target.value)}
            />
          )}
        </div>
      </div>
      {/* HEADINGS */}
      <div className="w-full flex px-10">
        {QUOTE_TABLE_HEADINGS.map(({title, width, hidden}, index) => (
          <span
            key={index}
            className={[
              `text-sm font-bold block py-16 first:pl-0 first:text-left text-center last:pr-0 last:${
                isPreview ||
                quoteVersionDetail?.quote_details?.status === 'accepted'
                  ? 'text-right'
                  : 'text-center'
              }`,
              hidden ? 'text-gray-300' : '',
            ].join(' ')}
            style={{width}}
          >
            {title}
          </span>
        ))}
      </div>
      {isPreview || quoteVersionDetail?.quote_details?.status !== 'draft' ? (
        <div className="px-10">
          {quoteDetail?.length &&
            quoteDetail?.map(({quote_item_details: item, line_item_list}) => {
              return (
                <div className="mt-10" key={item.id}>
                  <h1>
                    <strong>{item.title}</strong>
                  </h1>
                  <table
                    style={{
                      width: '100%',
                    }}
                  >
                    <tbody>
                      {line_item_list?.length > 0 &&
                        line_item_list?.map((item: any, i: number) => {
                          // const lineItem = Object.keys(item)
                          return (
                            <tr key={i}>
                              {[
                                'name',
                                'quantity',
                                'cost',
                                'price',
                                'tax_percent',
                                'discount_percent',
                                'total_cost',
                              ].map((key, index) => {
                                return (
                                  <td
                                    key={index}
                                    className="first:text-left text-center last:text-right"
                                    style={{
                                      width: QUOTE_TABLE_HEADINGS.find(
                                        (heading) => heading?.key === key,
                                      )?.width,
                                    }}
                                  >
                                    {item[key]}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              )
            })}
        </div>
      ) : (
        <>
          {/* NEW SECTION FORM */}
          <div className="mb-16 px-10">
            <form onSubmit={initialSubmitSection}>
              <Input
                prepend={<HiPlusSm size={24} />}
                placeholder="Type section name and press enter"
                name="sectionName"
                onChange={(e: any) => setInitialSectionName(e.target.value)}
                value={initialSectionName}
                className="rounded-r-sm"
              />
            </form>
          </div>

          {quoteDetail?.length > 0 ? (
            <>
              {quoteDetail?.map(
                ({quote_item_details: item, line_item_list}, index) => {
                  if (item === null)
                    return <React.Fragment key={index}> </React.Fragment>
                  return (
                    <div className="mt-16 mb-16 px-10" key={item?.id}>
                      <SectionCard
                        activeQuoteId={activeQuoteId}
                        sectionIndex={index}
                        sectionDetail={item}
                        onRemoveSection={() =>
                          onRemoveHandler(item?.quote_id, item?.id)
                        }
                        sectionLineItemList={line_item_list}
                        sectionList={quoteDetail}
                        fetchQuoteItem={fetchQuoteItem}
                      />
                    </div>
                  )
                },
              )}
            </>
          ) : null}
        </>
      )}

      <div
        className="
      border-t-1 border-t-gray-200
      mt-8 pt-8
      flex flex-row justify-end px-10
      "
      >
        <div>
          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">SubTotal</div>
            <div className="w-[85px] font-bold text-right">
              {currencySymbol}
              {quoteBasicDetails?.quote_details?.taxable_cost ?? 0.0}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">Discount</div>
            <div className="w-[85px] font-bold text-right">
              {currencySymbol}-
              {quoteBasicDetails?.quote_details?.discount ?? 0.0}
            </div>
          </div>
          {quoteBasicDetails?.quote_details?.discounted_cost ? (
            <div className="flex flex-row justify-end gap-10">
              <div className="font-bold text-right">Discounted Sub Total</div>
              <div className="w-[85px] font-bold text-right">
                {currencySymbol}
                {quoteBasicDetails?.quote_details?.discounted_cost}
              </div>
            </div>
          ) : null}

          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">Tax Amount</div>
            <div className="w-[85px] font-bold text-right">
              {currencySymbol}
              {quoteBasicDetails?.quote_details?.tax ?? 0.0}
            </div>
          </div>
          {projectDetail?.project_details?.is_prelim_included === true && (
            <div className="flex flex-row justify-end gap-10">
              <div className="font-bold text-right">Preliminary Cost</div>
              <div className="w-[85px] font-bold text-right">
                {currencySymbol}
                {projectDetail?.project_details?.total_prelim_cost ?? 0.0}
              </div>
            </div>
          )}

          <div className="flex flex-row justify-end gap-10 mt-6">
            <div className="font-bold text-right">Total</div>
            <div className="w-[85px] font-bold text-right">
              {currencySymbol}
              {grandTotal ?? 0.0}
              {/* {quoteBasicDetails?.quote_details?.total_cost ?? 0.0} */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24 mb-48 px-8">
        <div className="text-sm pt-16  ">
          {true ? (
            // {isPreview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: defaultQuoteSettings?.footer_text?.replaceAll(
                  '\n',
                  '<br/>',
                ),
              }}
              className="italic text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16 "
              name="invoiceFooter"
              placeholder={`${value} footer`}
              size="sm"
              value={footerNotes}
              onChange={(e: any) => setFooterNotes(e.target.value)}
            />
          )}
        </div>
        <QuoteOverviewModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          quoteVersion={quoteVersionDetail?.quote_details?.version}
        />
      </div>
    </>
  )
}
