import React, {useCallback, useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {BsCloudDownload} from 'react-icons/bs'
import {useFormInput} from 'use-form-input'
import {saveAs} from 'file-saver'

import {DataTable, DescriptionBox, DynamicSearchSelect} from 'app/components'
import {DefaultButton} from 'app/common'

import {getProjectPurchaseOrder, downloadPdfAction} from 'redux-src'
import {
  generateMaterialPurchaseHTMLString,
  generatePurchaseHTMLString,
} from 'helpers/generateHtmlString.helper'
import {MaterialPurchaseList} from './components'

const columns = [
  {
    name: 'Name',
    selector: (row: any) => row?.name,
    sortable: true,
    cell: (row: any) => (
      <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
        {row?.name}
      </span>
    ),
  },
  {
    name: 'Quantity',
    selector: (row: any) => row?.quantity,
    sortable: true,
    cell: (row: any) => (
      <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
        {row?.quantity}
      </span>
    ),
  },
  {
    name: 'Supplier',
    selector: (row: any) => row?.supplierName,
    sortable: true,
    cell: (row: any) => (
      <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
        {row?.supplierName}
      </span>
    ),
  },
]

export const QuotePurchaseOrderPage = () => {
  const {
    projectPurchaseOrder,
    projectDetail,
    getProjectPurchaseOrderLoading,
    projectPurchaseOrderTotal,
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  const {materialPurchaseOrder, materialPurchaseOrderTotal}: RT.QuoteReduxType =
    useSelector((state: any) => state.quotes)

  const {downloadPdfLoading: downloadLoading} = useSelector(
    (state: any) => state.file,
  )

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const [allPurchaseOrders, setAllPurchaseOrders] = useState<
    {
      id: number
      name: string
      supplierName: string
      quantity: number
    }[]
  >([])

  const [activeButton, setActiveButton] = useState<
    Array<{id: number; isActive: boolean}>
  >([
    {id: 1, isActive: false},
    {id: 2, isActive: false},
  ])

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const [data, {setValue}] = useFormInput({
    supplierId: undefined,
    materialSupplierId: undefined,
  })

  const remappedAllProjectPurchaseList = useCallback(() => {
    const remappedProjectPurchaseList = projectPurchaseOrder?.map(
      (item, index) => {
        return {
          id: item?.purchase_order_details?.id,
          name: item?.purchase_order_details?.name,
          supplierName: item?.supplier_details?.name,
          quantity: item?.purchase_order_details?.quantity,
        }
      },
    )

    setAllPurchaseOrders(remappedProjectPurchaseList)
  }, [projectPurchaseOrder])

  useEffect(() => {
    dispatch(
      getProjectPurchaseOrder(1, 10, projectId, data?.supplierId?.id, () => {}),
    )
  }, [dispatch, projectId, data?.supplierId])

  useEffect(() => {
    remappedAllProjectPurchaseList()
  }, [remappedAllProjectPurchaseList])

  const generatePurchaseOrderPdf = () => {
    const activeBtn = activeButton?.map((itm) => {
      if (itm?.id === 1) {
        return {...itm, isActive: true}
      } else {
        return {...itm, isActive: false}
      }
    })

    setActiveButton(activeBtn)

    const fileName = `${projectDetail?.project_details?.project_prefix}-${
      projectDetail?.project_details?.project_number
    }-PO${
      !!data?.supplierId && data?.supplierId?.value !== 'All'
        ? `-${data?.supplierId?.label}`
        : ''
    }`

    dispatch(
      downloadPdfAction(
        {
          html: generatePurchaseHTMLString(
            companyDetails,
            projectDetail,
            projectPurchaseOrder,
            data?.supplierId,
          ),
        },
        (data) => {
          saveAs(data, fileName)
        },
      ),
    )
  }

  const generateMaterialPurchaseOrderPdf = () => {
    const activeBtn = activeButton?.map((itm) => {
      if (itm?.id === 2) {
        return {...itm, isActive: true}
      } else {
        return {...itm, isActive: false}
      }
    })

    setActiveButton(activeBtn)

    dispatch(
      downloadPdfAction(
        {
          html: generateMaterialPurchaseHTMLString(
            companyDetails,
            projectDetail,
            materialPurchaseOrder,
            data?.materialSupplierId,
          ),
        },
        (data) => {
          saveAs(
            data,
            `${projectDetail?.project_details?.project_prefix}-${
              projectDetail?.project_details?.project_number
            }-PO${
              !!data?.materialSupplierId &&
              data?.materialSupplierId?.value !== 'All'
                ? `-${data?.materialSupplierId?.label}`
                : ''
            }`,
          )
        },
      ),
    )
  }

  return (
    <div className="flex flex-col gap-20">
      <DescriptionBox
        title="Item Purchase Orders"
        bodyClassName="p-16"
        rightElement={
          <div className="flex flex-row justify-center items-center gap-8">
            <div className="w-[200px]">
              <DynamicSearchSelect
                actionUrl={`/projects/${projectId}/purchase-orders/suppliers`}
                options={[
                  {
                    id: 0,
                    label: 'All Suppliers',
                    value: 'All',
                  },
                ]}
                remapOptions={(data: Api.SupplierType[]) => {
                  return data?.map((item: any) => {
                    return {
                      id: item?.id,
                      label: item?.name,
                      value: item?.name,
                    }
                  })
                }}
                onChangeValue={(value) => {
                  setValue('supplierId', value)
                }}
                placeholder={'Select Supplier'}
              />
            </div>

            {projectPurchaseOrderTotal > 0 && (
              <DefaultButton
                title="Download"
                leftIcon={<BsCloudDownload />}
                className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                onClick={generatePurchaseOrderPdf}
                loading={activeButton?.at(0).isActive && downloadLoading}
              />
            )}
          </div>
        }
      >
        <div className="my-5 flex flex-col gap-10">
          <DataTable
            columns={columns}
            data={allPurchaseOrders ?? []}
            pagination
            totalRows={projectPurchaseOrderTotal}
            loading={getProjectPurchaseOrderLoading}
          />
        </div>
      </DescriptionBox>

      <DescriptionBox
        title="Material Purchase Orders"
        bodyClassName="p-16"
        rightElement={
          <div className="flex flex-row justify-center items-center gap-8">
            <div className="w-[200px]">
              <DynamicSearchSelect
                actionUrl={`/projects/${projectId}/purchase-orders/suppliers`}
                options={[
                  {
                    id: 0,
                    label: 'All Suppliers',
                    value: 'All',
                  },
                ]}
                remapOptions={(data: Api.SupplierType[]) => {
                  return data?.map((item: any) => {
                    return {
                      id: item?.id,
                      label: item?.name,
                      value: item?.name,
                    }
                  })
                }}
                onChangeValue={(value) => {
                  setValue('materialSupplierId', value)
                }}
                placeholder={'Select Supplier'}
              />
            </div>

            {materialPurchaseOrderTotal > 0 && (
              <DefaultButton
                title="Download"
                leftIcon={<BsCloudDownload />}
                className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                onClick={generateMaterialPurchaseOrderPdf}
                loading={activeButton?.at(1).isActive && downloadLoading}
              />
            )}
          </div>
        }
      >
        <div className="my-5 flex flex-col gap-10">
          <MaterialPurchaseList supplierId={data?.materialSupplierId?.id} />
        </div>
      </DescriptionBox>
    </div>
  )
}
