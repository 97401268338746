import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  FaComments,
  FaEnvelope,
  FaFax,
  FaGlobe,
  FaPen,
  FaPhone,
  FaTimes,
  FaUser,
} from 'react-icons/fa'
import {
  HiLocationMarker,
  HiOutlineRefresh,
  HiPlusSm,
  HiSearch,
} from 'react-icons/hi'

import {ToolTip} from 'app/common'
import {
  DynamicSearchSelect,
  NewContactModal,
  NotesSummary,
} from 'app/components'
import {useDebounceValue} from 'hooks'
import {getAllInfoContacts, getCustomer, getCustomerById} from 'redux-src'
import {CustomerModal} from '../../../customers/components'
import {CustomerOptionsType} from '../../../customers/customers.type'
import {useQuery} from 'react-auth-navigation'

export const CustomerDetails = ({
  customerProps: {customerDetails, setCustomerDetails},
}: any) => {
  const dispatch = useDispatch()
  const query: any = useQuery()
  const {customers}: RT.CustomerReduxType = useSelector(
    (state: any) => state.customer,
  )

  const [newCustomerModal, setNewCustomerModal] = useState(false)
  const [activeEditMode, setActiveEditMode] = useState<boolean>(false)

  const [customerList, setCustomerList] = useState<CustomerOptionsType>() //   const [selectCustomer, setSelectedCustomer] = useState(null)
  const [hideAddCustomerSection, setCustomerSection] = useState(false)
  const [changeMainContact, setChangeMainContact] = useState(false)
  const [customerData, setCustomerData] = useState<Api.CustomerById>()

  const [contactList, setContactList] = useState<CustomerOptionsType>()

  const [customerContacts, setCustomerContacts] = useState<Api.ContactType>()
  const [customerContactLists, setCustomerContactLists] =
    useState<Api.ContactType[]>()

  const [filterData, setFilterData] = useState('')
  const [contactFilterData, setContactFilterData] = useState('')

  const debouncedFilterData = useDebounceValue(filterData)
  const debouncedContactFilter = useDebounceValue(contactFilterData)

  const [addNewContactModal, setNewContactModal] = useState(false)

  const customerRemapper = useCallback(
    (filterValue) => {
      const _temp: CustomerOptionsType = []
      customers?.map((item) => {
        if (
          item.customer_details?.name
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase())
        ) {
          return _temp.push({
            key: item.customer_details?.id,
            name: item.customer_details?.name,
            value: item.default_contact_details?.firstname,
          })
        }
        return null
      })
      setCustomerList(_temp)
    },
    [customers],
  )

  const contactRemapper = useCallback(
    (filterValue) => {
      if (customerData) {
        dispatch(
          getAllInfoContacts(
            customerData?.customer_details?.id,
            customerData?.info_id,
            (data) => {
              setCustomerContactLists(data.map((item) => item.contact_details))
              setContactList(
                data
                  .map(({contact_details: cd}) => ({
                    key: cd?.id,
                    name: cd?.phones ? cd?.phones[0] : null,
                    value: cd?.firstname,
                  }))
                  .filter((item) =>
                    item?.value
                      ?.toLowerCase()
                      .includes(filterValue?.toLowerCase()),
                  ),
              )
            },
          ),
        )
      }
    },
    [customerData, dispatch],
  )

  const onAddNewReason = () => {
    setNewCustomerModal(true)
    setActiveEditMode(false)
  }

  const onAddNewContact = () => {
    setNewContactModal(true)
  }

  // useEffect(() => {
  //   customerRemapper(debouncedFilterData)
  //   contactRemapper(debouncedContactFilter)
  // }, [
  //   contactRemapper,
  //   customerRemapper,
  //   debouncedContactFilter,
  //   debouncedFilterData,
  // ])

  useEffect(() => {
    contactRemapper(debouncedContactFilter)
  }, [contactRemapper, debouncedContactFilter])

  useEffect(() => {
    customerRemapper(debouncedFilterData)
  }, [customerRemapper, debouncedFilterData])

  const setCustomerValue = (id: number) => {
    dispatch(
      getCustomerById(id, (customerValue) => {
        setCustomerData(customerValue)
        setCustomerContacts(customerValue?.default_contact_details)
        setCustomerSection(true)
        setCustomerDetails(customerValue)
      }),
    )
  }

  const handleSelectContact = (item: any) => {}

  useEffect(() => {
    if (query.customerId) {
      setCustomerValue(query.customerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.customerId])

  return (
    <div className="flex bg-white">
      <div className="flex-1 w-full px-16 ">
        <div className="text-xl border-b-1  border-gray-200  pt-20 pb-4 text-gray-600 font-600">
          Enter customer details
        </div>
        <div className="border-1 border-gray-200 mt-1 relative rounded-md shadow-sm flex flex-wrap pt-16 pb-16 my-16">
          <div className="px-30 justify-center items-center text-blue-300 flex border-r-1 border-gray-200">
            <div className="flex-nowrap">
              <FaUser size="50" className="mx-auto" />
              <p className="text-center">Customer</p>
            </div>
          </div>
          {!hideAddCustomerSection && (
            <div className="mx-16 flex-1 ">
              <h1 className="font-bold text-xl">Customer</h1>
              <div className="flex justify-center items-center w-full  ">
                <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
                  <HiSearch size={18} />
                </div>
                <DynamicSearchSelect
                  actionUrl="/customers"
                  placeholder="Search for an existing customer"
                  remapOptions={(data: Api.CustomerIndividual[]) => {
                    return data?.map(
                      ({
                        customer_details: cd,
                        default_contact_details: dc,
                        info_id: info,
                      }) => {
                        return {
                          id: cd?.id,
                          label: [cd?.name, dc?.firstname].join(' - '),
                          value: info,
                        }
                      },
                    )
                  }}
                  customOption={
                    <div
                      onClick={onAddNewReason}
                      className="flex flex-nowrap justify-start items-center gap-8 py-6 px-10 cursor-pointer border-b-1 border-gray-200 hover:bg-blue-300 hover:text-white transition-all duration-200 ease-linear"
                    >
                      <HiPlusSm size="20" />
                      New Customer
                    </div>
                  }
                  onChangeValue={(value) => {
                    setCustomerValue(value.id)
                  }}
                  cacheOptions
                />
              </div>
              <div className="flex justify-center items-center w-full  ">
                <button
                  className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                  onClick={() => onAddNewReason()}
                >
                  {' '}
                  <span className="flex flex-nowrap justify-center items-center gap-4 py-4 text-gray-400">
                    <HiPlusSm size="20" />
                    Add New Customer
                  </span>
                </button>
              </div>
            </div>
          )}
          {hideAddCustomerSection && customerData !== null ? (
            <div className="mx-16 flex-1 items-center lg:w-12/12">
              <div className="w-full lg:w-12/12 px-2">
                <div className="-mx-16">
                  <div className="">
                    <div className="flex items-center justify-between  font-bold  px-12 border-gray-200 mb-20">
                      <span className="px-4 text-blue-300 hover:text-blue-400 cursor-pointer font-bold">
                        {customerData?.customer_details?.name}
                      </span>
                      <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100">
                        {/* <BootstrapTooltip title="Quick Email"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickEmail}
                          >
                            <FaEnvelope size={12} />
                          </button>
                        </div>
                        <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Quick SMS"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickSms}
                          >
                            <FaComments size={12} />
                          </button>
                        </div>
                        <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Map"> */}
                        {/* <div>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            //   onClick={handleQuickMap}
                          >
                            <HiLocationMarker size={12} />
                          </button>
                        </div> */}
                        {/* <div className="border-r-1 border-gray-200" /> */}
                        {/* <BootstrapTooltip title="Edit this contact"> */}
                        <ToolTip text="Edit" top>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            onClick={() => {
                              setNewCustomerModal(true)
                              setActiveEditMode(true)
                            }}
                          >
                            <FaPen size={12} />
                          </button>
                        </ToolTip>
                        <div className="border-r-1 border-gray-200" />
                        {/* <BootstrapTooltip title="Change customer contact"> */}
                        <ToolTip text="Cancel" top>
                          <button
                            className="p-6 hover:bg-gray-200"
                            type="button"
                            onClick={() => {
                              setCustomerSection(false)
                              setCustomerDetails(null)
                            }}
                          >
                            <FaTimes size={12} />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-2">
                  <div className="-mx-16 px-22 pb-10">
                    <span className="inline-flex text-gray-300">
                      <HiLocationMarker
                        className="text-gray-300 mx-10 w-20"
                        size={16}
                      />
                      <p>{customerData.postal_address_details?.address} </p>
                    </span>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 px-2">
                  <div className="flex items-center justify-between py-8">
                    <h3
                      className="text-gray-500"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Main Contact
                    </h3>
                    <div className="md-10">
                      {changeMainContact === false && (
                        <div className="border-1 border-gray-200 rounded-md shadow-sm flex bg-gray-100">
                          {/* <BootstrapTooltip title="Edit job main contact">  */}
                          <ToolTip text="Edit" top>
                            <button
                              className="p-6 hover:bg-gray-200"
                              type="button"
                              onClick={() => {
                                setNewCustomerModal(true)
                                setActiveEditMode(true)
                                //         customerContacts  &&
                                // customerContacts.contactTypeId === 2 ?
                                // handleQuickEdit()
                                //   : openEditContactModal(customerContacts)
                              }}
                            >
                              <FaPen className="border-gray-200" size={11} />
                            </button>
                          </ToolTip>
                          <div className="border-r-1 border-gray-200" />
                          <ToolTip text="Cancel" top>
                            <button
                              className="p-6 hover:bg-gray-200"
                              type="button"
                              onClick={() => setChangeMainContact(true)}
                            >
                              <FaTimes className="border-gray-200" size={12} />
                            </button>
                          </ToolTip>
                        </div>
                      )}
                    </div>
                  </div>
                  {changeMainContact === false ? (
                    <>
                      <div className="mx-4">
                        <span className="inline-flex text-gray-300">
                          <FaUser
                            className="text-gray-300 mx-10 w-20"
                            size={16}
                          />{' '}
                          {customerContacts && customerContacts.firstname}{' '}
                          {customerContacts && customerContacts.lastname} (
                          {customerContacts && customerContacts.position})
                        </span>
                      </div>
                      {customerContacts && (
                        <div>
                          <span className="flex mx-4 text-gray-300 flex-col">
                            {customerContacts.emails !== null &&
                              customerContacts.emails.length !== 0 && (
                                <span className="inline-flex">
                                  <FaEnvelope
                                    className="mx-10 w-20"
                                    size={16}
                                  />{' '}
                                  {customerContacts.emails[0]}
                                </span>
                              )}
                            {customerContacts.phones !== null &&
                              customerContacts.phones.length !== 0 && (
                                <span className="inline-flex">
                                  <FaPhone className="mx-10 w-20" size={16} />
                                  {customerContacts.phones[0]}
                                </span>
                              )}
                            {customerContacts.fax !== null &&
                              customerContacts.fax.length !== 0 && (
                                <span className="inline-flex">
                                  <FaFax className="mx-10 w-20" size={16} />
                                  {customerContacts.fax[0]}
                                </span>
                              )}
                            {customerContacts.websites !== null &&
                              customerContacts.websites.length !== 0 && (
                                <span className="inline-flex">
                                  <FaGlobe className="mx-10 w-20" size={16} />
                                  {customerContacts.websites[0]}
                                </span>
                              )}
                          </span>
                        </div>
                      )}{' '}
                    </>
                  ) : (
                    <div className="mb-4">
                      <div className="flex justify-center items-center w-full lg:w-12/12 md:w-12/12 px-16 sm:w-12/12 ">
                        <button
                          className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                          onClick={() => setChangeMainContact(false)}
                        >
                          {' '}
                          <span className="inline-flex text-gray-400 mt-4">
                            <HiOutlineRefresh className="mr-4" size={18} />{' '}
                            Customer Default Main Contact
                          </span>
                        </button>
                      </div>
                      <div className="flex justify-center items-center w-full lg:w-12/12 md:w-12/12 px-16 sm:w-12/12 ">
                        <button
                          className="my-10 w-full h-12 px-6 border-1 border-gray-200 transition-colors duration-150 bg-gray-100 shadow-sm rounded-sm focus:shadow-outline hover:bg-gray-200"
                          onClick={() => onAddNewContact()}
                        >
                          {' '}
                          <span className="inline-flex text-gray-400 mt-4">
                            <HiPlusSm size={18} />
                            New Contact
                          </span>
                        </button>
                      </div>
                      <div className="flex justify-center items-center w-full px-16 ">
                        {/* Dynamic select  contacts list */}
                        <DynamicSearchSelect
                          placeholder={'All contact list'}
                          actionUrl={`/customers/${customerData?.customer_details?.id}/info/${customerData?.info_id}/all-contacts`}
                          remapOptions={(data: Api.InfoContact[]) => {
                            const remappedItem = data?.map((item) => {
                              return {
                                id: item?.contact_details?.id,
                                label: item?.contact_details?.firstname,
                                value: item?.contact_details?.firstname,
                              }
                            })
                            return remappedItem
                          }}
                          onChangeValue={handleSelectContact}
                        />

                        <div>
                          <button
                            className="over:bg-gray-200 border-1 border-gray-200 px-10 py-4 shadow-sm rounded-sm mx-4 ml-10 mt-6"
                            onClick={() => setChangeMainContact(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {customerData && (
          <div className="mb-16">
            <NotesSummary
              type="customer"
              hostId={customerData?.customer_details?.id}
            />
          </div>
        )}
      </div>

      <CustomerModal
        newCustomerModal={newCustomerModal}
        setNewCustomerModal={setNewCustomerModal}
        customerAddCallback={() => dispatch(getCustomer())}
        activeCustomer={customerData}
        isEdit={activeEditMode}
      />

      <NewContactModal
        addNewContactModal={addNewContactModal}
        setNewContactModal={setNewContactModal}
      />

      {/* 
        <Modal
          isActive={editNewContactModal}
          size="xl"
          toggleModal={() => setNewEditContactModal(false)}
          title="Edit Contact"
        >
          <>
            <ContactEditForm
              CustomerContactForEdit={customerContactForUpdate}
              handleCloseEditContact={handelEditNewContact}
              reloadCallback={reloadCallback}
            />
          </>
    </Modal> 
      </div>
    */}
    </div>
  )
}
