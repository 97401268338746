import {useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {FaSearch} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import colors from 'tailwindcss/colors'
import moment from 'moment'

import {Button, Input} from 'app/common'
import {getCompanyUserGroupList} from 'redux-src'
import {SettingsHeader} from '../../../../components'
import {GroupModal, GroupTable} from './../../components'
import {useDebounceValue} from 'hooks'

export const MainGroupPage = () => {
  const dispatch = useDispatch()

  const {companyUserGroupList}: RT.UserReduxType = useSelector(
    (state: any) => state.user,
  )

  const {
    navigation: {navigate, routes},
  } = useNavigation()

  //* FORM STATES
  const [filterData, setFilterData] = useState<string>()
  const debounceFilterValue = useDebounceValue(filterData)

  const [newGroupModal, setNewGroupModal] = useState(false)

  const onEditGroup = (id: number) => {
    navigate(routes['Groups'].path + '/edit-group/' + id)
  }

  const newUserGroupList = useMemo(() => {
    const finalUserGroupList = companyUserGroupList?.map((group) => {
      return {
        id: group?.group_details?.id,
        name: group?.group_details?.name,
        dateCreated: moment(group?.group_details?.created_at),
        usersCount: group?.group_users?.length ?? 0,
        colorCode: group?.group_details?.color,
      }
    })

    return finalUserGroupList
  }, [companyUserGroupList])

  useEffect(() => {
    dispatch(getCompanyUserGroupList({search: debounceFilterValue}))
  }, [dispatch, debounceFilterValue])

  return (
    <div className="w-12/12">
      <div className="flex bg-white">
        <div className="flex-1 w-full h-[91vh]">
          <SettingsHeader
            title="Groups"
            subHeader="The Group list allows you to organise your users into different
            groups to help you better manage business units."
          />

          <div className="flex items-center justify-between py-10">
            <Input
              name="filterValue"
              type="text"
              // size="sm"
              prepend={<FaSearch size={16} color={colors.gray[500]} />}
              containerClass="w-[300px]"
              placeholder="Search Group By Name "
              className="w-full text-sm"
              value={filterData}
              onChange={(e) => setFilterData(e.target.value)}
            />

            <Button
              className=""
              size="sm"
              title="Add new group"
              onClick={() => setNewGroupModal(true)}
            />
          </div>
          <div className="border-b-1 border-gray-200 my-10 mb-24" />
          <GroupTable
            onEditGroup={onEditGroup}
            groups={newUserGroupList}
            search={debounceFilterValue}
          />

          <GroupModal
            newGroupModal={newGroupModal}
            setNewGroupModal={setNewGroupModal}
          />
        </div>
      </div>
    </div>
  )
}
