import React, {useContext, useEffect, useState, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface ProgressBarProps {
  id: number
  onSelectElement: (id: number) => void
}

interface ProgressType {
  label: string
  description: string
  value: string
}

export const ProgressBar = ({id, onSelectElement}: ProgressBarProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [currentProgress, setCurrentProgress] = useState<ProgressType>({
    label: individualElement?.content?.label || '',
    description: individualElement?.content?.description || '',
    value: (individualElement?.content?.value as string) || '0',
  })

  const dispatch = useDispatch()
  const progressBarRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(alterFormContent(id, currentProgress, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress])

  const handleProgressChange = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    if (!progressBarRef.current) return

    const progressBar = progressBarRef.current
    const rect = progressBar.getBoundingClientRect()
    const clientX =
      'touches' in e
        ? (e as React.TouchEvent).touches[0].clientX
        : (e as React.MouseEvent).clientX

    const percentage = Math.max(
      0,
      Math.min(100, ((clientX - rect.left) / rect.width) * 100),
    )

    setCurrentProgress((prev) => ({
      ...prev,
      value: percentage.toFixed(0),
    }))
  }

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
        ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="py-8 flex gap-4">
          <input
            type="text"
            name="label"
            value={currentProgress?.label}
            onChange={(e: any) => {
              setCurrentProgress((prev) => ({...prev, label: e.target.value}))
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />

          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {/* Sliding Progress Bar */}
      <div
        ref={progressBarRef}
        className="w-full h-10 bg-gray-200 relative cursor-pointer rounded-sm"
        onMouseDown={handleProgressChange}
        onTouchStart={handleProgressChange}
        onMouseMove={(e) => e.buttons === 1 && handleProgressChange(e)}
        onTouchMove={handleProgressChange}
      >
        <div
          ref={thumbRef}
          className="absolute top-0 bottom-0 w-6 bg-blue-500 transition-all duration-200"
          style={{
            left: `calc(${currentProgress.value}% - 5px)`,
          }}
        />
        <span className="text-transparent">Progress Bar</span>
        <div
          className="absolute top-0 bottom-0 bg-blue-300 opacity-50"
          style={{
            width: `${currentProgress.value}%`,
          }}
        />
      </div>

      <div className="flex flex-col mx-12 p-2 py-6">
        <div className="text-sm text-gray-600">
          Progress: {currentProgress.value}%
        </div>
      </div>
    </div>
  )
}
