import {AiOutlineUnorderedList} from 'react-icons/ai'
import {ImPageBreak, ImRadioChecked, ImTextColor} from 'react-icons/im'
import {CiCalendarDate} from 'react-icons/ci'
import {BiLink, BiTimeFive} from 'react-icons/bi'
import {BsImage, BsImages, BsTextLeft, BsTextParagraph} from 'react-icons/bs'
import {TbNumbers} from 'react-icons/tb'
import {RxDividerHorizontal} from 'react-icons/rx'
import {MdSpaceBar} from 'react-icons/md'
import {IoCheckboxOutline} from 'react-icons/io5'
import {FaFileSignature, FaVideo} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {SiStatuspal} from 'react-icons/si'
import {GiProgression} from 'react-icons/gi'

enum FormBuilderType {
  heading = 'heading',
  section = 'section',
  paragraph = 'paragraph',
  divider = 'divider',
  'page break' = 'page break',
  'blank space' = 'blank space',
  hyperlink = 'hyperlink',
  'multiple choice' = 'multiple choice',
  'single choice' = 'single choice',
  'date input' = 'date input',
  'time input' = 'time input',
  'number input' = 'number input',
  'short text' = 'short text',
  'long text' = 'long text',
  'image' = 'image',
  'photo' = 'photo',
  'signature' = 'signature',
  'video' = 'video',
  'project user' = 'project user',
  'project status' = 'project status',
  'progressbar' = 'progressbar',
}

export const defaultInstructionsElements = [
  {id: 1, formType: 'heading', heading: 'Heading', icon: <ImTextColor />},
  {
    id: 2,
    formType: 'section',
    heading: 'Section',
    icon: <AiOutlineUnorderedList />,
  },
  {
    id: 3,
    formType: 'paragraph',
    heading: 'Paragraph',
    icon: <BsTextParagraph />,
  },
  {
    id: 4,
    formType: 'divider',
    heading: 'Divider',
    icon: <RxDividerHorizontal />,
  },
  {id: 5, formType: 'page break', heading: 'Page Break', icon: <ImPageBreak />},
  {
    id: 6,
    formType: 'blank space',
    heading: 'Blank Space',
    icon: <MdSpaceBar />,
  },
  {id: 7, formType: 'hyperlink', heading: 'Hyperlink', icon: <BiLink />},

  {
    id: 8,
    formType: 'image',
    heading: 'Image',
    icon: <BsImage />,
  },
]

export const defaultDataEntryElements = [
  {
    id: 1,
    formType: 'multiple choice',
    heading: 'Multiple Choice',
    icon: <IoCheckboxOutline />,
  },
  {
    id: 2,
    formType: 'single choice',
    heading: 'Single Choice',
    icon: <ImRadioChecked />,
  },
  {
    id: 3,
    formType: 'date input',
    heading: 'Date Input',
    icon: <CiCalendarDate />,
  },
  {
    id: 4,
    formType: 'time input',
    heading: 'Time Input',
    icon: <BiTimeFive />,
  },
  {
    id: 5,
    formType: 'number input',
    heading: 'Number Input',
    icon: <TbNumbers />,
  },
  {
    id: 6,
    formType: 'short text',
    heading: 'Short Text',
    icon: <BsTextLeft />,
  },
  {id: 7, formType: 'long text', heading: 'Long Text', icon: <BsTextLeft />},
  {
    id: 8,
    formType: 'photo',
    heading: 'Photo',
    icon: <BsImages />,
  },
  {
    id: 9,
    formType: 'signature',
    heading: 'Signature',
    icon: <FaFileSignature />,
  },
  {
    id: 10,
    formType: 'video',
    heading: 'Video',
    icon: <FaVideo />,
  },
  {
    id: 11,
    formType: 'project user',
    heading: 'Projejct User',
    icon: <FiUsers />,
  },
  {
    id: 12,
    formType: 'project status',
    heading: 'Projejct Status',
    icon: <SiStatuspal />,
  },
  {
    id: 13,
    formType: 'progressbar',
    heading: 'ProgressBar',
    icon: <GiProgression />,
  },
]

export const formElementsConstants: Array<{
  data_type: RT.FormBuilderType
  behaviour: RT.FormElementType
}> = [
  {
    data_type: FormBuilderType['heading'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 4,
        maxW: 6,
      },
      position: 0,
      id: 33215085,
      formType: FormBuilderType['heading'],
      content: {label: '', description: ''},
      settings: {hideDescription: true},
      size: 'large',
      alignment: 'left',
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['section'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 3,
        maxW: 6,
      },
      position: 0,
      id: 75703857,
      formType: FormBuilderType['section'],
      content: {label: ''},
      settings: {numbered: false},
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['date input'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 79105727,
      formType: FormBuilderType['date input'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['time input'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 57773406,
      formType: FormBuilderType['time input'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['short text'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 61159166,
      formType: FormBuilderType['short text'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['long text'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 9,
        maxW: 6,
      },
      position: 0,
      id: 56242615,
      formType: FormBuilderType['long text'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['number input'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 81783291,
      formType: FormBuilderType['number input'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['paragraph'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 9,
        maxW: 6,
      },
      position: 0,
      id: 29684437,
      formType: FormBuilderType['paragraph'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
      },
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['divider'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 4,
        maxW: 6,
      },
      position: 0,
      id: 18090947,
      formType: FormBuilderType['divider'],
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['page break'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 12279718,
      formType: FormBuilderType['page break'],
      noSettingMessage: 'Page Break does not have any settings.',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['blank space'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 40019563,
      formType: FormBuilderType['blank space'],
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['hyperlink'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 43553722,
      formType: FormBuilderType['hyperlink'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
      },
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['multiple choice'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 71646317,
      formType: FormBuilderType['multiple choice'],
      content: {
        label: '',
        description: '',
        value: [{id: 1, text: '', isChecked: false}],
      },
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['single choice'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 5,
        maxW: 6,
      },
      position: 0,
      id: 37026888,
      formType: FormBuilderType['single choice'],
      content: {
        label: '',
        description: '',
        value: [{id: 1, text: '', isChecked: false}],
      },
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['image'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 15,
        maxW: 6,
      },
      position: 0,
      id: 37026820,
      formType: FormBuilderType['image'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
      },
      elementSize: '100%',
      elementType: 'instructional',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['photo'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 7,
        maxW: 6,
      },
      position: 0,
      id: 37026821,
      formType: FormBuilderType['photo'],
      content: {label: '', description: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['signature'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 7,
        maxW: 6,
      },
      position: 0,
      id: 37026822,
      formType: FormBuilderType['signature'],
      content: {label: '', description: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
        allowClearSignature: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['video'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 7,
        maxW: 6,
      },
      position: 0,
      id: 37026823,
      formType: FormBuilderType['video'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['project user'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 37023422,
      formType: FormBuilderType['project user'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['project status'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 37054322,
      formType: FormBuilderType['project status'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideDescription: true,
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
  {
    data_type: FormBuilderType['progressbar'],
    behaviour: {
      dataTypeId: 0,
      layout: {
        i: '0',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        maxW: 6,
      },
      position: 0,
      id: 37033982,
      formType: FormBuilderType['progressbar'],
      content: {label: '', description: '', value: ''},
      settings: {
        hideLabel: false,
        markAsRequired: false,
      },
      elementSize: '100%',
      elementType: 'data-entry',
      visibleTo: ['System Admin'],
      editableBy: ['System Admin'],
    },
  },
]

export const field_data_type = [
  {
    id: 111,
    created_at: '2023-06-25T10:44:54.362Z',
    updated_at: '2023-06-25T10:44:54.362Z',
    data_type: 'heading',
    behaviour: {
      id: 33215085,
      size: 'large',
      order: 0,
      layout: {
        h: 4,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
      },
      formType: 'heading',
      settings: {
        hideDescription: true,
      },
      alignment: 'left',
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 112,
    created_at: '2023-06-25T10:44:54.364Z',
    updated_at: '2023-06-25T10:44:54.364Z',
    data_type: 'section',
    behaviour: {
      id: 75703857,
      order: 0,
      layout: {
        h: 3,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
      },
      formType: 'section',
      settings: {
        numbered: false,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementType: 'instructional',
    },
  },
  {
    id: 113,
    created_at: '2023-06-25T10:44:54.364Z',
    updated_at: '2023-06-25T10:44:54.364Z',
    data_type: 'time input',
    behaviour: {
      id: 57773406,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'time input',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 114,
    created_at: '2023-06-25T10:44:54.364Z',
    updated_at: '2023-06-25T10:44:54.364Z',
    data_type: 'date input',
    behaviour: {
      id: 79105727,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'date input',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 115,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'short text',
    behaviour: {
      id: 61159166,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'short text',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 116,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'paragraph',
    behaviour: {
      id: 29684437,
      order: 0,
      layout: {
        h: 9,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'paragraph',
      settings: {
        hideLabel: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 117,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'long text',
    behaviour: {
      id: 56242615,
      order: 0,
      layout: {
        h: 9,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'long text',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 118,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'number input',
    behaviour: {
      id: 81783291,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'number input',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 119,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'divider',
    behaviour: {
      id: 18090947,
      order: 0,
      layout: {
        h: 4,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      formType: 'divider',
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 120,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'page break',
    behaviour: {
      id: 12279718,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      formType: 'page break',
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementType: 'instructional',
      noSettingMessage: 'Page Break does not have any settings.',
    },
  },
  {
    id: 121,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'blank space',
    behaviour: {
      id: 40019563,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      formType: 'blank space',
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 122,
    created_at: '2023-06-25T10:44:54.365Z',
    updated_at: '2023-06-25T10:44:54.365Z',
    data_type: 'hyperlink',
    behaviour: {
      id: 43553722,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'hyperlink',
      settings: {
        hideLabel: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 123,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'multiple choice',
    behaviour: {
      id: 71646317,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: [
          {
            id: 1,
            text: '',
          },
        ],
        description: '',
      },
      formType: 'multiple choice',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 124,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'single choice',
    behaviour: {
      id: 37026888,
      order: 0,
      layout: {
        h: 5,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: [
          {
            id: 1,
            text: '',
          },
        ],
        description: '',
      },
      formType: 'single choice',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 125,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'image',
    behaviour: {
      id: 37026820,
      order: 0,
      layout: {
        h: 15,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'image',
      settings: {
        hideLabel: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'instructional',
    },
  },
  {
    id: 126,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'photo',
    behaviour: {
      id: 37026821,
      order: 0,
      layout: {
        h: 7,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
      },
      formType: 'photo',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
        multipleUpload: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 128,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'video',
    behaviour: {
      id: 37026823,
      order: 0,
      layout: {
        h: 7,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        value: '',
        description: '',
      },
      formType: 'video',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
        multipleUpload: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 127,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'signature',
    behaviour: {
      id: 37026822,
      order: 0,
      layout: {
        h: 7,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
      },
      formType: 'signature',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
        allowClearSignature: false,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 129,
    created_at: '2024-11-26T12:00:00.000Z',
    updated_at: '2024-11-26T12:00:00.000Z',
    data_type: 'project user',
    behaviour: {
      id: 37023422,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
        value: '',
      },
      formType: 'project user',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 130,
    created_at: '2024-11-26T12:00:00.000Z',
    updated_at: '2024-11-26T12:00:00.000Z',
    data_type: 'project status',
    behaviour: {
      id: 37054322,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
        value: '',
      },
      formType: 'project status',
      settings: {
        hideLabel: false,
        markAsRequired: false,
        hideDescription: true,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
  {
    id: 131,
    created_at: '2023-06-25T10:44:54.366Z',
    updated_at: '2023-06-25T10:44:54.366Z',
    data_type: 'progressbar',
    behaviour: {
      id: 37033982,
      order: 0,
      layout: {
        h: 6,
        i: '0',
        w: 6,
        x: 0,
        y: 0,
        maxW: 6,
      },
      content: {
        label: '',
        description: '',
        value: '',
      },
      formType: 'progressbar',
      settings: {
        hideLabel: false,
        markAsRequired: false,
      },
      visibleTo: ['System Admin'],
      dataTypeId: 0,
      editableBy: ['System Admin'],
      elementSize: '100%',
      elementType: 'data-entry',
    },
  },
]
