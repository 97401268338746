import {Input, Modal} from 'app/common'
import {DataTable} from 'app/components'
import {ProjectTicketList} from 'app/pages/jobs/pages/ticket'
import {getStatusChip} from 'app/pages/jobs/pages/ticket/components/planList/components'
import {useDebounceValue} from 'hooks'
import {useEffect, useMemo, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {
  getArchivedProjectListForDashboard,
  getProjectTicketList,
  RootState,
} from 'redux-src'

export const ArchivedProjectTab = () => {
  const {
    archivedProjectsListForDashboard,
    isArchivedProjectsListForDashboardLoading,
  }: RT.CompanyReduxType = useSelector((state: RootState) => state.company)

  const [page, setPage] = useState<number>(1)
  const dispatch = useDispatch()
  const [search, setSearch] = useState<string>()

  const searchDebounce = useDebounceValue(search)

  const [showTicketModal, setShowTicketModal] = useState(false)
  const [ticketModalTitleText, setTicketModalTitleText] = useState('')
  const [ticketModalStatus, setTicketModalStatus] = useState('')
  const [ticketModalProjectId, setTicketModalProjectId] = useState<number>()

  const columns = useMemo(() => {
    return [
      {
        name: 'Project Title',
        selector: (row: any) => row.project_title,
        sortable: true,
        cell: (row: any) => {
          return (
            <Link to={`/projects/${row?.id}/projects-summary`}>
              <div className="text-blue-300">{row?.project_title}</div>{' '}
            </Link>
          )
        },
      },
      {
        name: 'Project Name',
        selector: (row: any) => row.project_name,
        sortable: true,
        cell: (row: any) => {
          return (
            <Link to={`/projects/${row?.id}/projects-summary`}>
              <div className="text-blue-300">{row?.project_name}</div>
            </Link>
          )
        },
      },
      {
        name: 'Start Date',
        sortable: true,
        selector: (row: any) => row.start_date,
      },
      {
        name: 'End Date',
        sortable: true,
        selector: (row: any) => row.end_date,
      },
      {
        name: 'Open Ticket',
        selector: (row: any) => row.open_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('open')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.open_ticket}
            </div>
          )
        },
      },
      {
        name: 'In Progress Ticket',
        selector: (row: any) => row.inprogress_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('in progress')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.inprogress_ticket}
            </div>
          )
        },
      },

      {
        name: 'Feedback Ticket',
        selector: (row: any) => row.feeback_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('feedback')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.feedback_ticket}
            </div>
          )
        },
      },
      {
        name: 'Completed Ticket',
        selector: (row: any) => row.completed_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('completed')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.completed_ticket}
            </div>
          )
        },
      },
    ]
  }, [])

  const modalData = useMemo(
    () =>
      archivedProjectsListForDashboard?.rows?.map((row) => ({
        id: row?.project_details?.id,
        project_title: row?.project_details?.title,
        project_name: `${row?.project_details?.project_prefix}-${row?.project_details?.project_number}`,
        start_date: row?.project_details?.start_date,
        end_date: row?.project_details?.end_date,
        open_ticket: row?.open_ticket_count,
        inprogress_ticket: row?.inprogress_ticket_count,
        feedback_ticket: row?.feedback_ticket_count,
        completed_ticket: row?.completed_ticket_count,
      })),
    [archivedProjectsListForDashboard],
  )

  useEffect(() => {
    dispatch(
      getArchivedProjectListForDashboard({
        page: page,
        limit: 10,
        search: searchDebounce ?? undefined,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchDebounce])

  // * Mark: Project ticket list
  useEffect(() => {
    if (!!ticketModalStatus) {
      dispatch(
        getProjectTicketList(ticketModalProjectId, {
          page: 1,
          limit: 10,
          status: ticketModalStatus,
        }),
      )
    }
  }, [dispatch, ticketModalProjectId, ticketModalStatus])

  return (
    <div className="flex flex-col gap-10 py-5">
      <div className="flex justify-end">
        <Input
          onChange={(e) => {
            setSearch((prev) => e.target.value)
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          placeholder="Search by title"
          prepend={<FaSearch />}
          containerClass="mb-0"
          className="px-6"
        />
      </div>
      <DataTable
        loading={isArchivedProjectsListForDashboardLoading}
        data={modalData ?? []}
        columns={columns as any}
        pagination
        onChangePage={(page) => {
          setPage(page)
        }}
        totalRows={+archivedProjectsListForDashboard?.total}
      />

      <Modal
        title={
          <div className="flex justify-between">
            <span>
              Showing tickets for project{' '}
              <span className="text-blue-300">{ticketModalTitleText}</span>
            </span>
            {getStatusChip((ticketModalStatus as any) ?? 'open')}
          </div>
        }
        isActive={showTicketModal}
        toggleModal={setShowTicketModal}
        size="md"
      >
        <div className="p-12">
          <ProjectTicketList
            onTitleClick={(ticketId: number) => {
              window.open(
                `#/projects/${ticketModalProjectId}/ticket?showTicket=true&ticketId=${ticketId}`,
                '_blank',
              )
            }}
            showAction={false}
          />
        </div>
      </Modal>
    </div>
  )
}
