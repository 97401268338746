import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {VscFilePdf} from 'react-icons/vsc'

import {Button, ImageDataTypes, ImageUpload, Input, Modal} from 'app/common'
import {addProjectPlan, getProjectPlanList, updateProjectPlan} from 'redux-src'
import {getAssetUrl} from 'helpers'

export const UploadFloorPlan = ({
  isOpen,
  setIsOpen,
  plan,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  plan?: Api.ProjectPlanIndividual
}) => {
  const [isPdf, setPdf] = useState<boolean>(false)

  const {loading}: RT.ProjectPlanReduxType = useSelector(
    (state: any) => state.projectPlan,
  )

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const [projectPlanName, setProjectPlanName] = useState<string>('')
  const [assets, setAssets] = useState<Array<{url: string; file: File}>>([])

  const [dragActive, setDragActive] = useState(false)

  const changeImage = (data: Array<ImageDataTypes>) => {
    if (data.length > 0) {
      if (data.at(0)?.file?.name?.split('.')?.at(-1)?.toLowerCase() === 'pdf') {
        setAssets(data)
        setPdf(true)
      } else setPdf(false)
    }
    return
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }

  const submit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const assetsFormData = new FormData()

      if (!!plan) {
        if (assets.length > 0) {
          assets.map((item) => {
            return assetsFormData.append('file', item.file)
          })
        }
        assetsFormData.append('name', projectPlanName)
        dispatch(
          updateProjectPlan(
            projectId,
            plan.project_plan_details.id,
            assetsFormData,
            () => {
              dispatch(getProjectPlanList(projectId))
              setIsOpen(false)
              setAssets([])
              setProjectPlanName('')
            },
          ),
        )
        return
      }

      if (assets.length > 0) {
        assets.map((item) => {
          return assetsFormData.append('file', item.file)
        })

        assetsFormData.append('name', projectPlanName)

        dispatch(
          addProjectPlan(projectId, assetsFormData, () => {
            dispatch(getProjectPlanList(projectId))
            setIsOpen(false)
            setAssets([])
            setProjectPlanName('')
          }),
        )
      }
    },
    [assets, dispatch, plan, projectId, projectPlanName, setIsOpen],
  )

  useEffect(() => {
    if (plan) {
      setProjectPlanName(plan?.project_plan_details.name)
      setPdf(!!plan?.project_plan_details?.from_pdf)
    }
  }, [plan])

  //MARK: Clear fields
  const clearFields = () => {
    setIsOpen(false)
    setProjectPlanName('')
    setAssets([])
  }

  return (
    <>
      <Modal
        title={plan ? 'Edit Project Plan' : 'Upload Project Plan'}
        isActive={isOpen}
        toggleModal={setIsOpen}
        onClose={clearFields}
      >
        <div className="p-16 flex flex-col items-center justify-start gap-10">
          <Input
            id="project-plan-name"
            label={'Project Plan Name'}
            placeholder="Enter project plan name"
            name={'projectPlanName'}
            value={projectPlanName}
            onChange={(e) => setProjectPlanName(e.target.value)}
          />
          <ImageUpload
            images={assets}
            onChange={changeImage}
            style={{background: 'red'}}
            title="upload image"
            accept="application/pdf"
            multiple={false}
          >
            {({onUploadImage, imageData}) => (
              <>
                <input
                  className="cursor-pointer hidden"
                  type="file"
                  id="project-plan-file"
                  name="project-plan-file"
                  accept="application/pdf"
                  // accept="image/*"
                  multiple={false}
                  onChange={(e) => onUploadImage(e.target.files)}
                />
                <label
                  htmlFor="project-plan-file"
                  className={
                    'cursor-pointer border-1 w-full h-[110px] overflow-hidden rounded-sm flex justify-center items-center flex-col ' +
                    `${
                      !isPdf
                        ? 'bg-red-100 border-red-300'
                        : dragActive
                        ? 'bg-gray-200 border-gray-300'
                        : 'bg-blue-100 border-blue-200'
                    }`
                  }
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={(e) => {
                    handleDrop(e)
                    onUploadImage(e.dataTransfer.files)
                  }}
                >
                  {imageData.length > 0 ||
                  plan?.asset_details ||
                  plan?.project_plan_details?.from_pdf ? (
                    <div className="inline-flex flex-row overflow-hidden gap-10">
                      {imageData.length > 0 ? (
                        imageData.map(({url, file}, index) => {
                          if (file && file?.type?.includes('image')) {
                            return (
                              <img
                                key={index.toString()}
                                src={url}
                                alt="project-plan-files-and-photos"
                                className="h-[80px] w-[70px] object-cover rounded-sm"
                              />
                            )
                          }

                          return (
                            <div
                              className={`flex items-center justify-center flex-col gap-6 w-[200px] break-all ${
                                isPdf ? 'text-gray-400' : 'text-red-400'
                              }`}
                            >
                              <VscFilePdf size={50} />
                              {file.name}
                            </div>
                          )
                        })
                      ) : plan?.asset_details?.file_type === 'image' ? (
                        <img
                          key={plan?.asset_details.name}
                          src={getAssetUrl(plan?.asset_details)}
                          alt="project-plan-files-and-photos"
                          className="h-[80px] w-[70px] object-cover rounded-sm"
                        />
                      ) : (
                        <div className="flex items-center justify-center flex-col gap-6 text-gray-400">
                          <VscFilePdf size={50} />
                          {plan.project_plan_details.name}.pdf
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <label
                        htmlFor="project-plan-file"
                        className="cursor-pointer text-gray-400 mt-auto"
                      >
                        Drag & drop your file <i>[ .pdf ]</i> here or
                      </label>
                      <label
                        htmlFor="project-plan-file"
                        className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400"
                      >
                        Choose File
                      </label>
                    </>
                  )}
                </label>
              </>
            )}
          </ImageUpload>
          <div className="flex items-center justify-end w-full">
            <Button
              title={plan ? 'Edit' : 'Upload'}
              onClick={submit}
              isDisabled={
                !!!isPdf ||
                (plan
                  ? (plan?.asset_details === null &&
                      !plan?.project_plan_details.from_pdf) ||
                    projectPlanName.length === 0
                  : assets.length === 0 || projectPlanName.length === 0)
              }
              loading={loading}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
