import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface ProjectStatusProps {
  id: number
  onSelectElement: (id: number) => void
}

interface ProjectStatusType {
  label: string
  description: string
  value: string
}

export const ProjectStatus = ({id, onSelectElement}: ProjectStatusProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {
    individualElement,
    individualStyles,
    alterElementSettings,
    defaultElement,
  } = useIndividualFormElement(id)

  const [selectedOptions, setSelectedOptions] = useState<{
    label: string
    description: string
    value: string
  }>(individualElement?.content as ProjectStatusType)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, selectedOptions, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    const updatedLayoutHeight = Array.isArray(selectedOptions?.value)
      ? defaultElement?.layout?.h + (selectedOptions?.value?.length - 1) * 2
      : defaultElement?.layout?.h

    const selectedLayout = {
      ...individualElement.layout,
      h: updatedLayoutHeight,
    }
    alterElementSettings({
      layout: selectedLayout,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alterElementSettings, defaultElement?.layout?.h, selectedOptions?.value])

  // Sample options for the dropdown
  const projectStatusOptions = [
    {
      value: '',
      label: `Select a ${
        individualElement?.content?.label?.length > 0
          ? individualElement?.content?.label
          : 'Project Status'
      }`,
    },
    {value: 'open', label: 'Open'},
    {value: 'in_progress', label: 'In Progress'},
    {value: 'resolved', label: 'Resolved'},
    {value: 'feedback', label: 'Feedback'},
    {value: 'closed', label: 'Closed'},
    {value: 'rejected', label: 'Rejected'},
    {value: 'completed', label: 'Completed'},
  ]

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
        ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="py-8 flex gap-4">
          <input
            type="text"
            name="label"
            value={selectedOptions?.label}
            onChange={(e: any) => {
              setSelectedOptions((prev) => ({...prev, label: e.target.value}))
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />

          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {/* Dropdown */}
      <div className="py-4 px-12">
        <div className="relative w-full">
          <select
            value={selectedOptions?.value || ''}
            onChange={(e) => {
              setSelectedOptions((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }}
            className="
              appearance-none 
              w-full 
              px-6 
              py-6 
              border 
              border-gray-300 
              rounded-md 
              text-gray-700 
              bg-gray-100 
              shadow-sm 
              focus:outline-none 
              focus:ring-blue-500 
              focus:border-blue-500
              cursor-pointer
              outline-none
            "
          >
            {projectStatusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* Custom dropdown arrow */}
          <div
            className="
              pointer-events-none 
              absolute 
              inset-y-0 
              right-0 
              flex 
              items-center 
              px-2 
              text-gray-700
            "
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={selectedOptions?.description}
            onChange={(e: any) => {
              setSelectedOptions((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      <div className="flex flex-col mx-12"></div>
    </div>
  )
}
