import {useCallback, useEffect} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch} from 'react-redux'

import {Button, Input, Modal} from 'app/common'

import {
  addSupplierAction,
  getSupplierAction,
  updateSupplierAction,
} from 'redux-src'

export const SupplierModal = ({
  itemTypeModal,
  close,
  mode,
  supplierDetails,
}: {
  itemTypeModal: boolean
  close: any
  mode?: string
  supplierDetails?: {
    id: string | number
    supplierName: string
  }
}) => {
  const dispatch = useDispatch()

  const {toast} = useAuth()

  const [data, {onChange, errors, modified, onSubmit, clear, setValue}] =
    useFormInput(
      {supplierName: ''},
      () => {
        if (mode !== 'edit') {
          dispatch(
            addSupplierAction(
              {name: data.supplierName},
              () => {
                dispatch(
                  getSupplierAction(undefined, () => {
                    clear()
                    close()
                  }),
                )
              },
              toast,
            ),
          )
        } else {
          dispatch(
            updateSupplierAction(
              supplierDetails.id,
              {name: data.supplierName},
              () => {
                dispatch(
                  getSupplierAction(undefined, () => {
                    clear()
                    close()
                  }),
                )
              },
              toast,
            ),
          )
        }
      },
      (data) => {
        const errors: any = {}

        if (data.supplierName.length === 0) {
          errors.supplierName = 'Please enter supplier name!'
        }

        return errors
      },
    )

  const prePopulatedValue = useCallback(() => {
    if (mode === 'edit') {
      !!supplierDetails &&
        setValue('supplierName', supplierDetails.supplierName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, supplierDetails])

  // Mark: Clear Fields
  const clearFields = () => {
    close()
    if (mode !== 'edit') {
      clear()
    }
  }

  useEffect(() => {
    prePopulatedValue()
  }, [prePopulatedValue])

  return (
    <div>
      <Modal
        isActive={itemTypeModal}
        toggleModal={close}
        title={mode !== 'edit' ? 'Add New Supplier' : 'Edit Supplier'}
        className="flex justify-center items-center lg:w-2/5"
        onClose={clearFields}
      >
        <form
          id="supplier-form"
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(e)
          }}
        >
          <div className="px-32 pt-10">
            <Input
              size="sm"
              name="supplierName"
              value={data.supplierName}
              error={modified.supplierName && errors.supplierName}
              onChange={onChange}
              placeholder="Supplier Name"
            />
          </div>
          <div className="pt-16 border-t-1 border-gray-200"></div>

          <div className="flex gap-6 justify-end items-center pb-20 pr-20">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={clearFields}
            >
              Cancel
            </Button>
            <Button
              form="supplier-form"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode !== 'edit' ? 'Save' : 'Edit'}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}
