import clsx from 'clsx'
import {ImCheckmark} from 'react-icons/im'
import {BsTrashFill} from 'react-icons/bs'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'

import {ButtonGroup, ToolTip, toast} from 'app/common'
import {NotesSummary} from 'app/components'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {
  getSiteVisitDetailAction,
  getSiteVisitListAction,
  getVoidedSiteVisitListAction,
  manuallyMarkAsInvoiced,
  markAsReadyToInvoice,
  updateSiteVisitBasicDetails,
  voidSiteVisitAction,
} from 'redux-src'
import {FaUnlock} from 'react-icons/fa'
import {changeNumToLetter} from '../../../../../../../utils/numToLetter'
import {useEffect, useState} from 'react'

export const PHASES_DATA = [
  {name: 'TO SCHEDULE', value: 'to schedule'},
  {name: 'To Start', value: 'to start'},
  {name: 'In Progress', value: 'in progress'},
  {name: 'Labour Complete', value: 'labour complete'},
  {name: 'To Invoice', value: 'to invoice'},
  {name: 'To Be Approved', value: 'to be approved'},
  {name: 'Invoiced', value: 'invoiced'},
]

export const VisitSitesHeader = () => {
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId, siteVisitId} = params as any

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )
  const {getSiteVisitDetail}: RT.SiteVisitReduxType = useSelector(
    (state: any) => state.siteVisit,
  )

  const {siteVisitTicketCount}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets,
  )

  const [siteVisitTitle, setSiteVisitTitle] = useState<any>('')

  const handleVoidSiteVisit = () => {
    if (siteVisitTicketCount <= 0) {
      return toast.error('There is no ticket assigned to this sitevisit !!')
    }

    if (!projectId && !siteVisitId) return

    dispatch(
      voidSiteVisitAction(projectId, siteVisitId, () => {
        dispatch(getVoidedSiteVisitListAction(projectId, 1, 10))
      }),
    )
  }

  const onUpdateSiteVisitTitle = () => {
    if (getSiteVisitDetail?.site_visit_details?.title === siteVisitTitle) return

    const body = {
      title: siteVisitTitle,
      description: getSiteVisitDetail?.site_visit_details?.description ?? '',
    }

    dispatch(
      updateSiteVisitBasicDetails(projectId, siteVisitId, body, () => {
        dispatch(getSiteVisitDetailAction(projectId, siteVisitId))
        dispatch(getSiteVisitListAction(projectId))
      }),
    )
  }

  useEffect(() => {
    setSiteVisitTitle(getSiteVisitDetail?.site_visit_details?.title ?? '')
  }, [getSiteVisitDetail?.site_visit_details?.title, siteVisitId])

  return (
    <div className="pt-16 bg-white">
      <div className="flex justify-between items-center mb-10">
        <div className="font-bold text-base flex">
          <span>
            {projectDetail?.project_details?.project_prefix}-
            {projectDetail?.project_details?.project_number}
            {changeNumToLetter(
              getSiteVisitDetail?.site_visit_details?.site_visit_number ?? 1,
            )}
          </span>
          <div className="px-10">|</div>
          <div className="block ml-16">
            <input
              className={`border border-1 border-dashed border-gray-300 font-bold w-[120px] ${
                getSiteVisitDetail?.site_visit_details?.status === 'invoiced'
                  ? 'cursor-not-allowed'
                  : ''
              }`}
              value={siteVisitTitle}
              name="siteVisitTitle"
              onChange={(e) => {
                setSiteVisitTitle(e.target.value)
              }}
              onBlur={onUpdateSiteVisitTitle}
              disabled={
                getSiteVisitDetail?.site_visit_details?.status === 'invoiced'
              }
            />
          </div>
        </div>

        {getSiteVisitDetail?.site_visit_details?.status !== 'voided' && (
          <ButtonGroup
            data={[
              {
                icon:
                  getSiteVisitDetail?.site_visit_details?.status !==
                    'to invoice' &&
                  getSiteVisitDetail?.site_visit_details?.status !==
                    'invoiced' ? (
                    <div
                      className="inline-flex gap-8"
                      onClick={() =>
                        dispatch(
                          markAsReadyToInvoice(projectId, siteVisitId, () =>
                            dispatch(
                              getSiteVisitDetailAction(projectId, siteVisitId),
                            ),
                          ),
                        )
                      }
                    >
                      <div className="flex justify-center items-center">
                        <ImCheckmark />
                      </div>{' '}
                      Mark as ready to invoice
                    </div>
                  ) : getSiteVisitDetail?.site_visit_details?.status ===
                    'to invoice' ? (
                    <div
                      className="inline-flex gap-8"
                      onClick={() =>
                        dispatch(
                          manuallyMarkAsInvoiced(projectId, siteVisitId, () =>
                            dispatch(
                              getSiteVisitDetailAction(projectId, siteVisitId),
                            ),
                          ),
                        )
                      }
                    >
                      <div className="flex justify-center items-center">
                        <ImCheckmark />
                      </div>{' '}
                      Manually Mark as Invoiced
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <FaUnlock />
                    </div>
                  ),
              },
              {
                icon: (
                  <ToolTip text="Void site visit" top>
                    <BsTrashFill onClick={handleVoidSiteVisit} />
                  </ToolTip>
                ),
              },
              // {
              //   icon: <BsFlagFill />
              // },
              // {
              //   icon: <IoMdMail size={16} />
              // },
              // {
              //   icon: <HiChatAlt2 size={20} />
              // },
              // {
              //   icon: <BsPrinterFill size={16} />
              // },
              // {
              //   icon: <IoMdSwap size={20} />
              // }
            ]}
          />
        )}
      </div>

      <div className="text-sm font-bold my-16">
        <span className="text-gray-300">CREATED 15 MAY 2022 BY JFDS |</span>
        <span className="bg-red-500 p-4 px-8 rounded-full text-white ml-8">
          SCHEDULE NOT SENT
        </span>
      </div>

      <div className="flex border-1 border-gray-200">
        {getSiteVisitDetail?.site_visit_details?.status === 'voided' ? (
          <div
            className="relative px-16 py-8 bg-red-300 w-full"
            style={{
              backgroundImage:
                'linear-gradient(-45deg, rgba(255,255,255,0.4) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0.4) 75%, transparent 75%, transparent)',
            }}
          >
            <span className="text-white font-bold py-6 px-10 rounded-sm bg-[#d02727]">
              Voided
            </span>
          </div>
        ) : (
          PHASES_DATA?.map((data, index, items) => (
            <div
              key={index}
              className="flex-1 flex items-center text-sm px-16 py-8"
            >
              <div className="flex flex-1 items-center">
                <div
                  className={clsx(
                    'whitespace-nowrap cursor-pointer',
                    data.value ===
                      getSiteVisitDetail?.site_visit_details?.status
                      ? 'bg-gray-500 p-4 px-8 rounded-full text-white'
                      : 'text-gray-300',
                  )}
                >
                  {data?.name}
                </div>
                {index !== items.length - 1 && (
                  <div className="ml-16">
                    <HiOutlineArrowNarrowRight />
                  </div>
                )}
              </div>
            </div>
          ))
        )}

        {}
      </div>

      <div className="mt-16">
        <NotesSummary type="site visit" />
      </div>
    </div>
  )
}
