import React, {useCallback, useEffect, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useAuth} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {Button, Input, Modal, SelectField} from 'app/common'

import {
  addLabourElementGroupAction,
  getLabourElementGroupAction,
  updateLabourElementGroupAction,
  attachLabourElementAction,
  getLabourElementForSelectFieldAction,
  getLabourGroupByIdAction,
} from 'redux-src'

export const AddLabourGroup = ({
  addLabourGroupModal,
  setAddLabourGroupModal,
  mode,
  labourElementGroupData,
}: Comp.AddLabourGroupProps) => {
  const [selectValue, setSelectValue] = useState<
    Array<{id: number; label: string; value: string}>
  >([])

  const [labourList, setLabourList] = useState<
    {
      id: number
      label: string
      value: string
    }[]
  >()

  const {labourElementListForSF: labourListFromApi}: RT.LabourElementReduxType =
    useSelector((state: any) => state.labourElements)

  const dispatch = useDispatch()
  const {toast} = useAuth()

  const [data, {onChange, onSubmit, clear, errors, modified, setValue}] =
    useFormInput(
      {
        groupName: '',
        description: '',
        selectValue: [],
        defaultElementsId: [],
      },
      () => {
        if (mode === 'update') {
          const selectedElements: Array<number> = data.selectValue?.length
            ? data.selectValue.filter(
                (el) => !data.defaultElementsId.includes(el),
              )
            : []
          dispatch(
            updateLabourElementGroupAction(
              labourElementGroupData.id,
              {
                name: data.groupName,
                description: data.description,
              },
              () => {
                setAddLabourGroupModal(false)
                selectedElements.length > 0
                  ? dispatch(
                      attachLabourElementAction(
                        labourElementGroupData.id,
                        {
                          labourElementIds: selectedElements,
                        },
                        () => {
                          clear()
                          dispatch(
                            getLabourGroupByIdAction(
                              labourElementGroupData.id,
                              () => {
                                setSelectValue([])
                                dispatch(getLabourElementGroupAction())
                              },
                            ),
                          )
                        },
                        toast,
                      ),
                    )
                  : dispatch(
                      getLabourGroupByIdAction(
                        labourElementGroupData.id,
                        () => {
                          setSelectValue([])
                          dispatch(getLabourElementGroupAction())
                        },
                      ),
                    )
              },
              toast,
            ),
          )
        } else {
          dispatch(
            addLabourElementGroupAction(
              {
                name: data.groupName,
                description: data.description,
                labourElementIds: data.selectValue,
              },
              () => {
                clear()
                setSelectValue([])
                // setLabourList([])
                setAddLabourGroupModal(false)
                dispatch(getLabourElementGroupAction())
              },
              toast,
            ),
          )
        }
      },
      (data) => {
        const errors: any = {}

        if (data.groupName.length === 0) {
          errors.groupName = 'Please enter group name'
        } else if (data.description.length === 0) {
          errors.description = 'Please enter description'
        }

        return errors
      },
    )

  const prePopulatedGroupInfo = useCallback(() => {
    if (mode === 'update' && labourElementGroupData) {
      setValue('groupName', labourElementGroupData?.groupName)
      setValue('description', labourElementGroupData?.description)
      setSelectValue(labourElementGroupData?.labourElements)
      setValue(
        'defaultElementsId',
        labourElementGroupData?.labourElements?.map((el) => el.id),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labourElementGroupData])

  // MARK: Clear fields

  const clearFields = () => {
    setAddLabourGroupModal(false)
    if (mode !== 'update') {
      clear()
    }
  }

  const remappedLaboutElementList = useCallback(() => {
    const remappedList = labourListFromApi?.rows.map(
      ({labour_element_details}: any) => {
        return {
          id: labour_element_details.id,
          label: labour_element_details.name,
          value: labour_element_details.name,
        }
      },
    )

    setLabourList(remappedList)
  }, [labourListFromApi])

  useEffect(() => {
    remappedLaboutElementList()
  }, [remappedLaboutElementList])

  useEffect(() => {
    prePopulatedGroupInfo()
  }, [prePopulatedGroupInfo])

  useEffect(() => {
    dispatch(getLabourElementForSelectFieldAction())
  }, [dispatch])

  return (
    <Modal
      isActive={addLabourGroupModal}
      toggleModal={setAddLabourGroupModal}
      title={mode === 'update' ? 'Update group' : 'Add a new group'}
      onClose={clearFields}
    >
      <form id="add-labour-group" onSubmit={onSubmit}>
        <div className="pt-[18px] px-[20px]">
          <label htmlFor="groupName" className="flex flex-col gap-6">
            <Input
              type="text"
              name="groupName"
              value={data.groupName}
              error={modified.groupName && errors.groupName}
              onChange={onChange}
              placeholder="Group Name"
              label={'Group Name'}
              required
            />
          </label>
          <label htmlFor="description" className="flex flex-col gap-6">
            <Input
              type="text"
              name="description"
              value={data.description}
              error={modified.description && errors.description}
              onChange={onChange}
              placeholder="Description"
              label={'Description'}
              required
            />
          </label>
        </div>
        <div className="px-20">
          <span className="flex gap-6 text-md font-bold pb-6">
            <span>Group Labour Element</span>
            <span className="text-red-500">*</span>
          </span>
          <span>
            <SelectField
              options={labourList}
              multiValue={selectValue}
              onMultiChangeValue={(e) => {
                setSelectValue(e)
                setValue(
                  'selectValue',
                  e?.map((item) => item.id),
                )
              }}
              placeholder="Start typing the labour element"
              isMulti={true}
            />
          </span>
        </div>
        <div className="border border-t-1 border-gray-200 px-20 py-12 mt-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => clearFields()}
            >
              Cancel
            </Button>
            <Button
              form="add-labour-group"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode === 'update' ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
