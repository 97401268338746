import {
  ADD_PROJECT_USER_GROUP,
  ADD_USER_TO_PROJECT,
  DELETE_PROJECT_IMAGE,
  DOWNLOAD_PURCHASE_ORDER,
  GET_COMPANY_TIME_ENTRY,
  GET_COMPANY_USER_TO_ADDED_IN_PROJECT,
  GET_PROJECT_FILES_AND_PHOTOS,
  GET_PROJECT_LIST_BY_DEFINED_ID,
  GET_PROJECT_PURCHASE_ORDER,
  GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID,
  GET_PROJECT_USER_GROUP_LIST,
  GET_PROJECT_USER_LIST,
  GET_PROJECT_VARIATION_LIST,
  GET_SELECTED_SITE_VISIT_TIMESHEET,
  GET_SUPPLIER_LIST_OF_PURCHASE_ORDER,
  GET_TICKET_FILES_AND_PHOTOS,
  GET_USER_GROUP_TO_ADDED_IN_PROJECT,
  REMOVE_PROJECT_USER,
  REMOVE_PROJECT_USER_GROUP,
  UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY,
} from './actionTypes.action'
import {api, APIS} from 'config'
import {
  GET_PROJECT_LIST,
  ADD_PROJECT,
  PROJECT_DETAIL_BY_ID,
  UPDATE_PROJECT,
  SIGNED_OFF_LIST,
  UPDATE_PROJECT_NUMBER_PREFIX,
  UPLOAD_PROJECT_IMAGE,
} from '.'
import {errorHandler} from 'utils'

import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'

/**
 * get the project list for a logged user
 */
export function getProjectList(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    dispatch({type: GET_PROJECT_LIST.LOADING})

    const res = await api<Api.Base<Api.ProjectList>>(
      getParsedUrl(APIS.projects, params),
    )

    const {
      success,
      data: {
        message,
        data: {total, rows},
      },
    } = res.data

    if (success) {
      dispatch({type: GET_PROJECT_LIST.SUCCESS, payload: {total, rows}})
      successCallback?.()
    } else {
      dispatch({type: GET_PROJECT_LIST.ERROR, payload: message})
    }
  }
}

// CREATE PROJECT

export function addProject(
  body: any,
  successCallback: (data: Api.ProjectDetails) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_PROJECT.LOADING})

      res = await api<any>(`${APIS.projects}`, 'POST', body)

      const {success, data} = res.data

      if (success) {
        dispatch({type: ADD_PROJECT.SUCCESS})

        successCallback && successCallback(data?.data)
        toast.success(data.message)
        return 1
      } else {
        dispatch({type: ADD_PROJECT.ERROR, payload: data?.message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_PROJECT.ERROR})
      toast.error('Error adding the project!')
      return 0
    }
  }
}

// GET PROJECT BY ID ACTION

export function getProjectByIdAction(
  projectId: number,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: PROJECT_DETAIL_BY_ID.LOADING})

      res = await api<Api.Base<Api.ProjectDetailsById>>(
        `${APIS.projects}/${projectId}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: PROJECT_DETAIL_BY_ID.SUCCESS, payload: data})
        successCallback(data)
        return 1
      } else {
        dispatch({type: PROJECT_DETAIL_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: PROJECT_DETAIL_BY_ID.ERROR})
      return 0
    }
  }
}

// GET SIGNEDOFFPROJECT LIST

export function getSignedOffProjectList(projectId: number) {
  return async function (dispatch: any) {
    dispatch({type: SIGNED_OFF_LIST.LOADING})

    const res = await api<any>(
      `${APIS.signedOff.replace(':projectId', String(projectId))}&${
        APIS.pagination
      }`,
    )
    const {
      success,
      data: {message, data: rows},
    } = res.data

    if (success) {
      dispatch({type: SIGNED_OFF_LIST.SUCCESS, payload: rows})
    } else {
      dispatch({type: SIGNED_OFF_LIST.ERROR, payload: message})
    }
  }
}

// UPDATE PROJECT ACTION

export function updateProjectAction(
  projectId: number | string,
  body: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PROJECT.LOADING})

      res = await api<any>(`${APIS.projects}/${projectId}`, 'PATCH', body)

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PROJECT.SUCCESS})

        successCallback?.()
        toast.success('Projects successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_PROJECT.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_PROJECT.ERROR})

      return 0
    }
  }
}

// UPDATE_PROJECT_NUMBER_AND_PREFIX

export function updateProjectNumberPrefix(
  projectId: number,
  body: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PROJECT_NUMBER_PREFIX.LOADING})

      res = await api<Api.Base<Api.ProjectNumberPrefixResponse>>(
        `${APIS.projects}/${projectId}/project-number-prefix`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PROJECT_NUMBER_PREFIX.SUCCESS})

        successCallback?.()
        toast.success('Project number and prefix successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_PROJECT_NUMBER_PREFIX.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_PROJECT_NUMBER_PREFIX.ERROR})

      return 0
    }
  }
}

// Upload project Image

export const uploadProjectImageAction = (
  projectId: number,
  assets: FormData,
  successCallback?: any,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPLOAD_PROJECT_IMAGE.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/image`,
        'POST',
        assets,
        {
          file: true,
        },
      )

      const {
        success,
        data: {data: project, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPLOAD_PROJECT_IMAGE.SUCCESS,
          payload: project,
        })
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: UPLOAD_PROJECT_IMAGE.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({
        type: UPLOAD_PROJECT_IMAGE.ERROR,
      })
      return 0
    }
  }
}

// Delete project image

export function deleteProjectImageAction(
  projectId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PROJECT_IMAGE.LOADING})

      res = await api<any>(`${APIS.projects}/${projectId}/image`, 'DELETE')

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_PROJECT_IMAGE.SUCCESS})

        successCallback?.()
        toast.success('Project image successfully deleted !')
        return 1
      } else {
        dispatch({
          type: DELETE_PROJECT_IMAGE.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_PROJECT_NUMBER_PREFIX.ERROR})

      return 0
    }
  }
}

// get project_list_by customer or site id

export function getProjectListByDefinedId(
  urlType: 'customers' | 'sites',
  definedId: number,
  query?: {
    page?: number
    limit?: number
    projectType?: string
    startDate?: string
    projectStatus?: string
    endDate?: string
    search?: string
  },
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_LIST_BY_DEFINED_ID.LOADING})

      res = await api<Api.Base<Api.ProjectListByDefinedId>>(
        getParsedUrl(`${urlType}/${definedId}/projects`, query),
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_LIST_BY_DEFINED_ID.SUCCESS,
          payload: data,
        })
        successCallback?.(data)
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_LIST_BY_DEFINED_ID.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_LIST_BY_DEFINED_ID.ERROR})
      return 0
    }
  }
}

//MARK: PURCHASE ORDER

// getSupplierList of purchase order

export function getSupplierListOfPurchaseOrder(
  projectId: number | string,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/purchase-orders/suppliers`,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({
          type: GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.SUCCESS,
          payload: data,
        })
        successCallback?.()
      } else {
        dispatch({
          type: GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.ERROR,
          payload: message,
        })
      }
    } catch (error) {
      dispatch({type: GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.ERROR})
      return 0
    }

    return res
  }
}

//MARK: get project purchase order

export function getProjectPurchaseOrder(
  page: number = 1,
  limit: number = 10,
  projectId: number,
  supplierId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_PURCHASE_ORDER.LOADING})

      res = await api<Api.Base<any>>(
        `${
          APIS.projects
        }/${projectId}/purchase-orders/item?page=${page}&limit=${limit}${
          supplierId ? `&supplierId=${supplierId}` : ''
        }`,
      )

      const {
        success,
        data: {
          message,
          data: {rows, total},
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_PURCHASE_ORDER.SUCCESS,
          payload: {rows, total},
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_PURCHASE_ORDER.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_PURCHASE_ORDER.ERROR})
      return 0
    }
  }
}

// MARK: get project purchase order by supplier id

export function getProjectPurchaseOrderBySupplierId(
  projectId: number,
  supplierId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/purchase-orders/suppliers/${supplierId}`,
        'GET',
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.SUCCESS,
          payload: {total, rows},
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.ERROR})
      return 0
    }
  }
}

//MARK: download purchase order

export function downloadPurchaseOrder(
  projectId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DOWNLOAD_PURCHASE_ORDER.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/purchase-orders/download`,
        'POST',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: DOWNLOAD_PURCHASE_ORDER.SUCCESS,
          payload: data,
        })
        toast.success('Successfully downloaded purchase orders')
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: DOWNLOAD_PURCHASE_ORDER.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: DOWNLOAD_PURCHASE_ORDER.ERROR})
      return 0
    }
  }
}

//MARK: PROJECT USER AND USER GROUP ACTIONS

export function getProjectUserList(
  projectId: number | string,
  page: number,
  limit: number,
  search: string = '',
  successCallback?: () => void,
) {
  return async function (dispatch: any, getState: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_USER_LIST.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users?page=${page}&limit=${limit}&search=${search}`,
        'GET',
      )

      const {
        errors,
        data: {
          data: {rows, total},
        },
        success,
      } = res.data

      if (success) {
        dispatch({type: GET_PROJECT_USER_LIST.SUCCESS, payload: {rows, total}})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_PROJECT_USER_LIST.ERROR, payload: errors[0]})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_USER_LIST.ERROR})
      return 0
    }
  }
}

export function getCompanyUserToAddedInProject(
  projectId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any, getState: any) {
    let res
    try {
      dispatch({type: GET_COMPANY_USER_TO_ADDED_IN_PROJECT.LOADING})

      res = await api<any>(`${APIS.projects}/${projectId}/users/company`, 'GET')

      const {
        errors,
        data: {data},
        success,
      } = res.data

      if (success) {
        dispatch({
          type: GET_COMPANY_USER_TO_ADDED_IN_PROJECT.SUCCESS,
          payload: data,
        })

        successCallback && successCallback()
        return 1
      } else {
        dispatch({
          type: GET_COMPANY_USER_TO_ADDED_IN_PROJECT.ERROR,
          payload: errors[0],
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_COMPANY_USER_TO_ADDED_IN_PROJECT.ERROR})
      return 0
    }
  }
}

export function addUsersToProject(
  projectId: number,
  body: any,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: ADD_USER_TO_PROJECT.LOADING})

      res = await api<any>(`${APIS.projects}/${projectId}/users`, 'POST', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({type: ADD_USER_TO_PROJECT.SUCCESS})
          successCallback(data)
          toast.success(message)
        } else dispatch({type: ADD_USER_TO_PROJECT.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: ADD_USER_TO_PROJECT.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function removeProjectUsersAction(
  projectId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: REMOVE_PROJECT_USER.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users`,
        'DELETE',
        body,
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: REMOVE_PROJECT_USER.SUCCESS})
          successCallback()
          // toast.success(message)
        } else dispatch({type: REMOVE_PROJECT_USER.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: REMOVE_PROJECT_USER.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

// CREATE USER GROUP
export function addProjectUserGroups(
  projectId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: ADD_PROJECT_USER_GROUP.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users/groups`,
        'POST',
        body,
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: ADD_PROJECT_USER_GROUP.SUCCESS})
          successCallback()
          toast.success(message)
        } else dispatch({type: ADD_PROJECT_USER_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: ADD_PROJECT_USER_GROUP.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

//MARK: DELETE USER GROUP

export function removeProjectUserGroupAction(
  projectId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: REMOVE_PROJECT_USER_GROUP.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users/groups`,
        'DELETE',
        body,
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: REMOVE_PROJECT_USER_GROUP.SUCCESS})
          successCallback()
          toast.success(message)
        } else
          dispatch({type: REMOVE_PROJECT_USER_GROUP.ERROR, payload: message})
      }
    } catch (message) {
      dispatch({type: REMOVE_PROJECT_USER_GROUP.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

//MARK: GET USER GROUP BY ID

export function getProjectUserGroupList(
  projectId: number,
  page: number,
  limit: number,
  search: string = '',
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_PROJECT_USER_GROUP_LIST.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users/groups?page=${page}&limit=${limit}&search=${search}`,
        'GET',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: GET_PROJECT_USER_GROUP_LIST.SUCCESS,
            payload: data,
          })
          successCallback()
        } else
          dispatch({
            type: GET_PROJECT_USER_GROUP_LIST.ERROR,
            payload: data,
          })
      }
    } catch (message) {
      dispatch({
        type: GET_PROJECT_USER_GROUP_LIST.ERROR,
        payload: message,
      })
      // errorHandler(res.data, toast)
    }
  }
}

export function getUserGroupListToAddedInProject(
  projectId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_USER_GROUP_TO_ADDED_IN_PROJECT})

      res = await api<any>(
        `${APIS.projects}/${projectId}/users/groups/company`,
        'GET',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: GET_USER_GROUP_TO_ADDED_IN_PROJECT.SUCCESS,
            payload: data,
          })
          successCallback?.()
        } else
          dispatch({
            type: GET_USER_GROUP_TO_ADDED_IN_PROJECT.ERROR,
            payload: message,
          })
      }
    } catch ({message}: any) {
      dispatch({
        type: GET_USER_GROUP_TO_ADDED_IN_PROJECT.ERROR,
        payload: message,
      })
    }
  }
}

// Project LabourEntry

export function getSelectedSitevisitLabourEntry(
  projectId: number,
  params: {
    page?: number
    limit?: number
    fromDate?: string
    toDate?: string
    isWorkerPaid?: 'true' | 'false'
    isInvoiced?: 'true' | 'false'
    userId?: number
    ticketId?: number
    siteVisitId?: number
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_SELECTED_SITE_VISIT_TIMESHEET})

      res = await api<Api.Base<any>>(
        getParsedUrl(`time-entry${APIS.projects}/${projectId}`, params),
        'GET',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {
            message,
            data: {rows, total},
          },
        } = res.data
        if (success) {
          dispatch({
            type: GET_SELECTED_SITE_VISIT_TIMESHEET.SUCCESS,
            payload: {rows, total},
          })
          successCallback?.()
        } else
          dispatch({
            type: GET_SELECTED_SITE_VISIT_TIMESHEET.ERROR,
            payload: message,
          })
      }
    } catch ({message}: any) {
      dispatch({
        type: GET_SELECTED_SITE_VISIT_TIMESHEET.ERROR,
        payload: message,
      })
    }
  }
}

//MARK: Get Company Time Entry List

export function getCompanyTimeEntryList(
  params: {
    page?: number
    limit?: number
    fromDate?: string
    toDate?: string
    isWorkerPaid?: 'true' | 'false'
    isInvoiced?: 'true' | 'false'
    userId?: number
    projectId?: number
    ticketName?: string
    siteVisitName?: string
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_COMPANY_TIME_ENTRY})

      res = await api<Api.Base<any>>(getParsedUrl(`time-entry`, params), 'GET')

      if (res.data !== undefined) {
        const {
          success,
          data: {
            message,
            data: {rows, total},
          },
        } = res.data
        if (success) {
          dispatch({
            type: GET_COMPANY_TIME_ENTRY.SUCCESS,
            payload: {rows, total},
          })
          successCallback?.()
        } else
          dispatch({
            type: GET_COMPANY_TIME_ENTRY.ERROR,
            payload: message,
          })
      }
    } catch ({message}: any) {
      dispatch({
        type: GET_COMPANY_TIME_ENTRY.ERROR,
        payload: message,
      })
    }
  }
}

//MARK: update completed at of project labour entry

export const updateCompletedAtOfLabourEntry = (
  projectId: number,
  projectLabourEntryId: number,
  body: any,
  successCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/labour-entry/${projectLabourEntryId}`,
        'PATCH',
        body,
      )

      const {
        data: {data, message},
        success,
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY.SUCCESS,
            payload: data,
          })

          successCallback?.()
          toast?.success(message)
        } else {
          dispatch({type: UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY.ERROR})

          toast.error(message)
        }
      }
    } catch ({message}: any) {
      dispatch({
        type: UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY.ERROR,
        payload: message,
      })
    }
  }
}

//MARK: PROJECT FILES AND PHOTOS

export const getProjectFilesAndPhotos = (
  projectId: number,
  params?: {
    page: number
    limit: number
    fileType?: string
    siteVisitId?: number
  },
  successCallback?: () => void,
  errorCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_FILES_AND_PHOTOS.LOADING})

      res = await api<Api.Base<Api.AssetsList>>(
        getParsedUrl(`${APIS.projects}/${projectId}/attachments`, params),
      )

      const {
        success,
        data: {
          data: {rows, total},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_FILES_AND_PHOTOS.SUCCESS,
          payload: {rows, total},
        })
        successCallback && successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_FILES_AND_PHOTOS.ERROR,
          payload: message,
        })

        errorCallback && errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({
        type: GET_PROJECT_FILES_AND_PHOTOS.ERROR,
      })
      return 0
    }
  }
}

// //UPLOAD PROJECT FILES AND PHOTOS

// export const uploadProjectFilesAndPhotos = (
//   projectId: number,
//   assets: FormData,
//   successCallback?: () => void,
//   errorCallback?: () => void
// ) => {
//   return async function (dispatch: any) {
//     let res
//     try {
//       dispatch({type: UPLOAD_PROJECT_FILES_AND_PHOTOS.LOADING})

//       res = await api<any>(
//         `${APIS.projects}/${projectId}/upload-files`,
//         'POST',
//         assets,
//         {
//           file: true
//         }
//       )

//       const {
//         success,
//         data: {data: site, message}
//       } = res.data

//       if (success) {
//         dispatch({
//           type: UPLOAD_PROJECT_FILES_AND_PHOTOS.SUCCESS,
//           payload: site
//         })
//         toast?.success(message)
//         successCallback?.()
//         return 1
//       } else {
//         dispatch({
//           type: UPLOAD_PROJECT_FILES_AND_PHOTOS.ERROR,
//           payload: message
//         })

//         errorCallback && errorCallback?.()

//         errorHandler(res.data, toast)
//         return 0
//       }
//     } catch (e) {
//       dispatch({
//         type: UPLOAD_PROJECT_FILES_AND_PHOTOS.ERROR
//       })
//       return 0
//     }
//   }
// }

export const getTicketsFilesAndPhotos = (
  projectId: number,
  ticketId: number,
  params?: {
    page: number
    limit: number
    documentType: 'document' | 'video' | 'image'
  },
  successCallback?: () => void,
  errorCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_FILES_AND_PHOTOS.LOADING})

      res = await api<Api.Base<Api.AssetsList>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/attachments/tickets/${ticketId}`,
          params,
        ),
      )

      const {
        success,
        data: {
          data: {rows, total},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_FILES_AND_PHOTOS.SUCCESS,
          payload: {rows, total},
        })
        successCallback && successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_TICKET_FILES_AND_PHOTOS.ERROR,
          payload: message,
        })

        errorCallback && errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({
        type: GET_TICKET_FILES_AND_PHOTOS.ERROR,
      })
      return 0
    }
  }
}

// * GET_PROJECT_VARIATION_LIST
export const getProjectVariation = (projectId: string | number) => {
  return async (dispatch: any) => {
    let resp
    dispatch({type: GET_PROJECT_VARIATION_LIST.LOADING})
    try {
      resp = await api<Api.Base<Api.VariationList>>(
        `projects/${projectId}/variations`,
      )

      const data = resp.data
      if (data.success) {
        dispatch({
          type: GET_PROJECT_VARIATION_LIST.SUCCESS,
          payload: data.data.data,
        })
      } else {
        throw new Error(data.message ?? data.data.message)
      }
    } catch (error: any) {
      dispatch({type: GET_PROJECT_VARIATION_LIST.ERROR})
      toast.error(error.message)
    }
  }
}

export const createProjectVariation = (
  projectId: string | number,
  dataToPost: Record<string, any>,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      const resp = await api<Api.Base<any>>(
        `projects/${projectId}/variations`,
        'POST',
        dataToPost,
      )
      const data = resp.data
      if (data.success) {
        toast.success('Created variation for project successfully!')
        onSuccess?.()
      } else {
        throw new Error(
          resp.data.message ?? 'Some error occured while creating variation.',
        )
      }
    } catch (error: any) {
      toast.error(error.message)
      onError?.()
    }
  }
}

//MARK: Delete Individual Project
export const deleteIndividualProjectAction = (
  projectId: string | number,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      const resp = await api<Api.Base<any>>(`projects/${projectId}`, 'DELETE')
      const data = resp.data
      if (data.success) {
        toast.success('Deleted project successfully!')
        onSuccess?.()
      } else {
        throw new Error(
          resp.data.message ?? 'Some error occured while deleting project.',
        )
      }
    } catch (error: any) {
      toast.error(error.message)
      onError?.()
    }
  }
}

//MARK: Archived Individual Project
export const archiveIndividualProjectAction = (
  projectId: string | number,
  status: boolean,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      const resp = await api<Api.Base<any>>(
        `projects/${projectId}/archived`,
        'PATCH',
        {
          isArchived: status,
        },
      )
      const data = resp.data
      if (data.success) {
        toast.success('Archived project successfully!')
        onSuccess?.()
      } else {
        throw new Error(
          resp.data.message ?? 'Some error occured while deleting project.',
        )
      }
    } catch (error: any) {
      toast.error(error.message)
      onError?.()
    }
  }
}
