import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {RootState, createMannualLog, getProjectHistory} from 'redux-src'

import {Checkbox, DateInput, Input} from 'app/common'
import {DescriptionBox} from 'app/components'
import {JobTitle} from '../../components'
import {HistoryListItem} from './components'
import {isStringEmpty} from 'helpers'
import {FaArrowRight, FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {useDebounceValue} from 'hooks'

export const NotesHistoryPage = () => {
  const {toast} = useAuth()
  const {params} = useNavigation()
  const {projectId} = params as any
  const dispatch = useDispatch()
  const [logValue, setLogValue] = useState('')
  const [notes, setNotes] = useState(true)
  const [logs, setLogs] = useState(true)

  const [search, setSearch] = useState('')

  const searchDebounce = useDebounceValue(search)

  const [typeSelectorFilter, setTypeSelectorFilter] = useState<string[]>([
    'note',
    'log',
  ])

  // * When note is added from modal, refetch history
  const {projectNotes} = useSelector((state: RootState) => state.noteHistory)

  useEffect(() => {
    let temp = []
    if (notes) {
      temp.push('note')
    }
    if (logs) {
      temp.push('log')
    }
    setTypeSelectorFilter(temp)
  }, [logs, notes])

  // useEffect(() => {
  //   dispatch(getNoteHistoryListAction(projectId, 'notes,history,logs'))
  // }, [projectId, dispatch])

  const [from, setFrom] = useState<string>()
  const [to, setTo] = useState<string>()
  const [limit, setLimit] = useState<number>(10)

  const fetchParams = useMemo(
    () => ({
      logType: typeSelectorFilter?.join(','),
      page: 1,
      limit: limit,
      fromDate: from,
      toDate: to,
      search: searchDebounce,
    }),
    [from, limit, to, typeSelectorFilter, searchDebounce],
  )
  useEffect(() => {
    dispatch(getProjectHistory(projectId, fetchParams))
  }, [dispatch, fetchParams, projectId, projectNotes])

  const handleCreateLog = useCallback(() => {
    if (!isStringEmpty(logValue)) {
      dispatch(
        createMannualLog(projectId, {text: logValue}, () => {
          dispatch(
            getProjectHistory(projectId, {
              logType: 'note,log',
              page: 1,
              limit: 10,
            }),
          )
        }),
      )
      setLogValue('')
    } else {
      toast.error('Please enter some text')
    }
  }, [dispatch, logValue, projectId, toast])

  return (
    <div className="min-h-[67vh]">
      <JobTitle>Notes and History</JobTitle>

      <DescriptionBox
        title="Notes and History"
        leftElement={
          <div className="flex items-center gap-10">
            <Checkbox
              dataId="notes"
              label="Notes"
              onChange={() => {
                if (!logs) {
                  toast.error('Either notes or logs have to be seleted!')
                } else {
                  setNotes((prev) => !prev)
                }
              }}
              value={notes}
              isChecked={notes}
            />
            {/* <Checkbox dataId="queries" label="Queries" />
            <Checkbox dataId="email" label="Emails" /> */}
            <Checkbox
              dataId="logs"
              label="Logs"
              onChange={() => {
                if (!notes) {
                  toast.error('Either notes or logs have to be seleted!')
                } else {
                  setLogs((prev) => !prev)
                }
              }}
              value={logs}
              isChecked={logs}
            />
            {/* <Checkbox
              dataId="checklist"
              label="Checklist"
              onChange={() => {
                setChecklist((prev) => !prev)
              }}
              value={checklist}
              isChecked={checklist}
            /> */}
            {/* <Checkbox dataId="sms" label="Sms" /> */}
          </div>
        }
        rightElement={
          <div className="flex items-center gap-10">
            {/* <Input
              name="city"
              size="sm"
              placeholder="Search "
              prepend={<HiSearch className="text-lg" />}
              containerClass="relative w-full form-group"
              // disabled
            /> */}
            <DateInput
              // type="date"
              onChange={(date) => {
                setFrom(date)
              }}
              className="DatePicker"
              name="date"
              size="sm"
              value={from ?? ''}
              selected={from && new Date(from)}
              placeholder="yyyy-mm-dd"
              // disabled
            />
            <FaArrowRight size={25} />
            <DateInput
              // type="date"
              onChange={(date) => {
                setTo(date)
              }}
              className="DatePicker"
              name="date"
              size="sm"
              value={to ?? ''}
              selected={to && new Date(to)}
              placeholder="yyyy-mm-dd"
              // disabled
            />
          </div>
        }
        subHeaderElement={
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleCreateLog()
            }}
            className="border-1 border-gray-200 bg-gray-200 px-16 py-10"
          >
            <div className="flex gap-10 items-center">
              <div className="mt-[10px]">
                <div className="flex items-center justify-between pb-10">
                  <Input
                    name="filterValue"
                    type="text"
                    // size="sm"
                    prepend={<FaSearch size={16} color={colors.gray[500]} />}
                    containerClass="w-[300px]"
                    placeholder="Search Notes"
                    className="w-full text-sm"
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex-1">
                <input
                  className="w-[100%]  py-4 px-10 rounded-sm h-[31px]"
                  name="addentry"
                  placeholder="Add a manual log entry"
                  onChange={(e) => setLogValue(e.target.value)}
                  value={logValue}
                  autoComplete="off"
                />
              </div>

              <div>
                <button className="border-1 border-gray-200 bg-white rounded-sm shadow-sm px-16 py-4 h-[31px]">
                  Add log Entry
                </button>
              </div>
            </div>
          </form>
        }
      >
        <div className="flex flex-col pt-20 px-20 mb-20">
          <HistoryListItem
            fetchParams={fetchParams}
            setLimit={setLimit}
            projectId={projectId}
          />
        </div>
      </DescriptionBox>
    </div>
  )
}
