import {
  GET_PROJECT_LIST,
  ADD_PROJECT,
  PROJECT_DETAIL_BY_ID,
  SIGNED_OFF_LIST,
  UPDATE_PROJECT_NUMBER_PREFIX,
  UPLOAD_PROJECT_IMAGE,
  DELETE_PROJECT_IMAGE,
  GET_PROJECT_LIST_BY_DEFINED_ID,
  GET_SUPPLIER_LIST_OF_PURCHASE_ORDER,
  GET_PROJECT_PURCHASE_ORDER,
  GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID,
  DOWNLOAD_PURCHASE_ORDER,
  GET_PROJECT_USER_LIST,
  GET_COMPANY_USER_TO_ADDED_IN_PROJECT,
  GET_PROJECT_USER_GROUP_LIST,
  GET_USER_GROUP_TO_ADDED_IN_PROJECT,
  GET_SELECTED_SITE_VISIT_TIMESHEET,
  UPDATE_PROJECT,
  GET_COMPANY_TIME_ENTRY,
  GET_PROJECT_FILES_AND_PHOTOS,
  GET_PROJECT_VARIATION_LIST,
  DELETE_INDIVIDUAL_PROJECT,
  ARCHIVE_INDIVIDUAL_PROJECT,
} from '../actions'

const initalState: RT.ProjectReduxType = {
  projectList: [],
  projectListTotal: 0,
  projectListLoading: false,

  add_loading: false,
  add_error: '',

  update_project_loading: false,

  projectDetailLoading: false,
  projectDetail: undefined,

  signedProjectListLoading: false,
  signedProjectList: [],

  projectNumberPrefixLoading: false,

  uploadProjectImageLoading: false,
  deleteProjectImageLoading: false,

  projectListByDefinedId: undefined,
  projectListByDefinedIdLoading: false,
  projectListByDefinedIdTotal: 0,

  purchaseSupplierList: undefined,
  getPurchaseSupplierListLoading: false,

  projectPurchaseOrder: undefined,
  getProjectPurchaseOrderLoading: false,
  projectPurchaseOrderTotal: 0,

  purchaseOrderListBySupplierId: undefined,
  getPurchaseOrderListByIdLoading: false,
  purchaseOrderListBySupplierIdTotal: 0,

  downloadPurchaseOrderLoading: false,

  projectUserList: undefined,
  getProjetUserListLoading: false,
  projectUserListTotal: 0,

  companyUsersToAddedInProject: undefined,
  companyUsersAddedToProjectLoading: false,

  projectUserGroupList: undefined,
  projectUserGroupListLoading: false,
  projectUserGroupListTotal: 0,

  userGroupToAddedInProject: undefined,
  userGroupToAddedInProjectLoading: false,

  selectedSitevisitLabourEntryTotal: 0,
  selectedSitevisitLabourEntry: undefined,
  selectedSitevisitLabourEntryLoading: false,

  companyTimeEntryLoading: false,
  companyTimeEntryList: undefined,
  companyTimeEntryTotal: 0,

  filesAndPhotosLoading: false,
  filesAndPhotos: undefined,
  filesAndPhotosTotal: 0,

  isProjectVariationError: false,
  isProjectVariationLoading: false,
  projectVariations: undefined,

  isDeleteProjectLoading: false,
  isArchiveProjectLoading: false,
}

export function projectReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case ADD_PROJECT.LOADING:
      return {...state, add_loading: true, add_error: false}
    case ADD_PROJECT.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: '',
      }
    case ADD_PROJECT.ERROR:
      return {...state, add_loading: false, add_error: payload}

    case UPDATE_PROJECT.LOADING:
      return {...state, update_project_loading: true}
    case UPDATE_PROJECT.SUCCESS:
      return {
        ...state,
        update_project_loading: false,
      }
    case UPDATE_PROJECT.ERROR:
      return {...state, update_project_loading: false}

    case GET_PROJECT_LIST.LOADING:
      return {...state, projectListLoading: true}
    case GET_PROJECT_LIST.SUCCESS:
      return {
        ...state,
        projectListLoading: false,
        projectList: payload.rows,
        projectListTotal: +payload.total,
      }
    case GET_PROJECT_LIST.ERROR:
      return {...state, projectListLoading: false}

    case PROJECT_DETAIL_BY_ID.LOADING:
      return {...state, projectDetailLoading: true}
    case PROJECT_DETAIL_BY_ID.SUCCESS:
      return {
        ...state,
        projectDetailLoading: false,
        projectDetail: payload,
      }
    case PROJECT_DETAIL_BY_ID.ERROR:
      return {...state, projectDetailLoading: false}

    case SIGNED_OFF_LIST.LOADING:
      return {...state, signedProjectListLoading: true}
    case SIGNED_OFF_LIST.SUCCESS:
      return {
        ...state,
        signedProjectListLoading: false,
        signedProjectList: payload,
      }
    case SIGNED_OFF_LIST.ERROR:
      return {...state, signedProjectListLoading: false}

    case UPDATE_PROJECT_NUMBER_PREFIX.LOADING:
      return {...state, projectNumberPrefixLoading: true}
    case UPDATE_PROJECT_NUMBER_PREFIX.SUCCESS:
      return {
        ...state,
        projectNumberPrefixLoading: false,
      }
    case UPDATE_PROJECT_NUMBER_PREFIX.ERROR:
      return {...state, projectNumberPrefixLoading: false}

    case UPLOAD_PROJECT_IMAGE.LOADING:
      return {...state, uploadProjectImageLoading: true}
    case UPLOAD_PROJECT_IMAGE.SUCCESS:
      return {
        ...state,
        uploadProjectImageLoading: false,
      }
    case UPLOAD_PROJECT_IMAGE.ERROR:
      return {...state, uploadProjectImageLoading: false}

    case DELETE_PROJECT_IMAGE.LOADING:
      return {...state, deleteProjectImageLoading: true}
    case DELETE_PROJECT_IMAGE.SUCCESS:
      return {
        ...state,
        deleteProjectImageLoading: false,
      }
    case DELETE_PROJECT_IMAGE.ERROR:
      return {...state, deleteProjectImageLoading: false}

    case GET_PROJECT_LIST_BY_DEFINED_ID.LOADING:
      return {...state, projectListByDefinedIdLoading: true}
    case GET_PROJECT_LIST_BY_DEFINED_ID.SUCCESS:
      return {
        ...state,
        projectListByDefinedIdLoading: false,
        projectListByDefinedId: payload.rows,
        projectListTotal: +payload.total,
      }
    case GET_PROJECT_LIST_BY_DEFINED_ID.ERROR:
      return {...state, projectListByDefinedIdLoading: false}

    case GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.LOADING:
      return {...state, getPurchaseSupplierListLoading: true}
    case GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.SUCCESS:
      return {
        ...state,
        getPurchaseSupplierListLoading: false,
        purchaseSupplierList: payload,
      }
    case GET_SUPPLIER_LIST_OF_PURCHASE_ORDER.ERROR:
      return {...state, getPurchaseSupplierListLoading: false}

    case GET_PROJECT_PURCHASE_ORDER.LOADING:
      return {...state, getProjectPurchaseOrderLoading: true}
    case GET_PROJECT_PURCHASE_ORDER.SUCCESS:
      return {
        ...state,
        getProjectPurchaseOrderLoading: false,
        projectPurchaseOrder: payload?.rows,
        projectPurchaseOrderTotal: +payload?.total,
      }
    case GET_PROJECT_PURCHASE_ORDER.ERROR:
      return {...state, getProjectPurchaseOrderLoading: false}

    case GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.LOADING:
      return {...state, getPurchaseOrderListByIdLoading: true}
    case GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.SUCCESS:
      return {
        ...state,
        getPurchaseOrderListByIdLoading: false,
        purchaseOrderListBySupplierId: payload.rows,
        purchaseOrderListBySupplierIdTotal: +payload.total,
      }
    case GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID.ERROR:
      return {...state, getPurchaseOrderListByIdLoading: false}

    case DOWNLOAD_PURCHASE_ORDER.LOADING:
      return {...state, downloadPurchaseOrderLoading: true}
    case DOWNLOAD_PURCHASE_ORDER.SUCCESS:
      return {
        ...state,
        downloadPurchaseOrderLoading: false,
      }
    case DOWNLOAD_PURCHASE_ORDER.ERROR:
      return {...state, downloadPurchaseOrderLoading: false}

    case GET_PROJECT_USER_LIST.LOADING:
      return {
        ...state,
        getProjetUserListLoading: true,
      }
    case GET_PROJECT_USER_LIST.SUCCESS:
      return {
        ...state,
        getProjetUserListLoading: false,
        projectUserList: payload.rows,
        projectUserListTotal: payload.total,
        // purchaseOrderListBySupplierIdTotal: +payload.total,
      }
    case GET_PROJECT_USER_LIST.ERROR:
      return {...state, getProjetUserListLoading: false}

    case GET_COMPANY_USER_TO_ADDED_IN_PROJECT.LOADING:
      return {
        ...state,
        companyUsersAddedToProjectLoading: true,
      }
    case GET_COMPANY_USER_TO_ADDED_IN_PROJECT.SUCCESS:
      return {
        ...state,
        companyUsersAddedToProjectLoading: false,
        companyUsersToAddedInProject: payload,
      }
    case GET_COMPANY_USER_TO_ADDED_IN_PROJECT.ERROR:
      return {...state, companyUsersAddedToProjectLoading: false}

    case GET_PROJECT_USER_GROUP_LIST.LOADING:
      return {
        ...state,
        projectUserGroupListLoading: true,
      }
    case GET_PROJECT_USER_GROUP_LIST.SUCCESS:
      return {
        ...state,
        projectUserGroupListLoading: false,
        projectUserGroupList: payload.rows,
        projectUserGroupListTotal: payload.total,
        // purchaseOrderListBySupplierIdTotal: +payload.total,
      }
    case GET_PROJECT_USER_GROUP_LIST.ERROR:
      return {...state, projectUserGroupListLoading: false}

    case GET_USER_GROUP_TO_ADDED_IN_PROJECT.LOADING:
      return {
        ...state,
        userGroupToAddedInProjectLoading: true,
      }
    case GET_USER_GROUP_TO_ADDED_IN_PROJECT.SUCCESS:
      return {
        ...state,
        userGroupToAddedInProjectLoading: false,
        userGroupToAddedInProject: payload,
      }
    case GET_USER_GROUP_TO_ADDED_IN_PROJECT.ERROR:
      return {...state, userGroupToAddedInProjectLoading: false}

    case GET_SELECTED_SITE_VISIT_TIMESHEET.LOADING:
      return {
        ...state,
        selectedSitevisitLabourEntryLoading: true,
      }
    case GET_SELECTED_SITE_VISIT_TIMESHEET.SUCCESS:
      return {
        ...state,
        selectedSitevisitLabourEntryLoading: false,
        selectedSitevisitLabourEntry: payload.rows,
        selectedSitevisitLabourEntryTotal: payload.total,
      }
    case GET_SELECTED_SITE_VISIT_TIMESHEET.ERROR:
      return {...state, selectedSitevisitLabourEntryLoading: false}

    case GET_COMPANY_TIME_ENTRY.LOADING:
      return {
        ...state,
        companyTimeEntryLoading: true,
      }
    case GET_COMPANY_TIME_ENTRY.SUCCESS:
      return {
        ...state,
        companyTimeEntryLoading: false,
        companyTimeEntryList: payload.rows,
        companyTimeEntryTotal: +payload.total,
      }
    case GET_COMPANY_TIME_ENTRY.ERROR:
      return {...state, companyTimeEntryLoading: false}

    case GET_PROJECT_FILES_AND_PHOTOS.LOADING:
      return {
        ...state,
        filesAndPhotosLoading: true,
      }
    case GET_PROJECT_FILES_AND_PHOTOS.SUCCESS:
      return {
        ...state,
        filesAndPhotosLoading: false,
        filesAndPhotos: payload.rows,
        filesAndPhotosTotal: payload.total,
      }
    case GET_PROJECT_FILES_AND_PHOTOS.ERROR:
      return {...state, filesAndPhotosLoading: false}

    // * Variation list
    case GET_PROJECT_VARIATION_LIST.SUCCESS:
      return {
        ...state,
        projectVariations: payload,
        isProjectVariationLoading: false,
        isProjectVariationError: false,
      }
    case GET_PROJECT_VARIATION_LIST.ERROR:
      return {
        ...state,
        isProjectVariationLoading: false,
        isProjectVariationError: true,
      }

    case GET_PROJECT_VARIATION_LIST.LOADING:
      return {
        ...state,
        isProjectVariationLoading: true,
        isProjectVariationError: false,
      }

    // * Delete Individual Project
    case DELETE_INDIVIDUAL_PROJECT.SUCCESS:
      return {
        ...state,
        isDeleteProjectLoading: false,
      }
    case DELETE_INDIVIDUAL_PROJECT.ERROR:
      return {
        ...state,
        isDeleteProjectLoading: false,
      }

    case DELETE_INDIVIDUAL_PROJECT.LOADING:
      return {
        ...state,
        isDeleteProjectLoading: true,
      }

    // * Archive Individual Project
    case ARCHIVE_INDIVIDUAL_PROJECT.SUCCESS:
      return {
        ...state,
        isArchiveProjectLoading: false,
      }
    case ARCHIVE_INDIVIDUAL_PROJECT.ERROR:
      return {
        ...state,
        isArchiveProjectLoading: false,
      }

    case ARCHIVE_INDIVIDUAL_PROJECT.LOADING:
      return {
        ...state,
        isArchiveProjectLoading: true,
      }

    default:
      return state
  }
}
