import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'

import {Button, Input, Modal, SelectField, toast} from 'app/common'
import {DynamicSearchSelect} from 'app/components'

import {
  updateMaterialElementAction,
  createMaterialElementAction,
  getMaterialElementAction,
  getMaterialUnitListAction,
  uploadMaterialElementFile,
} from 'redux-src'

import {MaterialElementDetails} from '../materialElementTable'

interface AddMaterialElementProps {
  addMaterialElementModal: boolean
  setMaterialElementModal: React.Dispatch<React.SetStateAction<boolean>>
  mode?: 'edit' | 'attach'
  materialElementDetails?: MaterialElementDetails
}

export const AddMaterialElement = ({
  addMaterialElementModal,
  setMaterialElementModal,
  mode,
  materialElementDetails,
}: AddMaterialElementProps) => {
  const {materialElementUnitList: unitList}: RT.MaterialElementReduxType =
    useSelector((state: any) => state.materialElement)

  const dispatch = useDispatch()

  const [data, {onChange, onSubmit, errors, modified, clear, setValue}] =
    useFormInput<{
      name: string
      noPerUnit: number
      unitName: string
      cost: number
      supplier?: {id: number; value: string; label: string}
    }>(
      {
        name: '',
        noPerUnit: 0,
        unitName: '',
        cost: 0,
        supplier: undefined,
      },
      (data) => {
        // return
        if (mode !== 'edit') {
          dispatch(
            createMaterialElementAction(
              {
                name: data.name,
                unitName: data.unitName,
                numberPerUnit: Number(data.noPerUnit),
                supplierId: data?.supplier?.id,
                cost: data?.cost,
              },
              () => {
                clear()
                setMaterialElementModal(false)
                dispatch(getMaterialElementAction())
              },
            ),
          )
        } else {
          dispatch(
            updateMaterialElementAction(
              materialElementDetails.id,
              {
                name: data.name,
                unitName: data.unitName,
                numberPerUnit: Number(data.noPerUnit),
                supplierId: data?.supplier?.id,
                cost: data?.cost,
              },
              () => {
                clear()
                setMaterialElementModal(false)
                dispatch(getMaterialElementAction())
              },
            ),
          )
        }
      },
      (data) => {
        const errors: any = {}

        if (data.name.length === 0) {
          errors.name = 'Please enter material element name!'
        } else if (data.unitName.length === 0) {
          errors.unitName = 'Please select an unit!'
        } else if (data.noPerUnit < 1) {
          errors.noPerUnit = 'Please enter no. per unit!'
        } else if (
          data.supplier === undefined ||
          data.supplier?.id === 0 ||
          typeof data.supplier === 'string'
        ) {
          errors.supplier = 'Please enter no. per unit!'
        } else if (data.cost < 1) {
          errors.cost = 'Please enter cost!'
        }

        return errors
      },
    )

  const newUnitList = useMemo(() => {
    const finalUnitList = unitList?.map((lists: any, idx: number) => {
      return {
        id: idx + 1,
        label: lists.unit_details.name,
        value: lists.unit_details.symbol,
      }
    })

    return finalUnitList
  }, [unitList])

  const prePopulatedValue = useCallback(() => {
    if (!!materialElementDetails) {
      setValue('cost', materialElementDetails?.cost)
      setValue('name', materialElementDetails?.name)
      setValue('noPerUnit', materialElementDetails?.numberPerUnit)
      setValue('supplier', materialElementDetails?.supplier)
      setValue('unitName', materialElementDetails?.unitName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialElementDetails])

  // MARK: Clear Fields
  const clearFields = () => {
    setMaterialElementModal(false)
    if (mode !== 'edit') {
      clear()
    }
  }

  useEffect(() => {
    dispatch(getMaterialUnitListAction())
  }, [dispatch])

  useEffect(() => {
    prePopulatedValue()
  }, [prePopulatedValue])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const acceptedFileTypes = useMemo(
    () => [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ],
    [],
  )

  // const [file, setFile] = useState<File>()

  const handleFileSelection = (file: File) => {
    // const file = file

    if (!file) {
      toast.error('Please select a file in order to import labour elements.')
      setHasFileError(true)
    } else {
      if (acceptedFileTypes.includes(file.type)) {
        // * The type of file is excel or csv
        const formData = new FormData()
        formData.append('csvFile', file)

        if (file.type === 'text/csv') {
          dispatch(
            uploadMaterialElementFile(formData, 'csv', () => {
              setMaterialElementModal(false)
              dispatch(getMaterialElementAction())
            }),
          )
        } else {
          dispatch(
            uploadMaterialElementFile(formData, 'excel', () => {
              setMaterialElementModal(false)
              dispatch(getMaterialElementAction())
            }),
          )
        }
        setHasFileError(false)
      } else {
        toast.error(
          'Please select a valid file type. Accepted file type includes csv and excel.',
        )
        setHasFileError(true)
      }
      // e.target.value = null
      // e.target.files = null
    }
  }

  const [dragActive, setDragActive] = useState<boolean>(false)

  const [hasFileError, setHasFileError] = useState<boolean>(false)

  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileSelection(e.dataTransfer.files[0])
    }
  }

  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else {
      setDragActive(false)
    }
  }

  return (
    <Modal
      isActive={addMaterialElementModal}
      toggleModal={setMaterialElementModal}
      title={
        mode === 'edit'
          ? 'Update a material element'
          : 'Add a new material element'
      }
      onClose={clearFields}
    >
      <form
        id="material-element-type"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(e)
        }}
      >
        <div className="pt-[18px] px-[20px]">
          {' '}
          {mode !== 'edit' && (
            <>
              <input
                id="labour-elements-file"
                type="file"
                accept={String(acceptedFileTypes)}
                className="hidden"
                ref={fileInputRef}
                onChange={(e) => {
                  handleFileSelection(e?.target?.files[0])
                }}
                // value={file}
              />

              <label
                htmlFor="labour-elements-file"
                className={
                  'cursor-pointer border-1 w-full h-[110px] rounded-sm mb-20 flex justify-center items-center flex-col ' +
                  `${
                    dragActive
                      ? 'bg-gray-200 border-gray-300'
                      : hasFileError
                      ? 'bg-red-100 border-red-300'
                      : 'bg-blue-100 border-blue-200'
                  }`
                }
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onClick={() => {
                  if (fileInputRef) {
                    fileInputRef.current.click()
                  }
                }}
              >
                <label className="cursor-pointer text-gray-400 mt-auto">
                  Use these titles{' '}
                  <i>
                    "name", "unit_name", "cost", "supplier", "number_per_unit"
                  </i>{' '}
                  in your file compulsorily.
                </label>
                <label className="cursor-pointer text-gray-400 mt-auto">
                  Drag & drop your material files <i>[.csv,.xlsx,.xls] </i>
                  here or
                </label>
                <label className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400">
                  Choose file
                </label>
              </label>
            </>
          )}
          <label htmlFor="name" className="flex flex-col gap-6">
            <div className="flex gap-4">
              <span>Name</span>
              <span className="text-red-500">*</span>
            </div>
            <Input
              type="text"
              name="name"
              value={data.name}
              onChange={onChange}
              error={errors.name && modified.name}
              placeholder="Name"
              required
            />
            {modified.name && errors.name && (
              <span className="text-red-500 -mt-10">{errors.name}</span>
            )}
          </label>
          <div className="flex justify-between">
            <span className="flex flex-col gap-6 w-1/2 ">
              <div className="flex gap-4">
                <span>Unit Name</span>
                <span className="text-red-500">*</span>
              </div>
              <SelectField
                options={newUnitList}
                value={newUnitList?.find((item) => {
                  return item.label === data.unitName
                })}
                onChangeValue={(e) => setValue('unitName', e.label)}
                placeholder={'Select unit name'}
                formatOptionLabel={(option) => {
                  return (
                    <div>
                      <span>{option.label}</span> -{' '}
                      <i className="text-gray-400">{option.value}</i>
                    </div>
                  )
                }}
              />
              {modified.unitName && errors.unitName && (
                <span className="text-red-500">{errors.unitName}</span>
              )}
            </span>
            <label htmlFor="timeMins" className="flex flex-col gap-6">
              <div className="flex gap-4">
                <span>No. Per Unit</span>
                <span className="text-red-500">*</span>
              </div>
              <Input
                type="number"
                name="noPerUnit"
                value={data.noPerUnit}
                onChange={onChange}
                error={errors.noPerUnit && modified.noPerUnit}
                placeholder="No. Per Unit"
                required
                inputType={'number'}
              />
              {modified.noPerUnit && errors.noPerUnit && (
                <span className="text-red-500">{errors.noPerUnit}</span>
              )}
            </label>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-6 w-1/2 ">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex gap-4">
                  <span>Supplier</span>
                  <span className="text-red-500">*</span>
                </div>
                <div>
                  <DynamicSearchSelect
                    actionUrl="/suppliers"
                    value={data?.supplier ?? undefined}
                    remapOptions={(data: Api.SupplierType[]) => {
                      return data?.map(({supplier_details}) => {
                        return {
                          id: supplier_details?.id,
                          label: supplier_details?.name,
                          value: supplier_details?.name,
                        }
                      })
                    }}
                    onChangeValue={(item) => {
                      setValue('supplier', item)
                    }}
                  />
                  {modified.supplier && errors.supplier && (
                    <span className="text-red-500">{errors.supplier}</span>
                  )}
                </div>
              </div>
            </div>
            <label htmlFor="cost" className="flex flex-col gap-6">
              <div className="flex gap-4">
                <span>Cost</span>
                <span className="text-red-500">*</span>
              </div>
              <Input
                name="cost"
                value={data.cost}
                onChange={onChange}
                error={errors.cost && modified.cost}
                placeholder="Cost"
                required
                inputType={'decimal'}
              />
              {modified.cost && errors.cost && (
                <span className="text-red-500">{errors.cost}</span>
              )}
            </label>
          </div>
        </div>
        <div className="border border-t-1 border-gray-200 px-20 py-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => clearFields()}
            >
              Cancel
            </Button>
            <Button
              form="material-element-type"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode !== 'edit' ? 'Save' : 'Edit'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
