import React, {useEffect} from 'react'
import {useAuth, ActiveLink, useNavigation} from 'react-auth-navigation'
import {FaBell, FaCog} from 'react-icons/fa'
import {MdSearch} from 'react-icons/md'

import {NavLink, NotificationModal} from './components'
import {UserProfile} from './components/userProfile'
import {NAV_LINKS} from './constants'
import {useMedia} from 'hooks'
import {ResponsiveOverlay} from '../responsiveOverlay'
import {getNotice, getNotifications} from 'redux-src'
import {useDispatch, useSelector} from 'react-redux'

const IconItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <span
      onClick={onClick}
      className="relative block text-white text-lg p-4 cursor-pointer"
    >
      {children}
    </span>
  )
}

export const Header = () => {
  const {isLoggedIn} = useAuth()
  const {
    navigation: {routes},
  } = useNavigation()

  const {lg} = useMedia()

  // Disable scrolling when notification is on

  // useEffect(() => {
  //   if (isNotificationShown) {
  //     document.body.style.overflow = 'hidden'
  //   } else {
  //     document.body.style.overflow = 'scroll'
  //   }
  // }, [isNotificationShown])

  // Fetch notification here

  const dispatch = useDispatch()
  // * refetch notification every 10 seconds
  useEffect(() => {
    let notifFetchTimer: NodeJS.Timer
    if (isLoggedIn) {
      notifFetchTimer = setInterval(() => {
        dispatch(getNotifications({page: 1, limit: 0}))
        dispatch(getNotice())
      }, 1000 * 60)
    } else {
      clearInterval(notifFetchTimer)
    }

    return () => {
      clearInterval(notifFetchTimer)
    }
  }, [dispatch, isLoggedIn])

  const {unreadCount}: RT.NotificationReduxType = useSelector(
    (state: any) => state.notifications,
  )

  return (
    isLoggedIn &&
    !routes['FormBuilderWebView']?.active &&
    !routes['TicketPlanPositionWebView']?.active && (
      <>
        <div className="w-full fixed bg-gray-600 z-[102]">
          <div className="container z-100 px-16 py-10 mx-auto">
            <nav className="flex justify-between">
              <div className="flex items-center space-x-3 ">
                <ActiveLink to="/new-project">
                  <button className="bg-blue-300 hover:bg-blue-400 px-20 py-8  text-sm text-white cursor-pointer rounded-sm whitespace-nowrap font-bold">
                    <span className="my-6">New Project</span>
                  </button>
                </ActiveLink>

                <div className="mx-16 flex justify-between text-black">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-8 flex items-center pointer-events-none">
                      <MdSearch className="text-lg" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="focus:outline-none block w-full  pl-32 py-8 sm:text-sm border-gray-300 rounded-sm"
                      placeholder="Search"
                    />
                  </div>
                </div>

                <ul className="flex gap-6">
                  {NAV_LINKS.map(({to, title, premium}, index) => (
                    <li key={index}>
                      <NavLink to={to} title={title} premium={premium} />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex w-auto justify-end items-center px-10 gap-16 border-l-1 border-gray-400">
                {/* <IconItem>
                  <FaClock size={20} />
                </IconItem>

                <IconItem>
                  <FaCheckSquare size={20} />
                </IconItem> */}

                {/* Notification icon */}
                <NotificationModal
                  displayElement={
                    <IconItem>
                      <div className="notification-wrapper relative flex">
                        <FaBell size={20} />
                        {!!unreadCount && unreadCount > 0 && (
                          <div className="unread_badge absolute right-0 translate-x-[30%] -translate-y-[5px] text-xs flex justify-center align-center bg-[#e50000] w-max min-w-[15px] h-[15px] rounded-full px-[2px]">
                            {unreadCount}
                          </div>
                        )}
                      </div>
                    </IconItem>
                  }
                />
                {/* <IconItem>
                  <div className="notification-wrapper relative flex">
                    <FaBell size={20} />
                  </div>
                </IconItem> */}

                {/* <IconItem>
                  <FaQuestionCircle size={20} />
                </IconItem> */}

                <IconItem>
                  <ActiveLink to="/settings">
                    <FaCog size={20} />
                  </ActiveLink>
                </IconItem>

                <UserProfile />
              </div>
            </nav>
          </div>
        </div>
        {!lg && <ResponsiveOverlay />}
        <div className="h-[52px]" />
      </>
    )
  )
}
