import {useState, useContext} from 'react'

import {Button, Input, SelectField} from 'app/common'
import {addNewUserSelectOptions} from '../usersMainPage/constants'
import {ChangePassword} from './components'
import {useAuth} from 'react-auth-navigation'
import {UserDetailsContext} from '../userDetailsMainPage'
import {DescriptionBox} from 'app/components'
import {AddressTable} from 'app/pages/customers/components'
import {BiError} from 'react-icons/bi'

export const UserDetails = () => {
  const [openChangePassword, setOpenChangePassword] = useState(false)

  const {currencySymbol} = useAuth()

  const {
    data,
    onChangeHandler,
    setUserType,
    userType,
    physicalAddressData,
    physicalAddressHandler,
    isSaveSectionVisible,
    handleUndoChanges,
    onUpdateCompanyUser,
  } = useContext(UserDetailsContext)

  return (
    <div className="text-gray-500">
      {/* USER DETAILS FORM */}
      <div className="flex gap-16 mt-12">
        <Input
          label="Firstname"
          name="firstName"
          placeholder="John"
          size="md"
          value={data?.firstName}
          onChange={onChangeHandler('firstName')}
        />
        <Input
          label="Lastname"
          name="lastName"
          placeholder="Smith"
          size="md"
          value={data?.lastName}
          onChange={onChangeHandler('lastName')}
        />
        <Input
          label="Email"
          type="email"
          name="loginEmail"
          placeholder="john@gmail.com"
          size="md"
          value={data?.loginEmail}
          onChange={onChangeHandler('loginEmail')}
        />
      </div>

      <div className="flex gap-16  w-full">
        <div className="flex flex-col gap-6 w-full">
          <Input
            label="Mobile phone"
            placeholder="9874512546"
            type="number"
            size="md"
            name="phoneNumber"
            value={data?.phoneNumber}
            onChange={onChangeHandler('phoneNumber')}
          />
        </div>
        <div className="flex flex-col gap-6 w-full">
          <div>User Type</div>
          {userType === 'System Admin' ? (
            <Input
              placeholder="Select User Type"
              size="md"
              name="userType"
              defaultValue={userType}
              disabled
            />
          ) : (
            <SelectField
              options={addNewUserSelectOptions}
              onChangeValue={(e) => {
                setUserType(e.value)
              }}
              //@ts-ignore
              value={addNewUserSelectOptions?.filter((item) => {
                return item?.value === userType
              })}
              placeholder="Select User Type"
              className="w-full"
            />
          )}
        </div>

        <div className="flex flex-col w-full">
          <form className="flex gap-20 w-full">
            <Input
              type="number"
              label="Standard charge out rate"
              prepend={currencySymbol}
              size="md"
              name="payRates"
              onChange={onChangeHandler('payRates')}
              value={data?.payRates}
              className="rounded-tr-sm rounded-br-sm text-gray-400 "
              placeholder="Standard charge out rate"
            />
          </form>
        </div>
      </div>

      <div className="mb-14">
        <DescriptionBox
          title={
            <div className="flex items-center align-center justify-between text-sm text-gray-700 ml-20">
              <h4 className="flex font-bold text-md justify-between">
                Address
              </h4>

              <div
                className={`border-1 px-8 py-3 rounded-[2px] border-red-300 text-white font-normal cursor-pointer ${
                  !physicalAddressData?.address
                    ? 'bg-red-200 border-red-200'
                    : 'bg-red-300'
                }`}
                onClick={() => {
                  if (!!physicalAddressData?.address) {
                    physicalAddressHandler.clear()
                  }
                }}
              >
                Clear
              </div>
            </div>
          }
        >
          {physicalAddressHandler?.errors.address && (
            <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
              {physicalAddressHandler?.errors.address}
            </p>
          )}
          <AddressTable
            addressProps={{
              addressData: physicalAddressData,
              addressHandler: physicalAddressHandler,
            }}
          />
        </DescriptionBox>
      </div>

      {/* PASSWORD SECTION */}
      {/* {!openChangePassword && ( */}
      <p
        onClick={() => setOpenChangePassword((prev) => !prev)}
        className="text-blue-300 cursor-pointer hover:underline inline-block"
      >
        Change password
      </p>
      {/* )} */}
      {/* {openChangePassword && ( */}
      <ChangePassword
        openModel={openChangePassword}
        setOpenModel={setOpenChangePassword}
      />
      {/* )} */}

      {isSaveSectionVisible && (
        <div className="flex justify-between sticky bottom-0 mt-20 py-20 border-t-1 border-gray-200 bg-white">
          <div className="flex gap-10 items-center">
            <span className="text-yellow-300">
              <BiError fontSize={14} />
            </span>
            <span className="text-gray-400">
              Don't forget to save your changes
            </span>
          </div>
          <div className="flex flex-row justify-center items-center">
            <span
              className="text-blue-300 cursor-pointer mr-10 capitalize"
              onClick={handleUndoChanges}
            >
              undo changes
            </span>
            <Button title="Save" onClick={onUpdateCompanyUser} />
          </div>
        </div>
      )}
    </div>
  )
}
