import {useDispatch, useSelector} from 'react-redux'
import {TableColumn} from 'react-data-table-component'
import {ActiveLink, useAuth, useNavigation} from 'react-auth-navigation'
import {saveAs} from 'file-saver'
import moment from 'moment'
import {HiDotsVertical} from 'react-icons/hi'

import {DataTable, MessageBox} from 'app/components'
import {Button, Dropdown, Modal, Textarea} from 'app/common'

import {
  addRemarksToInvoice,
  deleteInvoiceAction,
  enterActiveInvoice,
  getInvoiceById,
  getInvoicesAction,
} from 'redux-src'
import {LOCAL_FILE_URL} from 'config'
import {useEffect, useState} from 'react'
import {useFormInput} from 'use-form-input'

export const InvoiceTablePage = ({
  activeQuoteId,
  setActiveQuoteId,
}: {
  activeQuoteId: number
  setActiveQuoteId: (a: any) => void
}) => {
  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const dispatch = useDispatch()

  const getInvoices = (page?: number, tableLimit?: number) => {
    dispatch(getInvoicesAction(projectId, page, tableLimit))
  }

  const [showModal, setShowModal] = useState<boolean>(false)
  const [invoiceDetails, setInvoiceDetails] = useState<{
    invoiceId: number
    remarks?: string
  }>()

  const {projectId} = params as any
  const {currencySymbol, toast} = useAuth()
  const {invoices, invoiceTotal}: RT.ProjectInvoicingReduxType = useSelector(
    (state: any) => state.invoices,
  )

  const onDraftDeleteHandler = (invoiceId: any) => {
    dispatch(
      deleteInvoiceAction(
        invoiceId,
        toast,
        () => {
          navigate(`/projects/${projectId}/invoicing`)
          getInvoices()
        },
        () => {},
      ),
    )
  }

  const columns: TableColumn<any>[] = [
    {
      name: 'Invoicing',
      selector: (row) => row.invoice_details?.name,
      sortable: true,
      cell: (row: any) => (
        <div
          className="flex badge items-center"
          onClick={() => {
            dispatch(enterActiveInvoice(row.invoice_details))
          }}
        >
          <ActiveLink
            to={`/projects/${projectId}/invoicing/${row.invoice_details?.id}`}
            className="text-blue-300"
          >
            {row.invoice_details?.invoice_number}
          </ActiveLink>
        </div>
      ),
    },
    {
      name: 'Type',
      selector: (row) => row.invoice_details?.type,
      sortable: true,
      cell: (row: any) =>
        row?.invoice_details?.is_super_seeded ? (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-700">
            SUPERSEDED
          </div>
        ) : (
          getChipData(row?.invoice_details?.type)
        ),
    },
    {
      name: 'Delivery',
      selector: (row) => row.invoice_details?.delivery,
      sortable: true,
      cell: (row: any) =>
        row.invoice_details?.delivery === 'sent' ? (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-blue-300">
            SENT
          </div>
        ) : (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-300">
            NOT SENT
          </div>
        ),
    },
    {
      name: 'Status',
      selector: (row) => row.invoice_details?.status,
      sortable: true,
      cell: (row: any) =>
        row?.invoice_details?.is_super_seeded ? (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-700">
            SUPERSEDED
          </div>
        ) : (
          getInvoiceStatusChip(row?.invoice_details?.status)
        ),
    },
    {
      name: 'Created',
      selector: (row) => row.invoice_details?.created_at,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.invoice_details?.id}`}
            className="text-black"
          >
            {moment(row.invoice_details?.created_at).format('DD/MM/YYYY')}
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Sub Total
        </div>
      ),
      selector: (row) => row.invoice_details?.sub_total,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.invoice_details?.id}`}
            className="text-black"
          >
            {currencySymbol}
            {row?.invoice_details?.discounted_cost}
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total (With Tax)
        </div>
      ),
      selector: (row) => row.invoice_details?.total,

      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div className="text-black">
            {currencySymbol}
            {row?.invoice_details?.total_cost}
          </div>
        </div>
      ),
    },
    {
      name: 'Remarks',
      selector: (row) => row.invoice_details?.delivery,
      sortable: true,
      cell: (row: any) => (
        <div
          className="w-[max-content] text-sm px-10 py-4 text-blue-200 cursor-pointer font-medium"
          onClick={() => {
            setInvoiceDetails({
              invoiceId: row.invoice_details?.id,
              remarks: row.invoice_details?.remarks,
            })
            setShowModal((prev) => !prev)
          }}
        >
          View Remarks
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Actions
        </div>
      ),
      cell: (row: any, index: number) => (
        <div className="flex items-center justify-center">
          <Dropdown
            triggerToggle
            triggerElement={
              <div className="text-sm bg-gray-100 p-6 -mb-8 border-1 border-gray-300 rounded-sm hover:bg-gray-200 cursor-pointer">
                <HiDotsVertical />
              </div>
            }
            placement={
              // (index >= 5 && index <= 9) || index === invoices?.length - 1
              //   ? index === 0
              //     ? 'topright'
              //     : 'bottomright'
              //   : 'bottomright'
              index === 0 || index === invoices?.length - 1
                ? 'topright'
                : 'bottomright'
            }
          >
            <div className="bg-white shadow-lg rounded-sm overflow-hidden text-black mt-10 text-sm">
              <div
                className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                onClick={() => {
                  dispatch(enterActiveInvoice(row.invoice_details))
                  dispatch(getInvoiceById(projectId, row.invoice_details?.id))
                  navigate(
                    `/projects/${projectId}/invoicing/${row.invoice_details?.id}`,
                  )
                }}
              >
                {row.invoice_details?.status === 'draft'
                  ? 'Edit Draft'
                  : 'View Invoice'}
              </div>

              {row.invoice_details?.pdf && (
                <>
                  <a
                    href={`${LOCAL_FILE_URL}/assets/invoice/${row.invoice_details?.pdf}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                      // onClick={onToBeApprovedHandler}
                    >
                      View PDF
                    </div>
                  </a>
                  <div
                    className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                    onClick={() =>
                      saveAs(
                        `${LOCAL_FILE_URL}/assets/invoice/${row.invoice_details?.pdf}`,
                        `${row.invoice_details?.name}.pdf`,
                      )
                    }
                  >
                    Download PDF
                  </div>
                </>
              )}

              {row.invoice_details?.status === 'draft' && (
                <div
                  className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                  onClick={() => onDraftDeleteHandler(row.invoice_details?.id)}
                >
                  Delete Draft
                </div>
              )}
            </div>
          </Dropdown>
        </div>
      ),
    },
  ]

  return (
    <>
      <DataTable
        columns={columns}
        data={Array.isArray(invoices) ? invoices : []}
        noDataComponent={
          <MessageBox message="There are not any invoices created!" error />
        }
        pagination
        persistTableHead={false}
        dense
        striped
        totalRows={invoiceTotal}
        fetchData={getInvoices}
      />

      <ViewRemarksModal
        setShowModal={setShowModal}
        showModal={showModal}
        invoiceDetails={invoiceDetails}
      />
    </>
  )
}

const ViewRemarksModal = ({
  showModal,
  setShowModal,
  invoiceDetails,
}: {
  showModal: boolean
  setShowModal: any
  invoiceDetails: {
    invoiceId: number
    remarks?: string
  }
}) => {
  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const [data, {setValue, onChange: onChangeHandler}] = useFormInput({
    remarks: undefined,
  })

  const onUpdateValidDate = () => {
    if (true) {
      const body = {
        remarks: data?.remarks?.length > 0 ? data?.remarks : undefined,
      }

      dispatch(
        addRemarksToInvoice(projectId, invoiceDetails?.invoiceId, body, () => {
          dispatch(getInvoicesAction(projectId, 1, 10))
          setShowModal(false)
        }),
      )
    }
  }

  useEffect(() => {
    setValue('remarks', invoiceDetails?.remarks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails])

  return (
    <Modal
      title="Update Due Date"
      toggleModal={setShowModal}
      isActive={showModal}
    >
      <div className="flex flex-col w-full px-20 py-10 min-h-[200px]">
        <div className="flex w-full h-full">
          <Textarea
            className="text-sm h-full"
            name="remarks"
            placeholder={`Remarks`}
            size="sm"
            value={data?.remarks}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div className="flex gap-10 items-center mt-10 py-10 px-10 justify-end border-t-1 border-gray-200">
        <Button
          title="Cancel"
          onClick={() => {
            setShowModal(false)
          }}
          buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
        />
        <Button
          title="Update"
          onClick={onUpdateValidDate}
          disabled={data?.remarks?.length === 0}
        />
      </div>
    </Modal>
  )
}

const getInvoiceStatusChip = (value: string) => {
  const chipColor = {
    voided: 'bg-gray-400',
    unpaid: 'bg-red-400',
    disputed: 'bg-orange-400',
    paid: 'bg-green-400',
  }

  return (
    <div
      key={value}
      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
        chipColor[value as keyof typeof chipColor]
      }`}
      // style={{
      //   backgroundColor: chipColor[value as keyof typeof chipColor]
      // }}
    >
      {value}
    </div>
  )
}

const getChipData = (value: string) => {
  const chipColor = {
    draft: 'bg-orange-400',
    approved: 'bg-green-500',
  }

  return (
    <div
      key={value}
      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
        chipColor[value as keyof typeof chipColor]
      }`}
      // style={{
      //   backgroundColor: chipColor[value as keyof typeof chipColor]
      // }}
    >
      {value}
    </div>
  )
}
