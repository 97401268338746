import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {
  acceptQuoteAction,
  changeAcceptedQuoteAction,
  declineQuoteAction,
  getCompanyDetails,
  getProjectByIdAction,
  getQuotesAction,
  getSiteVisitListAction,
  markAsSentQuoteAction,
  publishAndMarkAsSentQuoteAction,
  publishQuoteAction,
  RootState,
} from 'redux-src'
import {Button, ConfirmationModal, CustomModal, Dropdown} from 'app/common'
import {useEffect, useMemo} from 'react'
import {FiAlertTriangle} from 'react-icons/fi'
import {BiPlusCircle} from 'react-icons/bi'

export const ButtonAction = ({
  projectId,
  activeQuoteId,
  quoteDetails,
  generateQuotePDF,
  quote,
}: {
  projectId: number
  activeQuoteId: number
  quoteDetails: any
  generateQuotePDF: () => void
  quote: Api.QuoteDetailsById
}) => {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )
  const navigation = useNavigation()

  const dispatch = useDispatch()
  const {toast, currencySymbol} = useAuth()
  useEffect(() => {
    dispatch(getCompanyDetails())
  }, [dispatch])

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: RootState) => state.company,
  )

  const onPublishHandler = () => {
    dispatch(
      publishQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onPublishandMarkAsSentHandler = () => {
    dispatch(
      publishAndMarkAsSentQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onSendHandler = () => {
    dispatch(
      markAsSentQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onAccecptHandler = (body?: any) => {
    dispatch(
      acceptQuoteAction(
        projectId,
        activeQuoteId,
        body,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onChangeAcceptedQuote = () => {
    dispatch(
      changeAcceptedQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onDeclineHandler = () => {
    dispatch(
      declineQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  // MARK : During Quote Accept

  const quoteProceedActions = useMemo(() => {
    let canQuoteAcceptProceed = ''

    if (projectDetail?.project_details?.total_prelim_cost !== 0) {
      canQuoteAcceptProceed = 'include_option'
    } else {
      canQuoteAcceptProceed = 'warn_user'
    }

    return canQuoteAcceptProceed
  }, [projectDetail?.project_details?.total_prelim_cost])

  const getButtonGroup = (status: string, delivery?: boolean) => {
    switch (true) {
      case status === 'draft':
        return (
          <>
            {quote?.length > 0 &&
            quote.some((value) => {
              return !!value.line_item_list
            }) &&
            !!companyDetails?.company_bank_details?.account_number &&
            !!companyDetails?.company_details?.tax_number ? (
              <Dropdown
                triggerElement={
                  <button
                    type="button"
                    className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm hover:bg-blue-400 hover:text-blue-700 active:bg-blue-400 active:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  >
                    Publish
                  </button>
                }
                inDismiss
                placement="bottomright"
              >
                <div className="w-[200px] bg-white border-1 border-gray-200 rounded-sm">
                  <div
                    className="flex pl-14 py-8 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
                    onClick={onPublishHandler}
                  >
                    Publish
                  </div>

                  <div
                    className="flex  pl-14 py-8  text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
                    onClick={onPublishandMarkAsSentHandler}
                  >
                    Publish and Mark as sent
                  </div>
                </div>
              </Dropdown>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  var toastMessage =
                    quote?.length === 0
                      ? 'Empty quotes cannot be published.'
                      : !quote.some((value) => {
                          return !!value.line_item_list
                        })
                      ? 'Quotes with empty line item cannot be published.'
                      : 'You will not be able to create any quotes without filling up necessary company details in general settings page.                      '
                  toast.error(toastMessage)
                }}
                className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm hover:bg-blue-400 hover:text-blue-700 active:bg-blue-400 active:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Publish
              </button>
            )}
          </>
        )

      case status === 'published' && delivery === false:
        return (
          <button
            type="button"
            className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            onClick={onSendHandler}
          >
            Send
          </button>
        )
      case status === 'published' && delivery === true:
        return (
          <div className="flex gap-8">
            {quoteProceedActions === 'include_option' ? (
              <ConfirmationModal
                label="Do you want to include preliminary cost ?"
                onConfirmClick={() => {
                  quoteDetails?.is_super_seeded
                    ? onChangeAcceptedQuote()
                    : onAccecptHandler({isPrelimIncluded: true})
                }}
                onCancelClick={() => {
                  quoteDetails?.is_super_seeded
                    ? onChangeAcceptedQuote()
                    : onAccecptHandler({isPrelimIncluded: false})
                }}
                confirmLabel="Include"
                cancelLabel="Dont Include"
                description={
                  <div className="px-6 mt-[-10px] mb-[20px] flex items-center gap-4">
                    <div className="text-[16px] font-medium">
                      Total preliminary cost to be added :
                    </div>
                    <div className="text-[16px] text-blue-200">
                      {currencySymbol}{' '}
                      {projectDetail?.project_details?.total_prelim_cost ?? 0.0}
                    </div>
                  </div>
                }
                displayElement={
                  <button
                    type="button"
                    className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  >
                    Accept
                  </button>
                }
              />
            ) : quoteProceedActions === 'warn_user' ? (
              <CustomModal
                title="Preliminary Cost Recommendation"
                displayElement={
                  <button
                    type="button"
                    className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  >
                    Accept
                  </button>
                }
              >
                {({onCloseModalHandler: onClose}) => {
                  return (
                    <div className="py-8 px-16 ">
                      <div className="bg-yellow-50 border border-yellow-300 p-4 rounded-lg flex items-start space-x-4 shadow-sm gap-10">
                        <FiAlertTriangle
                          className="text-yellow-500 w-7 h-7 flex-shrink-0"
                          size={26}
                          color="#FFAB2C"
                        />
                        <div className="flex-grow">
                          <h3 className="text-yellow-700 font-bold mb-2 text-md">
                            Preliminary Cost Recommendation
                          </h3>
                          <p className="text-gray-300 text-sm mb-3">
                            It's recommended to add a preliminary cost estimate
                            for more accurate planning and tracking. While you
                            can proceed, adding this information will provide
                            better insights.
                          </p>
                          <div
                            onClick={() => {
                              onClose()
                              navigation.navigation.navigate(
                                `/projects/${projectId}/quote/preliminary`,
                              )
                            }}
                            className="flex cursor-pointer items-center gap-6 space-x-2 bg-yellow-300  hover:bg-yellow-300 text-yellow-700 hover:bg-yellow-200 text-sm transition-colors mt-12 w-[max-content] px-6 py-4 rounded-sm"
                          >
                            <BiPlusCircle className="" size={20} color="#fff" />
                            <span className="text-white">
                              Add Preliminary Cost
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end gap-10 mt-20">
                        <Button
                          buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
                          type="button"
                          onClick={() => {
                            onClose()
                          }}
                          title={'Cancel'}
                        />
                        <Button
                          type="button"
                          buttonColor={
                            'text-white bg-blue-400 hover:bg-blue-500 px-10'
                          }
                          onClick={() => {
                            quoteDetails?.is_super_seeded
                              ? onChangeAcceptedQuote()
                              : onAccecptHandler({isPrelimIncluded: false})
                          }}
                          title={'Accept Anyway'}
                        />
                      </div>
                    </div>
                  )
                }}
              </CustomModal>
            ) : (
              <button
                type="button"
                className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                onClick={() =>
                  quoteDetails?.is_super_seeded
                    ? onChangeAcceptedQuote()
                    : onAccecptHandler()
                }
              >
                Accept
              </button>
            )}

            <button
              type="button"
              className=" py-8 px-8 text-sm font-medium text-white bg-red-300 rounded-sm 
hover:bg-red-400 hover:text-blue-700
active:bg-red-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              onClick={onDeclineHandler}
            >
              Decline
            </button>
          </div>
        )

      default:
        return null
    }
  }
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      {getButtonGroup(quoteDetails?.status, quoteDetails?.is_delivered)}
    </div>
  )
}
