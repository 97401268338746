import {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
  CustomCollapse,
  CustomModal,
  DateInput,
  InfiniteScrollBlock,
  Input,
  Modal,
  ToolTip,
} from 'app/common'
import {
  RootState,
  getActiveListForDashboard,
  getCompletedListForDashboard,
  getToPriceListForDashboard,
  getAllProjectsForDashboardModal,
  getProjectTicketList,
} from 'redux-src'

import {SummaryCard} from '../../components'
import {FaHourglassEnd, FaHourglassStart, FaSearch} from 'react-icons/fa'
import {useDebounceValue} from 'hooks'
import {CiViewList} from 'react-icons/ci'
import {DataTable} from 'app/components'
import {Link} from 'react-router-dom'
import {ProjectTicketList} from 'app/pages/jobs/pages/ticket'
import {getStatusChip} from 'app/pages/jobs/pages/ticket/components/planList/components/planTicketCart'

export function ProjectsTab() {
  const {
    toPriceListForDashboard,
    isToPriceForDashboardLoading,

    completedListForDashboard,
    isCompletedListForDashboardLoading,

    activeListForDashboard,
    isActiveListForDashboardLoading,

    allProjectsListForDashboard,
    isAllProjectsListForDashboardLoading,
  }: RT.CompanyReduxType = useSelector((state: RootState) => state.company)
  const dispatch = useDispatch()

  const [sortInfo, setSortInfo] = useState<{
    project: 'To Price' | 'Active' | 'Completed'
    projectTitleOrder: 'asc' | 'desc'
    projectNameOrder: 'asc' | 'desc'
    startDateOrder: 'asc' | 'desc'
    endDateOrder: 'asc' | 'desc'
  }>()

  //* TRIGGERS FOR COLLAPSE
  const [activeOpen, setActiveOpen] = useState(true)
  const [completeOpen, setCompleteOpen] = useState(true)
  const [toPriceOpen, setToPriceOpen] = useState(true)

  const [activeData, setActiveData] = useState(activeListForDashboard)
  const [completeData, setCompleteData] = useState(completedListForDashboard)
  const [toPriceData, setToPriceData] = useState(toPriceListForDashboard)

  // * Change limit on scroll for infinite scrolling
  const [toPriceLimit, setToPriceLimit] = useState(10)
  const [activeLimit, setActiveLimit] = useState(10)
  const [completedLimit, setCompletedLimit] = useState(10)

  // const [activeSearch, setActiveSearch] = useState('')
  // const [toPriceSearch, setToPriceSearch] = useState('')
  // const [completedSearch, setCompletedSearch] = useState('')

  // const activeSearchDebounced = useDebounceValue(activeSearch)
  // const toPriceSearchDebounced = useDebounceValue(toPriceSearch)
  // const completedSearchDebounced = useDebounceValue(completedSearch)

  // Refetch data when limit changes
  useEffect(() => {
    if (!isActiveListForDashboardLoading) {
      handleFetchActive(activeLimit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLimit])

  useEffect(() => {
    if (!isToPriceForDashboardLoading) {
      handleFetchToPrice(toPriceLimit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toPriceLimit])

  useEffect(() => {
    if (!isCompletedListForDashboardLoading) {
      handleFetchCompleted(completedLimit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedLimit])

  const handleFetchCompleted = (limit: number, search?: string) => {
    dispatch(
      getCompletedListForDashboard({limit, search}, (data) => {
        setCompleteData(data)
        setCompleteOpen(data?.rows?.length > 0)
      }),
    )
  }
  const handleFetchToPrice = (limit: number, search?: string) => {
    dispatch(
      getToPriceListForDashboard({limit, search}, (data) => {
        setToPriceData(data)
        setToPriceOpen(data?.rows?.length > 0)
      }),
    )
  }

  const handleFetchActive = (limit: number, search?: string) => {
    dispatch(
      getActiveListForDashboard({limit}, (data) => {
        setActiveData(data)
        setActiveOpen(data?.rows?.length > 0)
      }),
    )
  }

  const modalData = useMemo(
    () =>
      allProjectsListForDashboard?.rows?.map((row) => ({
        id: row?.project_details?.id,
        project_title: row?.project_details?.title,
        project_name: `${row?.project_details?.project_prefix}-${row?.project_details?.project_number}`,
        start_date: row?.project_details?.start_date,
        end_date: row?.project_details?.end_date,
        open_ticket: row?.open_ticket_count,
        inprogress_ticket: row?.inprogress_ticket_count,
        feedback_ticket: row?.feedback_ticket_count,
        completed_ticket: row?.completed_ticket_count,
      })),
    [allProjectsListForDashboard],
  )

  const columns = useMemo(() => {
    return [
      {
        name: 'Project Title',
        selector: (row: any) => row.project_title,
        sortable: true,
        cell: (row: any) => {
          return (
            <Link to={`/projects/${row?.id}/projects-summary`}>
              <div className="text-blue-300">{row?.project_title}</div>{' '}
            </Link>
          )
        },
      },
      {
        name: 'Project Name',
        selector: (row: any) => row.project_name,
        sortable: true,
        cell: (row: any) => {
          return (
            <Link to={`/projects/${row?.id}/projects-summary`}>
              <div className="text-blue-300">{row?.project_name}</div>
            </Link>
          )
        },
      },
      {
        name: 'Start Date',
        sortable: true,
        selector: (row: any) => row.start_date,
      },
      {
        name: 'End Date',
        sortable: true,
        selector: (row: any) => row.end_date,
      },
      {
        name: 'Open Ticket',
        selector: (row: any) => row.open_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('open')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.open_ticket}
            </div>
          )
        },
      },
      {
        name: 'In Progress Ticket',
        selector: (row: any) => row.inprogress_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('in progress')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.inprogress_ticket}
            </div>
          )
        },
      },

      {
        name: 'Feedback Ticket',
        selector: (row: any) => row.feeback_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('feedback')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.feedback_ticket}
            </div>
          )
        },
      },
      {
        name: 'Completed Ticket',
        selector: (row: any) => row.completed_ticket,
        cell: (row: any) => {
          return (
            <div
              className="cursor-pointer text-blue-300"
              onClick={() => {
                setTicketModalProjectId(row?.id)
                setTicketModalStatus('completed')
                setTicketModalTitleText(`${row?.project_name}`)
                setShowTicketModal(true)
              }}
            >
              {row?.completed_ticket}
            </div>
          )
        },
      },
    ]
  }, [])

  const handleSort = ({
    direction,
    name,
    project,
  }: {
    direction: 'asc' | 'desc'
    name: string
    project: 'To Price' | 'Active' | 'Completed'
  }) => {
    switch (name) {
      case 'Project Title':
        setSortInfo((prev) => ({
          ...prev,
          projectTitleOrder: direction,
          project: project,
        }))
        break
      case 'Project Name':
        setSortInfo((prev) => ({
          ...prev,
          projectNameOrder: direction,
          project: project,
        }))
        break
      case 'Start Date':
        setSortInfo((prev) => ({
          ...prev,
          startDateOrder: direction,
          project: project,
        }))
        break
      case 'End Date':
        setSortInfo((prev) => ({
          ...prev,
          endDateOrder: direction,
          project: project,
        }))
        break
      default:
        setSortInfo((prev) => ({
          ...prev,
          projectTitleOrder: 'asc',
          projectNameOrder: 'asc',
          startDateOrder: 'asc',
          endDateOrder: 'asc',
          project: project,
        }))
    }
  }

  const [openModal, setOpenModal] = useState<
    'Completed' | 'To Price' | 'Active'
  >()

  // //! work around to disable collapse opening on modal open
  // useEffect(() => {
  //   setToPriceOpen(!!!openModal)
  //   setActiveOpen(!!!openModal)
  //   setCompleteOpen(!!!openModal)
  // }, [openModal])

  const [modalTablePage, setModalTablePage] = useState({
    Active: 1,
    'To Price': 1,
    Completed: 1,
  })

  const [showTicketModal, setShowTicketModal] = useState(false)
  const [ticketModalTitleText, setTicketModalTitleText] = useState('')
  const [ticketModalStatus, setTicketModalStatus] = useState('')
  const [ticketModalProjectId, setTicketModalProjectId] = useState<number>()

  useEffect(() => {
    if (!!ticketModalStatus) {
      dispatch(
        getProjectTicketList(ticketModalProjectId, {
          page: 1,
          limit: 10,
          status: ticketModalStatus,
        }),
      )
    }
  }, [dispatch, ticketModalProjectId, ticketModalStatus])

  const [searchQuery, setSearchQuery] = useState<string>('')
  const searchDebounce = useDebounceValue(searchQuery)

  const generateSortOrderString = (sortInfo: any) => {
    if (!sortInfo) return ''

    const sortMappings = {
      projectTitleOrder: 'title',
      projectNameOrder: 'project_number',
      startDateOrder: 'start_date',
      endDateOrder: 'end_date',
    }

    const sortParts = Object.entries(sortMappings).reduce(
      (acc, [key, value]) => {
        if (sortInfo[key]) {
          acc.push(`${value}:${sortInfo[key]}`)
        }
        return acc
      },
      [],
    )

    return sortParts.join(',')
  }

  useEffect(() => {
    const sortOrder = generateSortOrderString(sortInfo)

    dispatch(
      getAllProjectsForDashboardModal({
        status: openModal,
        page: modalTablePage[openModal],
        search: searchDebounce === '' ? undefined : searchDebounce,
        sortOrder: sortOrder,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, modalTablePage, searchDebounce, sortInfo, openModal])

  return (
    <div className="w-full ">
      <div className="project-list--to-price w-full mt-14 p-20 rounded-sm">
        <CustomCollapse
          trigger={toPriceOpen}
          header={
            <div
              className="flex flex-row items-center justify-between py-10 cursor-pointer"
              onClick={() => !!!openModal && setToPriceOpen((prev) => !prev)}
            >
              <div className="flex flex-row items-center justify-start gap-20">
                <div className="font-bold text-lg text-[#e2420d]">To Price</div>
                <div className="font-bold text-md bg-[#e2420d] rounded-sm text-white px-8">
                  {toPriceData?.total}
                </div>
              </div>

              <CustomModal
                onCloseCB={() => {
                  setSearchQuery('')
                  setOpenModal(undefined)
                  setSortInfo(undefined)
                }}
                displayElement={
                  <div
                    onClick={(e) => {
                      setOpenModal('To Price')
                    }}
                    className="w-full"
                  >
                    <ToolTip top text="Show in list view">
                      <div className="font-bold text-md bg-[#e2420d] rounded-sm text-white p-4">
                        <CiViewList size={16} />
                      </div>
                    </ToolTip>
                  </div>
                }
                size="md"
                title={
                  <div className="flex flex-row items-center justify-start gap-12  ">
                    <div className="font-bold text-lg text-[#e2420d]">
                      To Price
                    </div>
                    <div className="font-bold text-md bg-[#e2420d] rounded-sm text-white px-8">
                      {toPriceData?.total}
                    </div>
                  </div>
                }
              >
                {(onClose) => {
                  return (
                    <div className="p-20 py-12 flex flex-col gap-y-12">
                      {/* <div className="flex gap-4"> */}
                      {/* <div className="flex">
                          <ProjectListSort
                            onSort={(val) => {
                              console.log({val}, 'To price')
                            }}
                          />
                        </div> */}
                      <div className="flex justify-end">
                        <Input
                          onChange={(e) => {
                            setSearchQuery((prev) => e.target.value)
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          placeholder="Search to price"
                          prepend={<FaSearch />}
                          containerClass="mb-0"
                          className="px-6"
                        />
                      </div>
                      {/* </div> */}
                      <DataTable
                        totalRows={+allProjectsListForDashboard?.total}
                        pagination
                        fetchData={(newPage) => {
                          setModalTablePage((prev) => ({
                            ...prev,
                            'To Price': newPage,
                          }))
                        }}
                        data={modalData ?? []}
                        columns={columns as any}
                        loading={isAllProjectsListForDashboardLoading}
                        //@ts-ignore
                        onSort={(e, direction) => {
                          handleSort({
                            direction,
                            name: e?.name,
                            project: 'To Price',
                          })
                        }}
                        sortServer
                      />
                    </div>
                  )
                }}
              </CustomModal>
            </div>
          }
          content={
            <div className="flex gap-14 py-14 w-full overflow-x-auto justify-start">
              {!!toPriceData && toPriceData?.rows?.length > 0 && (
                <InfiniteScrollBlock
                  triggerCallback={() => {
                    setToPriceLimit((prev) => prev + 10)
                  }}
                  isLast={toPriceData?.isLast}
                  className="flex w-full gap-[1.5rem] pb-20 overflow-x-auto items-center scrollbar"
                >
                  {toPriceData?.rows?.map((data, i) => {
                    return (
                      <SummaryCard
                        key={
                          data?.project_details?.id +
                          data?.project_details?.title +
                          i
                        }
                        data={data}
                      />
                    )
                  })}
                </InfiniteScrollBlock>
              )}
            </div>
          }
        />
      </div>

      <div className="project-list--active w-full mt-[2rem] p-20 rounded-sm">
        <CustomCollapse
          trigger={activeOpen}
          header={
            <div
              className="flex flex-row items-center justify-between py-10 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                !!!openModal && setActiveOpen((prev) => !prev)
              }}
            >
              <div className="flex flex-row items-center justify-start gap-20 py-10">
                <div className="font-bold text-lg text-[#3452c0]">Active</div>
                <div className="font-bold text-md bg-[#3452c0] rounded-sm text-white px-8">
                  {activeData?.total}
                </div>
              </div>

              <CustomModal
                onCloseCB={() => {
                  setSearchQuery('')
                  setOpenModal(undefined)
                  setSortInfo(undefined)
                }}
                displayElement={
                  <div
                    onClick={(e) => {
                      setOpenModal('Active')
                    }}
                  >
                    <ToolTip top text="Show in list view">
                      <div className="font-bold text-md bg-[#3452c0] rounded-sm text-white p-4">
                        <CiViewList size={16} />
                      </div>
                    </ToolTip>
                  </div>
                }
                size="md"
                title={
                  <div className="flex flex-row items-center justify-start gap-12  ">
                    <div className="font-bold text-lg text-[#3452c0]">
                      Active
                    </div>
                    <div className="font-bold text-md bg-[#3452c0] rounded-sm text-white px-8">
                      {activeData?.total}
                    </div>
                  </div>
                }
              >
                {(onClose) => {
                  return (
                    <div className="p-20 py-12 flex flex-col gap-y-12">
                      {/* <div className="flex gap-4"> */}
                      {/* <div className="flex">
                          <ProjectListSort
                            onSort={(val) => {
                              console.log({val}, 'active')
                            }}
                          />
                        </div> */}
                      <div className="flex justify-end">
                        <Input
                          onChange={(e) => {
                            setSearchQuery((prev) => e.target.value)
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          placeholder="Search active"
                          prepend={<FaSearch />}
                          containerClass="mb-0"
                          className="px-6"
                        />
                      </div>{' '}
                      {/* </div> */}
                      <DataTable
                        loading={isAllProjectsListForDashboardLoading}
                        data={modalData ?? []}
                        columns={columns as any}
                        pagination
                        fetchData={(newPage) => {
                          setModalTablePage((prev) => ({
                            ...prev,
                            Active: newPage,
                          }))
                        }}
                        totalRows={+allProjectsListForDashboard?.total}
                        //@ts-ignore
                        onSort={(e, direction) => {
                          handleSort({
                            direction,
                            name: e?.name,
                            project: 'Active',
                          })
                        }}
                        sortServer
                      />
                    </div>
                  )
                }}
              </CustomModal>
            </div>
          }
          content={
            <div className="flex gap-14 py-14 w-full justify-start">
              {!!activeData && activeData?.rows?.length > 0 && (
                <InfiniteScrollBlock
                  triggerCallback={() => {
                    setActiveLimit((prev) => prev + 10)
                  }}
                  isLast={activeData?.isLast}
                  className="flex w-full gap-[1.5rem] pb-20 overflow-x-auto items-center scrollbar"
                >
                  {activeData?.rows?.map((data, i) => {
                    return (
                      <SummaryCard
                        key={
                          data?.project_details?.id +
                          data?.project_details?.title +
                          i
                        }
                        data={data}
                      />
                    )
                  })}
                </InfiniteScrollBlock>
              )}
            </div>
          }
        />
      </div>

      <div className="project-list--completed w-full mt-[2rem] p-20 rounded-sm">
        <CustomCollapse
          trigger={completeOpen}
          header={
            <div
              className="flex flex-row items-center justify-between gap-20 py-10 cursor-pointer"
              onClick={() => !!!openModal && setCompleteOpen((prev) => !prev)}
            >
              <div className="flex flex-row items-center justify-start gap-20 py-10 cursor-pointer">
                <div className="font-bold text-lg text-[#76cc00]">
                  Completed
                </div>
                <div className="font-bold text-md bg-[#76cc00] rounded-sm text-white px-8">
                  {completeData?.total}
                </div>
              </div>
              <CustomModal
                onCloseCB={() => {
                  setSearchQuery('')
                  setOpenModal(undefined)
                  setSortInfo(undefined)
                }}
                displayElement={
                  <div
                    onClick={(e) => {
                      setOpenModal('Completed')
                    }}
                  >
                    <ToolTip top text="Show in list view">
                      <div className="font-bold text-md bg-[#76cc00] rounded-sm text-white p-4">
                        <CiViewList size={16} />
                      </div>
                    </ToolTip>
                  </div>
                }
                size="md"
                title={
                  <div className="flex flex-row items-center justify-start gap-12  ">
                    <div className="font-bold text-lg text-[#76cc00]">
                      Completed
                    </div>
                    <div className="font-bold text-md bg-[#76cc00] rounded-sm text-white px-8">
                      {completeData?.total}
                    </div>
                  </div>
                }
              >
                {(onClose) => {
                  return (
                    <div className="p-20 py-12 flex flex-col gap-y-12">
                      {/* <div className="flex gap-4"> */}
                      {/* <div className="flex">
                          <ProjectListSort
                            onSort={(val) => {
                              console.log({val}, 'completed')
                            }}
                          />
                        </div> */}
                      <div className="flex justify-end">
                        <Input
                          onChange={(e) => {
                            setSearchQuery((prev) => e.target.value)
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          placeholder="Search completed"
                          prepend={<FaSearch />}
                          containerClass="mb-0"
                          className="px-6"
                        />
                      </div>
                      {/* </div> */}
                      <DataTable
                        loading={isAllProjectsListForDashboardLoading}
                        data={modalData ?? []}
                        columns={columns as any}
                        pagination
                        fetchData={(newPage, limit) => {
                          setModalTablePage((prev) => ({
                            ...prev,
                            Completed: newPage,
                          }))
                        }}
                        totalRows={+allProjectsListForDashboard?.total}
                        //@ts-ignore
                        onSort={(e, direction) => {
                          handleSort({
                            direction,
                            name: e?.name,
                            project: 'Completed',
                          })
                        }}
                        sortServer
                      />
                    </div>
                  )
                }}
              </CustomModal>
            </div>
          }
          content={
            <div className="flex gap-14 py-14 w-full overflow-x-auto justify-start">
              {!!completeData && completeData?.rows?.length > 0 && (
                <InfiniteScrollBlock
                  triggerCallback={() => {
                    setCompletedLimit((prev) => prev + 10)
                  }}
                  isLast={completeData?.isLast}
                  className="flex w-full gap-[1.5rem] pb-20 overflow-x-auto items-center scrollbar"
                >
                  {completeData?.rows?.map((data, i) => {
                    return (
                      <SummaryCard
                        key={
                          data?.project_details?.id +
                          data?.project_details?.title +
                          i
                        }
                        data={data}
                      />
                    )
                  })}
                </InfiniteScrollBlock>
              )}
            </div>
          }
        />
      </div>

      <Modal
        title={
          <div className="flex justify-between">
            <span>
              Showing tickets for project{' '}
              <span className="text-blue-300">{ticketModalTitleText}</span>
            </span>
            {getStatusChip((ticketModalStatus as any) ?? 'open')}
          </div>
        }
        isActive={showTicketModal}
        toggleModal={setShowTicketModal}
        size="md"
      >
        <div className="p-12">
          <ProjectTicketList
            onTitleClick={(ticketId: number) => {
              window.open(
                `#/projects/${ticketModalProjectId}/ticket?showTicket=true&ticketId=${ticketId}`,
                '_blank',
              )
            }}
            showAction={false}
          />
        </div>
      </Modal>
    </div>
  )
}

interface SortBy {
  projectTitle?: string
  projectName?: string
  startDate?: string
  endDate?: string
}

interface ProjectListSortProps {
  onSort: (sort: SortBy) => void
}

const ProjectListSort = ({onSort}: ProjectListSortProps) => {
  const [sortedBy, setSortedBy] = useState<SortBy>()

  useEffect(() => {
    if (sortedBy) {
      onSort(sortedBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedBy])

  return (
    <div className="flex gap-4">
      <Input
        onChange={(e) => {
          setSortedBy((prev) => ({...prev, projectTitle: e.target.value}))
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        placeholder="Sort by title"
        prepend={<FaSearch />}
        containerClass="mb-0"
      />
      <Input
        onChange={(e) => {
          setSortedBy((prev) => ({...prev, projectName: e.target.value}))
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        placeholder="Sort by name"
        prepend={<FaSearch />}
        containerClass="mb-0"
      />

      <div className="flex gap-[2px]">
        <ToolTip text="Project Start" top className="flex flex-1">
          <DateInput
            prepend={<FaHourglassStart className="text-blue-300" />}
            name="dateFrom"
            placeholder="Date From"
            containerClass="w-full relative h-full"
            className="h-[32px]"
            // value={data.dateFrom}
            onChange={(date) => {
              setSortedBy((prev) => ({...prev, startDate: date}))
            }}
          />
        </ToolTip>
        <ToolTip text="Project End" top className="flex flex-1">
          <DateInput
            prepend={<FaHourglassEnd className="text-blue-300" />}
            name="dateTo"
            placeholder="Date To"
            containerClass="w-full relative"
            // value={data.dateTo}
            onChange={(date) => {
              setSortedBy((prev) => ({...prev, endDate: date}))
            }}
            className="h-[32px]"
            minDate={new Date(sortedBy?.startDate)}
          />
        </ToolTip>
      </div>
    </div>
  )
}
