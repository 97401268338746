import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {saveAs} from 'file-saver'
import {FaTrash} from 'react-icons/fa'
import {IoAddOutline} from 'react-icons/io5'
import {useNavigation} from 'react-auth-navigation'
import {VscFilePdf} from 'react-icons/vsc'
import {useFormInput} from 'use-form-input'

import {ImagePreviewModal, MessageBox} from 'app/components'
import {Checkbox, Modal, SelectField} from 'app/common'
import {getAssetName, getAssetUrl} from 'helpers'
import {getTicketAttachmentList, removeTicketAttachments} from 'redux-src'
import {TicketContext} from '../../ticket.page'
import {LabourElementsRemappedTypes} from '../handleLabourElementModal'
import {AiOutlineDownload, AiOutlineEye} from 'react-icons/ai'

interface AllAttachmentsModalProps {
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  assets?: Array<{url: string; file: File}>
  setAssets?: React.Dispatch<React.SetStateAction<any>>
  labelId?: string
  getTicketLogList?: () => void
  isShowLabourAttachments?: boolean
  labourDetails?: LabourElementsRemappedTypes
  edit?: boolean
  addDisabled?: boolean
}

export const AllAttachmentsModal = ({
  isModalVisible,
  setIsModalVisible,
  assets,
  setAssets,
  labelId,
  getTicketLogList,
  isShowLabourAttachments,
  labourDetails,
  edit,
  addDisabled,
}: AllAttachmentsModalProps) => {
  const {
    ticketAttachments,
    ticketAttachmentsCount,
    labourElements,
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const [data, {setValue}] = useFormInput({
    filterType: undefined,
    status: undefined,
    file: undefined,
    filterValue: undefined,
    labourElement: undefined,
  })

  const {projectTicketId} = useContext(TicketContext)

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const handleDeleteAssets = (assetsId: number) => {
    if (!!ticketAttachments && ticketAttachments?.length > 0) {
      const body = {
        ticketAssetIds: [assetsId],
      }

      dispatch(
        removeTicketAttachments(projectId, projectTicketId, body, () => {
          dispatch(getTicketAttachmentList(projectId, projectTicketId))
          getTicketLogList()
        }),
      )

      return
    }

    let tempAssets = [...assets]
    tempAssets.splice(assetsId, 1)
    setAssets(tempAssets)
  }

  const handleCheckboxChange = (
    tempId: number,
    type: 'isCompleted' | 'isMandatory',
  ) => {
    const changedStatus = data?.filterValue?.map((item: any) => {
      if (item?.id === tempId) {
        return {
          ...item,
          isChecked: !item?.isChecked,
        }
      } else {
        return {...item}
      }
    })

    setValue('filterValue', changedStatus)
  }

  useEffect(() => {
    const finalFilterValue = data.filterValue?.reduce(
      (
        acc: {
          isCompleted?: 'true' | 'false'
          isMandatory?: 'true' | 'false'
        },
        curr: {
          id: number
          label: string
          value: 'isCompleted' | 'isMandatory'
          isChecked: boolean
        },
      ) => {
        const newAcc = {...acc, [curr.value]: curr.isChecked}
        return newAcc
      },
      {},
    )

    const params = {
      fileType: data?.file?.value === 'all' ? undefined : data?.file?.value,
      ...finalFilterValue,
      labourElements:
        data?.filterType?.value === 'labourAttachments' ? true : undefined,
      labourElementId: data?.labourElement?.id,
      page: 1,
      limit: 100,
    }

    const finalParams =
      data?.filterType?.value === 'allAttachments' ? undefined : params

    !!projectTicketId &&
      dispatch(getTicketAttachmentList(projectId, projectTicketId, finalParams))
  }, [
    data?.file,
    data?.filterType,
    data?.filterValue,
    data?.labourElement?.id,
    dispatch,
    projectId,
    projectTicketId,
  ])

  useEffect(() => {
    isShowLabourAttachments
      ? setValue(
          'filterType',
          filterOptions?.find((item) => item?.value === 'labourAttachments'),
        )
      : setValue(
          'filterType',
          filterOptions?.find((item) => item?.value === 'allAttachments'),
        )

    !!labourDetails &&
      setValue('labourElement', {
        id: labourDetails?.id,
        label: labourDetails?.label,
        value: labourDetails?.id,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowLabourAttachments, labourDetails])

  return (
    <Modal
      title="See all the attachments"
      toggleModal={setIsModalVisible}
      isActive={isModalVisible}
      size="full"
    >
      <div className="flex flex-col gap-10 w-full h-[93vh] px-20 overflow-y-auto">
        <div className="flex gap-8 mt-4 w-full">
          <div className="flex gap-8 mt-4 w-[20%]">
            <label
              htmlFor={labelId}
              className={`flex gap-4 items-center  border border-solid border-1 border-blue-300 py-6 rounded-sm px-12
              transition-all duration-200 ease-in-out cursor-pointer text-white
             ${
               addDisabled
                 ? 'pointer-events-none bg-blue-200'
                 : 'pointer-events-auto  bg-blue-300 hover:bg-blue-400'
             } `}
            >
              <IoAddOutline size={18} color="#fff" />{' '}
              <span className="text-white font-bold">Add Attachments</span>
            </label>
            <div className="border flex items-center px-8 border-solid border-l-1 border-gray-300 font-bold">
              {(ticketAttachmentsCount || assets?.length) ?? 0} attachments
            </div>
          </div>
          {edit && (
            <div className="flex items-center gap-10 w-full">
              <div className="w-[15%]">
                <SelectField
                  placeholder="Filter Options"
                  value={data?.filterType ?? undefined}
                  options={filterOptions}
                  onChangeValue={(item) => {
                    setValue('filterType', item)
                  }}
                />
              </div>
              {data?.filterType?.value === 'labourAttachments' && (
                <div className="w-full flex items-center gap-8">
                  <div className="w-[15%]">
                    <SelectField
                      placeholder="File Type"
                      options={labourElements?.map((item) => ({
                        id: item?.ticket_labour_el_details?.id,
                        label: item?.quote_labour_el_details?.name,
                        value: item?.ticket_labour_el_details?.id,
                      }))}
                      value={data?.labourElement ?? undefined}
                      onChangeValue={(item) => {
                        setValue('labourElement', item)
                      }}
                    />
                  </div>
                  <div className="w-[15%]">
                    <SelectField
                      placeholder="File Type"
                      options={fileTypeOptions}
                      value={data?.file ?? undefined}
                      onChangeValue={(item) => {
                        setValue('file', item)
                      }}
                    />
                  </div>
                  <div className="w-[20%] flex gap-6 items-center">
                    <SelectField
                      placeholder="Status"
                      options={statusOptions}
                      multiValue={data?.status ?? undefined}
                      onMultiChangeValue={(data) => {
                        const prepopulatedCheck = data?.map((item: any) => {
                          return {
                            ...item,
                            isChecked: true,
                          }
                        })
                        setValue('filterValue', prepopulatedCheck)
                        setValue('status', prepopulatedCheck)
                      }}
                      isMulti
                    />
                  </div>
                  <div className="w-[15%] flex gap-6 items-center">
                    {data?.filterValue?.map((item: any) => {
                      return (
                        <Checkbox
                          dataId={`${item?.id}-labourStatus`}
                          label={item?.label}
                          isChecked={item?.isChecked}
                          onChange={() => {
                            handleCheckboxChange(item?.id, item?.value)
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="py-10 border border-solid border-t-1 border-gray-200 w-full">
          <div className="grid grid-cols-7 gap-6 w-full">
            {!!ticketAttachments && ticketAttachmentsCount > 0 ? (
              ticketAttachments?.map((image, index: number) => {
                return (
                  <IndividualAsset
                    key={index}
                    {...{image, index, handleDeleteAssets}}
                  />
                )
              })
            ) : assets && assets.length > 0 ? (
              assets?.map(({file, url}, index) => {
                return (
                  <IndividualAsset
                    key={index}
                    {...{url, file, index, handleDeleteAssets}}
                  />
                )
              })
            ) : (
              <>
                <MessageBox message="No attachments found !!" />
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const IndividualAsset = ({
  file,
  url,
  index,
  handleDeleteAssets,
  image,
}: {
  file?: File
  url?: string
  index?: number
  handleDeleteAssets?: (id: number) => void
  image?: Api.TicketAttachmentIndividual
}) => {
  const [activeTrash, setActiveTrash] = useState<boolean>(false)

  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const handleOnMouseOver = (event: any, state: 'over' | 'out') => {
    event.preventDefault()
    event.stopPropagation()

    if (state === 'over') {
      setActiveTrash(true)
    }
    if (state === 'out') {
      setActiveTrash(false)
    }
  }

  return (
    <React.Fragment>
      <div
        className="flex flex-col gap-4 relative h-[300px] overflow-hidden "
        onMouseEnter={(e: any) => {
          handleOnMouseOver(e, 'over')
        }}
        onMouseLeave={(e: any) => {
          handleOnMouseOver(e, 'out')
        }}
      >
        {image?.asset_details?.file_type === 'image' ||
        file?.type?.includes('image') ? (
          <img
            src={
              !!image?.asset_details ? getAssetUrl(image?.asset_details) : url
            }
            alt={
              !!image?.asset_details
                ? image?.asset_details?.label
                  ? image?.asset_details?.label
                  : image?.asset_details?.name
                : file?.name
            }
            className="w-[240px] h-[200px] rounded-sm object-contain bg-gray-100"
          />
        ) : image?.asset_details?.file_type === 'video' ? (
          <div className="flex items-center justify-center w-[240px] h-[200px] flex-col gap-6 object-cover text-gray-400 bg-gray-100 rounded-sm">
            <video
              src={getAssetUrl(image?.asset_details)}
              controls
              style={{width: '100%', height: '200px', objectFit: 'contain'}}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center w-[240px] h-[200px] flex-col gap-6 text-gray-400 bg-gray-100 rounded-sm">
            <VscFilePdf size={80} />
          </div>
        )}
        <div className="flex flex-col gap-4 text-md text-gray-400 w-[90%] h-[96px] break-all">
          {image
            ? image?.asset_details?.label
              ? image?.asset_details?.label
              : getAssetName(image?.asset_details)
            : file?.name}
        </div>
        {activeTrash && (
          <div className="flex absolute gap-8 top-4 right-1 2xl:right-[30px] bg-white rounded-sm px-8 py-4 shadow-md w-full">
            {!!image?.asset_owner_details && (
              <div className="truncate w-full">
                {image?.asset_owner_details?.display_name +
                  ' ' +
                  image?.asset_owner_details?.lastname}
              </div>
            )}
            <div className="flex gap-8 items-center justify-end w-full">
              {(image?.asset_details?.file_type === 'image' ||
                file?.type?.includes('image')) && (
                <div
                  className="cursor-pointer 2xl:right-[10px]"
                  onClick={() => {
                    setModalVisible((prev) => !prev)
                  }}
                >
                  <AiOutlineEye
                    size={20}
                    className="transition-all duration-100 ease-in-out hover:scale-110 cursor-pointer"
                  />
                </div>
              )}
              {image && image?.asset_details && (
                <div
                  className="cursor-pointer 2xl:right-[10px]"
                  onClick={() => {
                    saveAs(
                      getAssetUrl(image?.asset_details),
                      `${
                        (image && image?.asset_details?.label
                          ? image?.asset_details?.label
                          : getAssetName(image?.asset_details)) ?? file?.name
                      }`,
                    )
                  }}
                >
                  <AiOutlineDownload
                    size={16}
                    className="mr-10 transition-all duration-100 ease-in-out hover:scale-125 cursor-pointer"
                  />
                </div>
              )}
              <div
                className="transition-all duration-100 ease-in-out hover:scale-110 cursor-pointer"
                onClick={() => {
                  handleDeleteAssets(image?.ticket_asset_details?.id ?? index)
                }}
              >
                <FaTrash size={16} color="crimson" />
              </div>
            </div>
          </div>
        )}

        <ImagePreviewModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          assetDetails={image?.asset_details}
        />
      </div>
    </React.Fragment>
  )
}

const fileTypeOptions = [
  {
    id: 1,
    label: 'All',
    value: 'all',
  },
  {
    id: 2,
    label: 'Document',
    value: 'document',
  },
  {
    id: 3,
    label: 'Image',
    value: 'image',
  },
  {
    id: 4,
    label: 'Video',
    value: 'video',
  },
]

const filterOptions = [
  {
    id: 1,
    label: 'All Attachments',
    value: 'allAttachments',
  },
  {
    id: 2,
    label: 'Labour Attachments',
    value: 'labourAttachments',
  },
]

const statusOptions = [
  {
    id: 1,
    label: 'Is Completed',
    value: 'isCompleted',
    isChecked: false,
  },
  {
    id: 2,
    label: 'Is Mandatory',
    value: 'isMandatory',
    isChecked: false,
  },
]
