import {ActiveLink, Auth, useNavigation} from 'react-auth-navigation'
import {NoticeBoard, QuickActions} from './components'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

export const DashboardPage = () => {
  const {
    navigation: {navigate},
  } = useNavigation()

  const {pathname} = useLocation()
  useEffect(() => {
    if (pathname === '/dashboard' || pathname === '/dashboard/') {
      navigate('/dashboard/projects')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div className="w-9/12 mx-auto mt-0 px-20 pt-24 flex flex-col gap-16 bg-white min-h-[100vh]">
      <div className="flex flex-row gap-16">
        <div className="w-9/12 ">
          <div className="grid grid-cols-4 gap-4 border-b-1 border-[#d1d1d1]">
            <SiteVisitAuthTabItem
              title="Projects"
              path={`/dashboard/projects`}
            />
            <SiteVisitAuthTabItem
              title="Tickets Overdue"
              path={`/dashboard/overdue`}
            />
            <SiteVisitAuthTabItem
              title="Archived Projects"
              path={`/dashboard/archived`}
            />
          </div>

          <div className="tab-body w-full py-10">
            <Auth.Screens path={'/dashboard'} />
          </div>
        </div>
        <div className="w-3/12 flex flex-col gap-18">
          <NoticeBoard />
          <QuickActions />
        </div>
      </div>
    </div>
  )
}

const SiteVisitAuthTabItem = ({
  title,
  path,
  subtitle,
}: {
  title: string
  path: string
  subtitle?: string
}) => (
  <ActiveLink
    to={path}
    className="block p-12 px-16 bg-gray-100 rounded-t-sm border-1 border-gray-200 border-b-0 relative text-gray-400"
    activeClassName="bg-white after:content-[''] after:absolute after:left-0 after:top-[100%] after:w-[100%] after:h-[3px] after:bg-white after:-translate-y-half font-bold text-gray-500"
  >
    <div className="text-[16px]">{title}</div>
    <div className="text-sm">{subtitle}</div>
  </ActiveLink>
)
